import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import { NoScrollToggle } from "../../hooks/NoScroll";

import "./NavList.css";
import { useWindowSize } from "react-use";
import { LabelContext } from "../../pages/InstantQuotePage/labelDataContext";
import { mainNavlist, servicesNavList, aboutUsNavList } from "./NavigationLists";

interface NavListInterface {
  theme: string;
  location?: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  setServicesMobile?: Dispatch<SetStateAction<boolean>>;
  setAboutUsMobile?: Dispatch<SetStateAction<boolean>>;
}

export const checkTheme = (location: string, theme: string) => {
  if (location === "footer") {
    return "dark dark-footer";
  } else return theme;
};


const NavList = ({ theme, location, setIsOpen, setServicesMobile, setAboutUsMobile }: NavListInterface): JSX.Element => {
  const [ ourServicesDropdownVisible, setOurServicesDropdownVisible] = useState(false);
  const [ aboutUsDropdownVisible, setAboutUsDropdownVisible] = useState(false);
  const { setMobileMenuOpen, SetOurServicesOpen, SetAboutUsOpen } = useContext(LabelContext);

  const { pathname } = useLocation();
  const { t, i18n } = useTranslation(); // Use i18n from useTranslation

  const { width } = useWindowSize();

  const checkActive = (pathStart: string) => {
    if (pathname.startsWith(pathStart)) {
      return " active";
    }
  };

  const OurServicesMobileClick = () => {
    if (width < 980) {
      setMobileMenuOpen!(true);
      SetOurServicesOpen!(true);
    }
  };

  const AboutUsMobileClick = () => {
    if (width < 980) {
      setMobileMenuOpen!(true);
      SetAboutUsOpen!(true);
    }
  };

  const CloseMobileMenu = () => {
    if (setIsOpen) {
      setIsOpen(false);
      NoScrollToggle();
    }
    setOurServicesDropdownVisible(false);
    setAboutUsDropdownVisible(false);
    SetOurServicesOpen!(false);
    SetAboutUsOpen!(false);
  };

  const handleOurServicesMouseEnter = () => {
    setOurServicesDropdownVisible(true);
  };

  const handleOurServicesMouseLeave = () => {
    setOurServicesDropdownVisible(false);
  };

  const handleAboutUsMouseEnter = () => {
    setAboutUsDropdownVisible(true);
  };

  const handleAboutUsMouseLeave = () => {
    setAboutUsDropdownVisible(false);
  };

  const getLanguageSegment = () => {
    return i18n.language === 'ua' ? '/ua' : '';
  };

  return (
    <nav className="nav">
      {mainNavlist.map((nav) => {
        if (nav.path === "/our-services") {
          return (
            <div
              key={nav.path}
              className="our-services-nav"
              onMouseEnter={handleOurServicesMouseEnter}
              onMouseLeave={handleOurServicesMouseLeave}
            >
              <div
                onClick={OurServicesMobileClick}
                className={`nav-element ${theme} dropdown-nav ${checkActive("/our-services/")}`}
              >
                <div className="nav-dropdown-text">
                  {t(nav.name)}
                  <svg
                    className={`nav-dropdown-text-icon icon ${ourServicesDropdownVisible && "open"}`}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.6673 5.6665L8.00065 10.3332L3.33398 5.6665"
                      stroke="#71778A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className={`nav-dropdown ${ourServicesDropdownVisible ? "visible" : ""} ${checkTheme(location!, theme)}`}>
                {servicesNavList.map((secnav) => (
                  <NavLink
                    onClick={CloseMobileMenu}
                    to={`${getLanguageSegment()}${secnav.path}`} // Add language segment
                    key={secnav.path}
                    className={`secondary-nav-element ${checkTheme(location!, theme)}`}
                  >
                    {t(secnav.name)}
                  </NavLink>
                ))}
              </div>
            </div>
          );
        } else if (nav.path === "/about-us") {
          return (
            <div
              key={nav.path}
              className="our-services-nav"
              onMouseEnter={handleAboutUsMouseEnter}
              onMouseLeave={handleAboutUsMouseLeave}
            >
              <div
                onClick={AboutUsMobileClick}
                className={`nav-element ${theme} dropdown-nav ${checkActive("/about-us/")}`}
              >
                <div className="nav-dropdown-text">
                  {t(nav.name)}
                  <svg
                    className={`nav-dropdown-text-icon icon ${aboutUsDropdownVisible && "open"}`}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.6673 5.6665L8.00065 10.3332L3.33398 5.6665"
                      stroke="#71778A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              
              <div className={`nav-dropdown ${aboutUsDropdownVisible ? "visible" : ""} ${checkTheme(location!, theme)}`}>
                {aboutUsNavList.map((secnav) => (
                  <NavLink
                    onClick={CloseMobileMenu}
                    to={`${getLanguageSegment()}${secnav.path}`} // Add language segment
                    key={secnav.path}
                    className={`secondary-nav-element ${checkTheme(location!, theme)}`}
                  >
                    {t(secnav.name)}
                  </NavLink>
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <NavLink onClick={CloseMobileMenu} to={`${getLanguageSegment()}${nav.path}`} key={nav.path} className={`nav-element ${theme}`}>
              {t(nav.name)}
            </NavLink>
          );
        }
      })}
      <NavLink onClick={CloseMobileMenu} to={`${getLanguageSegment()}/blog/game-translation/`} className={`nav-element ${theme} ${checkActive("/blog/")}`}>
        {t("blog")}
      </NavLink>
    </nav>
  );
};

export default NavList;
