import { useState } from "react";
import IconRightButton from "../../../components/IconRightButton";
import HeroImage from "../../../images/hero-image.png";
import HeroImageSmall from "../../../images/hero-image-2.png";
import { ReactComponent as FiverIconSVG } from "../../../images/icons/fiverrIcon.svg";
import { ReactComponent as TyphoonIconSVG } from "../../../images/icons/Typhoon.svg";
import { ReactComponent as DribbleIconSVG } from "../../../images/icons/dribbleIcon.svg";
import { ReactComponent as UpworkIconSVG } from "../../../images/icons/upwork.svg";
import { ReactComponent as NetflixIconSVG } from "../../../images/icons/Netflix.svg";
import { ReactComponent as ViceIconSVG } from "../../../images/icons/Vice.svg";
import { ReactComponent as BoardovaIconSVG } from "../../../images/icons/Boardova.svg";
import BookADemo, { toggleModal } from "../../../components/BookADemoPopUp";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Hero.css";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2200, // Autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '30px',
        },
      }
    ],
  };

  const { t, i18n } = useTranslation();
  const currentPathBase = i18n.language === 'en' ? '' : `/${i18n.language}`;
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  return (
    <div className="hero-wrapper">
      <div className="container">
        <div className="hero-container">
          <div className="hero-text-container">
            <h1 className="hero-title">{t("heroTitle")}</h1>
            <p className="hero-text">{t("heroDescription")}</p>
            <div className="hero-btn-container">
              <a href={`${currentPathBase}/instant-quote/`} className="main-btn">
                {t("get quote")}
              </a>
              <IconRightButton
                onClick={() => {
                  toggleModal(setIsPopUpOpen, true);
                }}
                buttonName={t("book demo")}
                theme="dark"
              />
              {isPopUpOpen && <BookADemo setIsPopUpOpen={setIsPopUpOpen} />}
            </div>
          </div>
          <div className="hero-image-container">
            <img className="hero-main-image" src={HeroImage} alt="Hero" />
            <img className="hero-main-image-mobile" src={HeroImageSmall} alt="Hero" />
          </div>
        </div>
        <div className="partners-container mt-10">
          <Slider {...settings}>
            <div className="partners-slide">
              <a href={`${currentPathBase}/about-us/our-cases/`}>
                <FiverIconSVG className="partners-icon transition-transform transform hover:scale-95 cursor-pointer" />
              </a>
            </div>
            <div className="partners-slide">
              <a href={`${currentPathBase}/about-us/our-cases/`}>
                <TyphoonIconSVG className="partners-icon transition-transform transform hover:scale-95 cursor-pointer" />
              </a>
            </div>
            <div className="partners-slide">
              <a href={`${currentPathBase}/about-us/our-cases/`}>
                <DribbleIconSVG className="partners-icon transition-transform transform hover:scale-95 cursor-pointer" />
              </a>
            </div>
            <div className="partners-slide">
              <a href={`${currentPathBase}/about-us/our-cases/`}>
                <NetflixIconSVG className="partners-icon transition-transform transform hover:scale-95 cursor-pointer" />
              </a>
            </div>
            <div className="partners-slide">
              <a href={`${currentPathBase}/about-us/our-cases/`}>
                <ViceIconSVG className="partners-icon transition-transform transform hover:scale-95 cursor-pointer" />
              </a>
            </div>
            <div className="partners-slide">
              <a href={`${currentPathBase}/about-us/our-cases/`}>
                <BoardovaIconSVG className="partners-icon transition-transform transform hover:scale-95 cursor-pointer" />
              </a>
            </div>
            <div className="partners-slide">
              <a href={`${currentPathBase}/about-us/our-cases/`}>
                <UpworkIconSVG className="partners-icon transition-transform transform hover:scale-95 cursor-pointer" />
              </a>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Hero;
