import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './ROICalculator.css'; // Adjust the path as needed
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import Select from 'react-select'
import { RoiCalculatorService } from '../../services/roiCalculator/roiCalculatorService';
import { IRoiCalculatorServiceEmailRequest } from '../../services/roiCalculator/roiCalculatorEmailRequest';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { IoIosWarning } from "react-icons/io";

export interface GridDataItem {
  language: string;
  playersGained: number;
  additionalRevenue: number;
  cost: number;
  isGood: boolean;
}

interface ILanguageData {
    language: string;
    rate: number;
    playerCount: number;
}

interface OptionType {
    value: string;
    label: string;
}

const RoiCalculator = () => {
    const { t } = useTranslation();
    const allLanguageData: ILanguageData[] = [
        { language: "English", rate: 0.10, playerCount: 1500000 },
        { language: "Chinese", rate: 0.15, playerCount: 1400000 }, // Assuming Mandarin
        { language: "Hindi", rate: 0.12, playerCount: 800000 },
        { language: "Spanish", rate: 0.08, playerCount: 700000 },
        { language: "French", rate: 0.12, playerCount: 300000 },
        { language: "Arabic", rate: 0.14, playerCount: 400000 },
        { language: "Bengali", rate: 0.12, playerCount: 300000 },
        { language: "Russian", rate: 0.13, playerCount: 250000 },
        { language: "Portuguese", rate: 0.11, playerCount: 220000 },
        { language: "Indonesian", rate: 0.09, playerCount: 200000 },
        { language: "Urdu", rate: 0.12, playerCount: 160000 },
        { language: "German", rate: 0.14, playerCount: 150000 },
        { language: "Japanese", rate: 0.18, playerCount: 130000 },
        { language: "Swahili", rate: 0.11, playerCount: 100000 },
        { language: "Marathi", rate: 0.12, playerCount: 95000 },
        { language: "Telugu", rate: 0.12, playerCount: 90000 },
        { language: "Turkish", rate: 0.11, playerCount: 80000 },
        { language: "Korean", rate: 0.16, playerCount: 75000 },
        { language: "Tamil", rate: 0.12, playerCount: 70000 },
        { language: "Vietnamese", rate: 0.09, playerCount: 65000 },
        { language: "Italian", rate: 0.12, playerCount: 60000 },
        { language: "Hausa", rate: 0.11, playerCount: 55000 },
        { language: "Thai", rate: 0.10, playerCount: 50000 },
        { language: "Gujarati", rate: 0.12, playerCount: 45000 },
        { language: "Kannada", rate: 0.12, playerCount: 40000 },
        { language: "Polish", rate: 0.13, playerCount: 35000 },
        { language: "Ukrainian", rate: 0.13, playerCount: 30000 },
        { language: "Persian", rate: 0.11, playerCount: 25000 }, // Assuming Farsi
        { language: "Punjabi", rate: 0.12, playerCount: 20000 },
        { language: "Malay", rate: 0.09, playerCount: 15000 }, // Assuming Malay (Bahasa Melayu)
    ];

    const languageOptions: OptionType[] = allLanguageData.map(lang => ({
        value: lang.language,
        label: t(lang.language),
    }));

    const [invalidRequiredFields, setInvalidRequiredFields] = useState({
        playerBase: true,
        revenuePerPlayer: true,
        wordCount: true,
        sourceLanguage: true
    });

    const [formData, setFormData] = useState({
        playerBase: '',
        revenuePerPlayer: '',
        wordCount: '',
        email: '',
        name: '',
        surname: '',
        showOnlyProfitable: false,
    });

    const [selectedLanguages, setSelectedLanguages] = useState<OptionType[]>([]);
    const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<OptionType>();
    const [gridData, setGridData] = useState<GridDataItem[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [totalAdditionalRevenue, setTotalAdditionalRevenue] = useState<number>(0);
    const [totalCost, setTotalCost] = useState<number>(0);
    const [totalPlayersGained, setTotalPlayersGained] = useState<number>(0);
    

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCalculate = () => {

        const playerBaseValid: boolean = formData.playerBase !== "" && Number(formData.playerBase) > 0;
        const revenuePerPlayerValid: boolean = formData.revenuePerPlayer !== "" && Number(formData.playerBase) > 0;
        const wordCountValid: boolean = formData.wordCount !== "" && Number(formData.wordCount) > 0;
        const sourceLanguageValid: boolean = Boolean(selectedSourceLanguage);

        const newInvalidFields = {
            playerBase: playerBaseValid,
            revenuePerPlayer: revenuePerPlayerValid,
            wordCount: wordCountValid,
            sourceLanguage: sourceLanguageValid
        };
        setInvalidRequiredFields(newInvalidFields);

        if (!(playerBaseValid && revenuePerPlayerValid && wordCountValid && sourceLanguageValid)) {
            return; 
        }

        var data: GridDataItem[] = [];
        setShowGrid(false);
        setGridData(data);
        if(selectedLanguages.length === 0) {
            data = allLanguageData.map(x => calculateRow(x));
        }
        else {
            data = allLanguageData.filter(x => selectedLanguages.some(y => y.value === x.language)).map(x => calculateRow(x));
        }    

        if (formData.showOnlyProfitable) {
            data = data.filter(item => item.isGood);
        }
        
        setTotalAdditionalRevenue(data.reduce((acc, curr) => acc + curr.additionalRevenue, 0));
        setTotalCost(data.reduce((acc, curr) => acc + curr.cost, 0));
        setTotalPlayersGained(data.reduce((acc, curr) => acc + curr.playersGained, 0))
        setGridData(data);
        setShowGrid(true);
    };

    const sendResults = () => {
        const roiCalculatorService = new RoiCalculatorService();
        const request: IRoiCalculatorServiceEmailRequest = {
            email: formData.email,
            data: gridData
        };

        toast.promise(
            roiCalculatorService.sendRoiCalculatorResponse(request),
            {
                pending: t("sending") as string,
                success: t("successContactMessage") as string,
                error: t("errorContactMessage") as string,
            }
        );
    };

    const calculateRow = (languageData: ILanguageData) : GridDataItem => {

        const playersPercentile = (Number(formData.playerBase) / allLanguageData.filter(x => x.language === selectedSourceLanguage?.value)[0].playerCount);

        const cost = calculateCost(languageData);
        const playersGained = calculatePlayersGained(languageData, playersPercentile);
        const additionalRevenue = calculateAdditionalRevenue(playersGained);
    
        const data: GridDataItem = {
            language: languageData.language,
            playersGained: playersGained, 
            additionalRevenue: additionalRevenue, // Example value, calculate based on your logic
            cost: cost,
            isGood: cost < additionalRevenue, // Example condition, adjust as needed
        };

        return data;
    }

    const calculateCost = (languageData: ILanguageData) : number => {
        return (languageData.rate * Number(formData.wordCount));
    }

    const calculatePlayersGained = (languageData: ILanguageData, playersPercentile: number) : number => {
        return languageData.playerCount * playersPercentile;
    }

    const calculateAdditionalRevenue = (playersGained: number) : number => {
        return playersGained * Number(formData.revenuePerPlayer);
    }

    const language = i18n.language;
    const helmetRoiCalculatorTitle = t("helmetRoiCalculatorTitle");
    const helmetRoiCalculatorMetaDescription = t("helmetRoiCalculatorMetaDescription");
    const helmetRoiCalculatorKeywords = t("helmetRoiCalculatorKeywords");
    return (
        <HelmetProvider>
            <ToastContainer autoClose={3000}/>
            <Helmet>
                <title>{helmetRoiCalculatorTitle}</title>
                <meta name="description" content={helmetRoiCalculatorMetaDescription} />
                <meta name="keywords" content={helmetRoiCalculatorKeywords} />
                <meta name="robots" content="index, follow"/>

                <meta property="og:image" content=""/>
                <meta property="og:title" content={helmetRoiCalculatorTitle} />
                <meta property="og:description" content={helmetRoiCalculatorMetaDescription} />
                
                {language === 'en' && (
                <meta property="og:locale" content="en_US" />
                )}
                {language === 'ua' && (
                <meta property="og:locale" content="uk_UA" />
                )}
                
                {language === 'en' && (
                <meta property="og:url" content="https://wordlords.co/roi-calculator/" />
                )}
                {language === 'ua' && (
                <meta property="og:url" content="https://wordlords.co/ua/roi-calculator/" />
                )}

                {language === 'en' && (
                <link rel="canonical" href="https://wordlords.co/roi-calculator/" />
                )}
                {language === 'ua' && (
                <link rel="canonical" href="https://wordlords.co/ua/roi-calculator/" />
                )}
            </Helmet>
            <div className="roi-calculator-container">
                <div className='roi-calculator-header-container'>
                    <h2 className='roi-header-text'>{t("roiCalculatorHeading")}</h2>
                    <p className='roi-header-text'>{t("roiCalculatorDescription1")}</p>
                    <p className='roi-header-text'>{t("roiCalculatorDescription2")}</p>
                </div>

                <div className="form-input">
                    <label className='form-label' htmlFor="playerBase">{t("estimatedPlayerBase") as string} <span className="required-star">*</span></label>
                    <input
                        className={`form-input-in-group ${invalidRequiredFields.playerBase ? '' : 'form-input-error'}`}
                        type="number"
                        name="playerBase"
                        placeholder={t("estimatedPlayerBase") as string}
                        value={formData.playerBase}
                        onChange={handleChange}
                    />
                </div> 
                <div className="form-input">
                    <label className='form-label' htmlFor="revenuePerPlayer">{t("averageAnnualRevenuePerPlayer") as string} <span className="required-star">*</span></label>
                    <input
                        className={`form-input-in-group ${invalidRequiredFields.revenuePerPlayer ? '' : 'form-input-error'}`}
                        type="number"
                        name="revenuePerPlayer"
                        placeholder={t("averageAnnualRevenuePerPlayer") as string}
                        value={formData.revenuePerPlayer}
                        onChange={handleChange}
                    />
                </div>   
                <div className="form-input">
                    <label className='form-label' htmlFor="wordCount">{t("estimatedWordcountForProject") as string} <span className="required-star">*</span></label>
                    <input
                        className={`form-input-in-group ${invalidRequiredFields.wordCount ? '' : 'form-input-error'}`}
                        type="number"
                        name="wordCount"
                        placeholder={t("estimatedWordcountForProject") as string}
                        value={formData.wordCount}
                        onChange={handleChange}
                    />
                </div>                
                <div className="form-input">
                    <div className='form-label'>{t("sourceLanguageSelectLabel")} <span className="required-star">*</span></div>
                    <Select
                        options={languageOptions}
                        placeholder={t("select")}
                        classNamePrefix="select"
                        className={`${invalidRequiredFields.sourceLanguage ? '' : 'form-input-error-select'}`}
                        onChange={(newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
                            setSelectedSourceLanguage(newValue || {} as OptionType);
                        }}
                        value={selectedSourceLanguage}
                    />
                </div>
                <div className="form-input">
                    <div className='form-label'>{t("targetLanguagesSelectLabel")}</div>
                    <Select
                        isMulti
                        options={languageOptions}
                        placeholder={t("select")}
                        classNamePrefix="select"
                        onChange={(newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
                        setSelectedLanguages([...newValue]);
                        }}
                        value={selectedLanguages}
                    />
                </div>
                <div className="roi-calculator-checkbox-container">
                    <input
                        type="checkbox"
                        name="showOnlyProfitable"
                        checked={formData.showOnlyProfitable}
                        onChange={e => setFormData({ ...formData, showOnlyProfitable: e.target.checked })}
                        className='roi-calculator-checkbox'
                    />
                    <span>{t("checkboxOnlyProftableLabel")}</span>
                </div>
                <button className="calculate-button" onClick={handleCalculate}>{t("calculate")}</button>
                {showGrid && (
                    <div className='roi-calculator-results-container'>
                        <div className='roi-calculator-results-header'>
                            <h2 className='roi-header-text'>{`${t("results")}:`}</h2>
                        </div>
                        
                        <div className="data-grid-container">
                            <table className="data-grid">
                                <thead>
                                    <tr>
                                        <th className="data-grid-header">{t("language")}</th>
                                        <th className="data-grid-header">{t("estimatedPlayersGained")}</th>
                                        <th className="data-grid-header">{t("estimatedAdditionalRevenue")}</th>
                                        <th className="data-grid-header">{t("costEstimated")}</th>
                                        <th className="data-grid-header">{t("profitable")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gridData.map((data, index) => (
                                        <tr key={index}>
                                            <td className="data-grid-cell">{t(data.language)}</td>
                                            <td className="data-grid-cell">{data.playersGained.toFixed(0)}</td>
                                            <td className="data-grid-cell">{data.additionalRevenue.toFixed(0)}</td>
                                            <td className="data-grid-cell">{data.cost.toFixed(0)}</td>
                                            <td className="data-grid-cell">{data.isGood ? t("yes") : t("no")}</td>
                                        </tr>
                                    ))}
                                    <tr key={"summaryRow"}>
                                        <td className="data-grid-cell-summary first-cell-summary"><i className="icon-summary">&#8721;</i></td>
                                        <td className="data-grid-cell-summary ">{totalPlayersGained.toFixed(0)}</td>
                                        <td className="data-grid-cell-summary ">{totalAdditionalRevenue.toFixed(0)}</td>
                                        <td className="data-grid-cell-summary ">{totalCost.toFixed(0)}</td>
                                        <td className="data-grid-cell-summary last-cell-summary"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="email-section">
                            <div className="warning-box" style={{ border: '1px solid orange', borderRadius: '4px', padding: '10px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IoIosWarning style={{ marginRight: '10px' }}/> 
                                <p style={{ margin: 0, textAlign:"center"}}>
                                    {t("keepInMindEstimatesWarning")}
                                </p>
                                <IoIosWarning style={{ marginLeft: '10px' }}/> 
                            </div>
                            <h4 className='roi-header-text'>{t("roiCalculatorEmailSectionHeader")}</h4>
                            <input className="form-input" type="email" name="email" placeholder={t("email") as string} value={formData.email} onChange={handleChange} />
                            <button className="calculate-button" onClick={sendResults}>{t("sendResults")}</button>
                        </div>
                    </div>
                )}
            </div>
        </HelmetProvider>
    );
};

export default RoiCalculator;


