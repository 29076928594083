import { useNavigate } from "react-router-dom";

import { ReactComponent as DocumentIconSVG } from "../../../images/icons/docTranslHomepageIcon.svg";
import { ReactComponent as SubtitleIconSVG } from "../../../images/icons/subTranslHomepageIcon.svg";
import { ReactComponent as WebIconSVG } from "../../../images/icons/webTranslHomepageIcon.svg";
import IconRightButton from "../../../components/IconRightButton";

import "./OurProServices.css";
import { useTranslation } from "react-i18next";

const OurProServices = () => {
  const { t, i18n } = useTranslation();
  const currentPathBase = i18n.language === 'en' ? '' : `/${i18n.language}`; 
  let navigate = useNavigate();
  const routeChange = (path: string) => {
    navigate(path);
  };

  return (
    <div className="our-pro-services">
      <div className="container">
        <p className="our-pro-services-subtitle subtitle-mobile-size">{t('Translation innovation')}</p>
        <h2 className="our-pro-services-title title-mobile-size">{t('professional services')}</h2>
        <div className="services-wrapper">
          <div className="service-container">
            <DocumentIconSVG className="sercvice-icon" />
            <h4>{t('games localization short')}</h4>
            <p>
              {t('games localization content short')}
            </p>
            <IconRightButton
              buttonName={t('Read more')}
              theme="light"
              onClick={() => routeChange(`${currentPathBase}/our-services/games-localization`)}
            />
          </div>
          <div className="service-container">
            <SubtitleIconSVG className="sercvice-icon" />
            <h4>{t('subtitle translation short')}</h4>
            <p>
              {t('subtitle translation content_1 short')}
            </p>
            <IconRightButton
              buttonName={t('Read more')}
              theme="light"
              onClick={() => routeChange(`${currentPathBase}/our-services/subtitle-translation`)}
            />
          </div>
          <div className="service-container">
            <WebIconSVG className="sercvice-icon" />
            <h4>{t('docs translation short')}</h4>
            <p>{t('docs translation content')}</p>
            <IconRightButton
              buttonName={t('Read more')}
              theme="light"
              onClick={() => routeChange(`${currentPathBase}/our-services/document-translation`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProServices;
