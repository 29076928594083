import { useTranslation } from "react-i18next";
import "./BlogCard.css";

interface Image {
  blogMainImage: string;
  blogAuthorImage: string;
}

interface BlogContentParagraph {
  subtitle: string;
  text: string[];
  image?: string;
}

interface Text {
  subtitle: string;
  title: string;
  content: BlogContentParagraph[];
}

export interface BlogCardInterface {
  blogId: string;
  blogImages: Image;
  blogAuthor?: {
    authorName: string;
    blogDate: string;
    authorId: string;
  };
  blogText: Text;
  blogMetaData: IBlogMetaData;
}

interface IBlogMetaData {
  title: string;
  description: string;
  date: string;
}

interface IBlogCard {
  item: BlogCardInterface;
}

const BlogCard = ({ item }: IBlogCard) => {
  const { i18n } = useTranslation();
  const currentPathBase = i18n.language === 'en' ? '' : `/${i18n.language}`;

  return (
    <div className="blog-card-container">
      <a href={`${currentPathBase}/blog/${item.blogId}/`}>
        <img src={item.blogImages.blogMainImage} alt={item.blogText.title} className="blog-card-image" />
        <p className="blog-card-subtitle">{item.blogText.subtitle}</p>
        <h4 className="blog-card-title">{item.blogText.title}</h4>
        <p className="blog-card-context">{item.blogText.content[0].text[0].substring(0, 69) + "..."}</p>
      </a>
    </div>
  );
};

export default BlogCard;
