import React, { useState, createContext, useEffect, Dispatch, SetStateAction } from "react";
import i18n from "../../i18n";
import { useNavigate } from "react-router-dom";

export const LabelContext = createContext<Partial<ILabelContext>>({});

const languages: Language[] = [
  { id: 1, value: i18n.t("en") },
  { id: 2, value: i18n.t("ja") },
  { id: 3, value: i18n.t("tr") },
  { id: 4, value: i18n.t("ko") },
  { id: 5, value: i18n.t("fr") },
  { id: 6, value: i18n.t("de") },
  { id: 7, value: i18n.t("it") },
  { id: 8, value: i18n.t("pl") },
  { id: 9, value: i18n.t("uk") },
  { id: 10, value: i18n.t("ro") },
  { id: 11, value: i18n.t("el") },
  { id: 12, value: i18n.t("cs") },
];

export interface Language {
  id: number;
  value: string;
}

interface ILabelContext {
  websiteLang: string;
  page: number;
  nextPage: () => void;
  prevPage: () => void;
  labelInfo: LabelInfo;
  handleChange: (name: string, value: string) => void;
  setTranslateInfo: (prop: string) => (event: any) => void;
  setLanguagesInfo: (prop: string) => (event: any) => void;
  setUserContactsInfo: (prop: string) => (event: any) => void;
  setTranslateFrom: (language: Language) => void;
  setTranslateTo: (language: Language) => void;
  removeTranslateTo: (id: number) => void;
  setFiles: (file: File[], translate: Translate) => void;
  removeFiles: (id: number) => void;
  toggleCheckbox: (name: string, value: boolean) => void;
  resetLabelInfo: () => void;
  changeWebsiteLang: (lang: string) => void;
  isOurServicesOpen: boolean;
  isAboutUsOpen: boolean;
  isMobileMenuOpen: boolean;
  SetAboutUsOpen: Dispatch<SetStateAction<boolean>>;
  SetOurServicesOpen: Dispatch<SetStateAction<boolean>>;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}
export interface Translate {
  file: File[];
  text: string;
  note: string;
  certifyTranslations: boolean;
  couponCode: boolean;
}

export interface LabelInfo {
  translate: Translate;
  languages: {
    languagesArray: Language[];
    translateFrom: string;
    translateTo: Language[];
  };
  userContacts: {
    fName: string;
    lName: string;
    email: string;
  };
}

const initialLabelInfo = {
  translate: {
    file: [],
    text: "",
    note: "",
    certifyTranslations: false,
    couponCode: false,
  },
  languages: {
    languagesArray: languages,
    translateFrom: "",
    translateTo: [],
  },
  userContacts: {
    fName: "",
    lName: "",
    email: "",
  },
};

export const LabelProvider = (props: any) => {
  const [page, setPage] = useState(0);
  const [websiteLang, setWebsiteLang] = useState(i18n.language.toUpperCase());
  const [labelInfo, setlabelInfo] = useState<LabelInfo>(initialLabelInfo);
  const [isOurServicesOpen, SetOurServicesOpen] = useState(false);
  const [isAboutUsOpen, SetAboutUsOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const updatedLanguages = [
      { id: 1, value: i18n.t("en") },
      { id: 2, value: i18n.t("ja") },
      { id: 3, value: i18n.t("tr") },
      { id: 4, value: i18n.t("ko") },
      { id: 5, value: i18n.t("fr") },
      { id: 6, value: i18n.t("de") },
      { id: 7, value: i18n.t("it") },
      { id: 8, value: i18n.t("pl") },
      { id: 9, value: i18n.t("uk") },
      { id: 10, value: i18n.t("ro") },
      { id: 11, value: i18n.t("el") },
      { id: 12, value: i18n.t("cs") },
    ];

    const updatedTranslateFrom =
      updatedLanguages.find((lang) => lang.value === labelInfo.languages.translateFrom)?.value || "";
    const updatedTranslateTo = labelInfo.languages.translateTo.map((lang) => ({
      ...lang,
      value: updatedLanguages.find((updatedLang) => updatedLang.id === lang.id)?.value || lang.value,
    }));

    setlabelInfo({
      ...labelInfo,
      languages: {
        ...labelInfo.languages,
        languagesArray: updatedLanguages,
        translateFrom: updatedTranslateFrom,
        translateTo: updatedTranslateTo,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteLang]);

  const changeWebsiteLang = (lang: string) => {
    i18n.changeLanguage(lang);
    var urlbase = lang === 'en' ? '' : lang;
    navigate(`${urlbase}`);
    setWebsiteLang(lang.toUpperCase());
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleChange = (name: string, value: string) => {
    setlabelInfo({
      ...labelInfo,
      userContacts: { ...labelInfo.userContacts, [name]: value },
    });
  };

  const setTranslateInfo = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setlabelInfo({
      ...labelInfo,
      translate: { ...labelInfo.translate, [prop]: event.target.value },
    });
  };

  const setFiles = (files: File[], translate: Translate) => {
    const newFiles = [...labelInfo.translate.file, ...files];
    setlabelInfo({
      ...labelInfo,
      translate: {
        ...translate,
        file: newFiles,
      },
    });
  };

  const removeFiles = (id: number) => {
    const newFiles = labelInfo.translate.file.filter((item, idx) => idx !== id);
    setlabelInfo({
      ...labelInfo,
      translate: { ...labelInfo.translate, file: newFiles },
    });
  };

  const setLanguagesInfo = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setlabelInfo({
      ...labelInfo,
      languages: { ...labelInfo.languages, [prop]: event.target.value },
    });
  };
  const setTranslateFrom = (language: Language) => {
    setlabelInfo({
      ...labelInfo,
      languages: { ...labelInfo.languages, translateFrom: language.value },
    });
  };

  const toggleCheckbox = (name: string, value: boolean) => {
    setlabelInfo({
      ...labelInfo,
      translate: { ...labelInfo.translate, [name]: !value },
    });
  };
  const setTranslateTo = (language: Language) => {
    setlabelInfo({
      ...labelInfo,
      languages: { ...labelInfo.languages, translateTo: [...labelInfo.languages.translateTo, language] },
    });
  };
  const removeTranslateTo = (id: number) => {
    const newLanguages = labelInfo.languages.translateTo.filter((item) => item.id !== id);
    setlabelInfo({
      ...labelInfo,
      languages: { ...labelInfo.languages, translateTo: newLanguages },
    });
  };
  const setUserContactsInfo = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setlabelInfo({
      ...labelInfo,
      userContacts: { ...labelInfo.userContacts, [prop]: event.target.value },
    });
  };

  const resetLabelInfo = () => {
    setlabelInfo(initialLabelInfo);
    setPage(0);
  };

  return (
    <LabelContext.Provider
      value={{
        websiteLang,
        page,
        nextPage,
        prevPage,
        labelInfo,
        handleChange,
        setTranslateInfo,
        setLanguagesInfo,
        setUserContactsInfo,
        setTranslateFrom,
        setTranslateTo,
        removeTranslateTo,
        setFiles,
        removeFiles,
        toggleCheckbox,
        resetLabelInfo,
        changeWebsiteLang,
        isOurServicesOpen,
        SetOurServicesOpen,
        isAboutUsOpen,
        SetAboutUsOpen,
        isMobileMenuOpen,
        setMobileMenuOpen,
      }}
    >
      {props.children}
    </LabelContext.Provider>
  );
};
