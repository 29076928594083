import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from "react-router-dom";
import { cases } from "../CasesArray";
import { Fade } from "react-awesome-reveal";
import NumberCounter from "../../../components/NumberCounter";
import ContactUsBlock from "../../../components/ContactUsBlock";

const CaseDetail = () => {
    const { i18n } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const caseItem = cases.find(c => c.id === id);
    if (!caseItem) return <div>Case not found</div>;

    const language: string = i18n.language;
    const helmetCaseMetaDescription: string = caseItem.description;
    const helmetCaseTitle: string = `Case: ${caseItem.title} | Wordlords Localisation`;

    const baseURL = language === 'ua' ? 'https://wordlords.co/ua/about-us/our-cases/' : 'https://wordlords.co/about-us/our-cases/';

    // Structured data for SEO
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `${baseURL}${id}`
        },
        "headline": helmetCaseTitle,
        "image": caseItem.imageUrl,
        "datePublished": "2024-06-07", // Update this with the actual publish date
        "author": {
            "@type": "Organization",
            "name": "Wordlords"
        },
        "publisher": {
            "@type": "Organization",
            "name": "Wordlords",
            "logo": {
                "@type": "ImageObject",
                "url": "https://wordlords.co/logo.svg"
            }
        },
        "description": helmetCaseMetaDescription
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{helmetCaseTitle}</title>
                <meta name="description" content={helmetCaseMetaDescription} />
                <meta name="robots" content="index, follow" />
                <meta property="og:image" content={caseItem.imageUrl} />
                <meta property="og:title" content={helmetCaseTitle} />
                <meta property="og:description" content={helmetCaseMetaDescription} />
                <meta property="og:type" content="article" />
                {language === 'en' && (
                    <>
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:url" content={`${baseURL}${id}`} />
                        <link rel="canonical" href={`${baseURL}${id}`} />
                    </>
                )}
                {language === 'ua' && (
                    <>
                        <meta property="og:locale" content="uk_UA" />
                        <meta property="og:url" content={`${baseURL}${id}`} />
                        <link rel="canonical" href={`${baseURL}${id}`} />
                    </>
                )}
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <div className="bg-white min-h-screen">
                <div className="bg-black text-white py-32">
                    <div className="px-8 mx-auto text-center flex flex-col justify-center w-2/3">
                        <Fade direction="down" triggerOnce={true} cascade damping={0.3} delay={200} duration={1500}>
                            <h1 className="text-5xl font-bold mb-4">{caseItem.title}</h1>
                            <p className="text-lg">{caseItem.description}</p>
                        </Fade>
                    </div>
                </div>
                <div className="md:mx-44 py-8 px-8">
                    <div className="p-8 rounded-lg flex flex-col md:flex-row items-start justify-center space-y-8 md:space-y-0">
                        <div className="md:sticky top-32 md:w-1/2 w-full">
                            <img src={caseItem.imageUrl} alt={`${caseItem?.title}`} className="w-full h-auto rounded-lg"/>
                        </div>
                        <div className="flex flex-col space-y-8 md:w-1/2 w-full">
                            <div className="w-full px-8 flex flex-row space-x-8 justify-center text-center">
                                <NumberCounter end={caseItem.stats.numberOfLangPairs} prefix="" label={"Language pairs"}/>
                                <NumberCounter end={caseItem.stats.wordsTranslated} prefix="" label={"Words translated"}/>
                            </div>
                            <div className="w-full text-lg mb-4 md:px-10 space-y-8" dangerouslySetInnerHTML={{ __html: caseItem.fullDescription }} />
                        </div>
                    </div>
                </div>
                <ContactUsBlock />
            </div>
        </HelmetProvider>
    );
};

export default CaseDetail;
