import { useTranslation } from 'react-i18next';

export interface ContactUsErrors {
  firstName?: string | undefined;
  lastName?: string | undefined;
  email: string | undefined;
  message: string | undefined;
}

export const useValidateContactUs = () => {
  const { t } = useTranslation();

  const validate = (name: string, value: string) => {
    switch (name) {
      case 'fName':
      case 'lName':
        if (!value || value.trim() === '') {
          return t('required field');
        } else {
          return '';
        }
      case 'email':
        if (!value) {
          return t('required field');
        } else if (
          !value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          return t('not valid email');
        } else {
          return '';
        }
      case 'message':
        if (!value) {
          return t('required field');
        } else {
          return '';
        }
      default: {
        return '';
      }
    }
  };

  return validate;
};
