import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-awesome-reveal';
import "./Vacancy.css";
import PrimaryButton from '../PrimaryButton';
import { CareersService } from "../../services/careers/CareersService";
import { IVacancy } from '../../services/careers/IVacancy';

const VacancyPage = () => {
    const { vacancyId } = useParams();
    const [vacancy, setVacancy] = useState(null as IVacancy | null);
    const { i18n } = useTranslation();
    const language = i18n.language;

    const datePosted = new Date(vacancy?.datePosted as string);
    datePosted.setMonth(datePosted.getMonth() + 6);

    useEffect(() => {
        new CareersService().getVacancy(vacancyId as string).then(setVacancy);
    }, [vacancyId]);

    const generateStructuredData = (vacancy: IVacancy) => {
        if (!vacancy) return;

        return {
            "@context" : "https://schema.org/",
            "@type" : "JobPosting",
            "title" : vacancy.title,
            "description" : `<p>${vacancy.jobPostingDescription}</p>`,
            "datePosted" : vacancy.datePosted,
            "validThrough" : datePosted.toISOString().split('T')[0],
            "applicantLocationRequirements": {
              "@type": "Country",
              "name": "USA"
            },
            "jobLocationType": "TELECOMMUTE",
            "employmentType": "PART_TIME",
            "hiringOrganization" : {
              "@type" : "Organization",
              "name" : "Wordlords",
              "sameAs" : "https://wordlords.co",
              "logo" : "https://api.wordlords.co/images/logo.png"
            },
            "occupationalCategory": "27-3091.00"
        }
    };

    const onFormButtonClick = () => {
        if (vacancy && vacancy.formLink) {
            window.open(vacancy.formLink, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{vacancy?.title}</title>
                <meta name="description" content={`Learn more about the ${vacancy?.title} role at Wordlords.`} />
                <meta name="robots" content="index, follow"/>
                <meta property="og:title" content={vacancy?.title} />
                <meta property="og:locale" content="en_US" />
                
                {language === 'en' && (
                <meta property="og:url" content={`https://wordlords.co/careers/${vacancyId}/`} />
                )}
                {language === 'ua' && (
                <meta property="og:url" content={`https://wordlords.co/ua/careers/${vacancyId}/`} />
                )}

                {language === 'en' && (
                <link rel="canonical" href={`https://wordlords.co/careers/${vacancyId}/`} />
                )}
                {language === 'ua' && (
                <link rel="canonical" href={`https://wordlords.co/ua/careers/${vacancyId}/`} />
                )}
                <script type="application/ld+json">
                    {JSON.stringify(generateStructuredData(vacancy as IVacancy))}
                </script>
            </Helmet>
            <div className="careers-container-primary">
                <Fade direction="down" triggerOnce={true} cascade damping={0.3} delay={200} duration={1500}>
                    <h1 className="careers-heading-h1">{vacancy?.title}</h1>
                    <h3 className="careers-heading">Open vacancy</h3>
                </Fade>
            </div>
            <div className="careers-container">
                <div className='vacancy-body-container'>
                    <div className='vacancy-header-container'>
                        <div className='go-back-container'>
                            <a href={`${language === 'ua' ? '/ua' : ''}/careers/`} className="link style-1">
                                <svg className="arrow-icon-reverse" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                    <g fill="none" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
                                    <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
                                    <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                                    </g>
                                </svg>
                                <span className='go-back-text'>Go back</span>
                            </a>
                        </div>
                        <div className="tags-container">
                            {vacancy?.tags.map((tag, index) => (
                                <div key={index} className="tag">{tag}</div>
                            ))}
                        </div>
                        <div className='tag-grey'>
                            Posted: {vacancy?.datePosted}
                        </div>
                    </div>
                    <div className='vacancy-description-container'>
                        {vacancy?.body?.map((section, index) => (
                            <div key={index} className='careers-section-container'>
                                <h4>{section.title}</h4>
                                <div>
                                    {section.subtext?.map((subtext, subIndex) => (
                                        <p key={subIndex}>{subtext}</p>
                                    ))}
                                </div>
                                <ul>
                                    {section.bulletPoints?.map((bulletPoint, bpIndex) => (
                                        <li key={bpIndex}>{bulletPoint}</li>
                                    ))}
                                </ul>
                                <div>
                                    {section.endtext?.map((subtext, etIndex) => (
                                        <p key={etIndex}>{subtext}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className='careers-footer-container'>
                            <PrimaryButton
                                buttonName="Apply here"
                                onClick={onFormButtonClick}
                                customClass="careers-footer-btn"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
};

export default VacancyPage;
