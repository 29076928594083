import Slider from "react-slick";
import { ReactComponent as PartnersSVG } from "../../images/icons/contact-us-partners-img.svg";
import { ReactComponent as OtherSVG } from "../../images/icons/contact-us-other-img.svg";
import OtherSVGImage from "../../images/icons/contact-us-other-img.svg";
import { ReactComponent as ClientsSVG } from "../../images/icons/contact-us-client-img.svg";
import { ReactComponent as PressSVG } from "../../images/icons/contact-us-press-img.svg";
import "./Contacts.css";
import { useTranslation } from "react-i18next";
import EmailContainer from "../../components/EmailContainer";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ContactUsPopUp from "../../components/ContactUsPopUp";
import { ContactType } from "../../services/hubspot/contact";
import { ToastContainer } from "react-toastify";
import { useState } from "react";

const ContactUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
    ],
  };

  const [selectedContactType, setSelectedContactType] = useState('');
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const helmetContactUsMetaDescription = t("helmetContactUsMetaDescription");
  const helmetContactUsKeywords = t("helmetContactUsKeywords");
  const helmetContactUsTitle = t("helmetContactUsTitle");

  const handleLinkClick = (type: string) => {
    setSelectedContactType(type);
    setIsPopUpOpen(true);
  };

  return (
    <HelmetProvider>
      <ToastContainer autoClose={3000} />
      <div className="contact-cards">
        <Helmet>
          <title>{helmetContactUsTitle}</title>
          <meta name="description" content={helmetContactUsMetaDescription} />
          <meta name="keywords" content={helmetContactUsKeywords} />
          <meta name="robots" content="index, follow" />
          <meta property="og:image" content={OtherSVGImage} />
          <meta property="og:title" content={`${t('Contact Us')} | Wordlords`} />
          <meta property="og:description" content={helmetContactUsMetaDescription} />
          {language === 'en' && <meta property="og:locale" content="en_US" />}
          {language === 'ua' && <meta property="og:locale" content="uk_UA" />}
          {language === 'en' && <meta property="og:url" content="https://wordlords.co/contact-us/" />}
          {language === 'ua' && <meta property="og:url" content="https://wordlords.co/ua/contact-us/" />}
          {language === 'en' && <link rel="canonical" href="https://wordlords.co/contact-us/" />}
          {language === 'ua' && <link rel="canonical" href="https://wordlords.co/ua/contact-us/" />}
        </Helmet>
        <div className="container">
          <p className="contact-cards-subtitle subtitle-mobile-size">{t("contact us subtitle")}</p>
          <h2 className="contact-cards-title title-mobile-size">{t("contact us title")}</h2>
          <Slider {...settings} className="contact-us-cards-container">
            <div className="contact-us-card-container">
              <PartnersSVG />
              <div onClick={() => handleLinkClick(ContactType.partnership)} className="linkWrap">
                <div className="link style-1">
                  {t("For partners")}
                  <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
                      <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
                      <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="contact-us-card-container">
              <ClientsSVG />
              <div onClick={() => handleLinkClick(ContactType.lead)} className="linkWrap">
                <div className="link style-1">
                  {t("For clients")}
                  <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
                      <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
                      <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="contact-us-card-container">
              <PressSVG />
              <div onClick={() => handleLinkClick(ContactType.press)} className="linkWrap">
                <div className="link style-1">
                  {t("For press")}
                  <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
                      <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
                      <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="contact-us-card-container">
              <OtherSVG />
              <div onClick={() => handleLinkClick(ContactType.other)} className="linkWrap">
                <div className="link style-1">
                  {t("For other questions")}
                  <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
                      <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
                      <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </Slider>
          {isPopUpOpen && <ContactUsPopUp setIsPopUpOpen={setIsPopUpOpen} contactType={selectedContactType} />}
          <h5 className="mail-us-container">
            {t("Or mail us")}
            <div className="devider-mail"></div>
            <EmailContainer emailAddress="info@wordlords.co" />
          </h5>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ContactUs;
