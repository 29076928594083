import "./PrimaryButton.css";

interface PrimaryButtonInterface {
  disabled?: () => boolean;
  buttonName: string;
  onClick?: () => void;
  customClass?: string;
}

const PrimaryButton = ({ disabled, buttonName, onClick, customClass }: PrimaryButtonInterface) => {
  return (
    <>
      <button disabled={disabled && disabled()} onClick={onClick} className={`main-btn ${customClass}`}>
        {buttonName}
      </button>
    </>
  );
};

export default PrimaryButton;
