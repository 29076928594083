import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { useValidateBookADemo } from "../../../../components/BookADemoPopUp/BookADemoValidate";
import CustomInput from "../../../../components/CustomInput";
import IconLeftButton from "../../../../components/IconLeftButton";
import IconRightButton from "../../../../components/IconRightButton";
import { LabelContext } from "../../labelDataContext";
import { stepperPageHandler } from "../ContentStep";

import "./QuoteStep.css";

const QuoteStep = () => {
  const { t } = useTranslation();
  const valueLabel = useContext(LabelContext);
  const firstName = valueLabel.labelInfo?.userContacts.fName;
  const lastName = valueLabel.labelInfo?.userContacts.lName;
  const email = valueLabel.labelInfo?.userContacts.email;
  const validate = useValidateBookADemo();


  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    valueLabel.handleChange!(name, value);

    setErrors({
      ...errors,
      [name]: validate(name, value),
    });
  };

  const [errors, setErrors] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  const btnDisabled = () => {
    if (
      errors.fName === "" &&
      errors.lName === "" &&
      errors.email === "" &&
      firstName !== "" &&
      lastName !== "" &&
      email !== ""
    ) {
      return false;
    } else return true;
  };

  return (
    <div className="quote-step-wrapper">
      <div className="quote-step-container">
        <h5>{t('send translation')}</h5>
        <div className="input-container">
          <CustomInput
            errors={errors.fName}
            label={t('name')}
            inputName="fName"
            value={firstName}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('name placeholder')}
            type="text"
          />
          <CustomInput
            errors={errors.lName}
            label={t('last name')}
            inputName="lName"
            value={lastName}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('surname placeholder')}
            type="text"
          />
          <CustomInput
            errors={errors.email}
            label={t('email')}
            inputName="email"
            value={email}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('email placeholder')}
            type="email"
          />
        </div>
      </div>
      <div className="navigation-button-container">
        <IconLeftButton buttonName={t('previous')} onClick={() => stepperPageHandler!(valueLabel.prevPage!)} />
        <IconRightButton
          disabled={btnDisabled()}
          buttonName={t('next')}
          theme="light"
          onClick={() => stepperPageHandler!(valueLabel.nextPage!)}
        />
      </div>
    </div>
  );
};

export default QuoteStep;
