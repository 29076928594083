import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ContactUsBlock = () => {
    const { t, i18n } = useTranslation();
    const currentUrl = i18n.language === 'en' ? "/contact-us/" : "/ua/contact-us/";
    return (
        <div className="bg-grey-3 text-balck py-16">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold mb-4">{t('Interested in Our Services?')}</h2>
                <p className="text-lg mb-8">
                    {t('contactUsBlockDescription')}
                </p>
                <Link to={currentUrl} className="main-btn">
                    {t('Contact Us')}
                </Link>
            </div>
        </div>
    );
};

export default ContactUsBlock;
