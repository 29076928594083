import "./TeamMember.css";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from "react-router-dom";
import { OurTeamArray } from "../OurTeam/OurTeamArray";
import AuthorBlogPosts from "../../components/BlogsSlider";

import { ReactComponent as LinkedinSVG } from "../../images/icons/linkedin-logo-icon.svg";
import { ReactComponent as EmailSVG } from "../../images/icons/emailScuare.svg";

const TeamMember = () => {
    const { teamMemberId } = useParams();
    const currentTeamMember = OurTeamArray.find((item) => item.teamMemberId === teamMemberId);

    const { t, i18n } = useTranslation();
    const language: string = i18n.language;
    const helmetOurTeamMetaDescription: string = t(currentTeamMember?.descriptionKey as string).substring(0, 160);
    const helmetOurTeamMemberTitle: string = `${t(currentTeamMember?.firstName as string)} ${t(currentTeamMember?.lastName as string)} | Wordlords ${t(currentTeamMember?.title as string)}`;
    return (
        <HelmetProvider>
            <Helmet>
                    <title>{helmetOurTeamMemberTitle}</title>
                    <meta name="description" content={helmetOurTeamMetaDescription} />
                    <meta name="robots" content="index, follow"/>

                    <meta property="og:image" content={currentTeamMember?.image}/>
                    <meta property="og:title" content={helmetOurTeamMemberTitle} />
                    <meta property="og:description" content={helmetOurTeamMetaDescription} />
                    {language === 'en' && (
                    <meta property="og:locale" content="en_US" />
                    )}
                    {language === 'ua' && (
                    <meta property="og:locale" content="uk_UA" />
                    )}
                    
                    {language === 'en' && (
                    <meta property="og:url" content={`https://wordlords.co/about-us/our-team/${teamMemberId}/`} />
                    )}
                    {language === 'ua' && (
                    <meta property="og:url" content={`https://wordlords.co/ua/about-us/our-team/${teamMemberId}/`} />
                    )}

                    {language === 'en' && (
                    <link rel="canonical" href={`https://wordlords.co/about-us/our-team/${teamMemberId}/`} />
                    )}
                    {language === 'ua' && (
                    <link rel="canonical" href={`https://wordlords.co/ua/about-us/our-team/${teamMemberId}/`} />
                    )}
                    
            </Helmet>
            <div className="team-member-page-container">
                <div className="team-member-header">
                    <h2 className="team-member-header-title">
                        {helmetOurTeamMemberTitle}
                    </h2>
                </div>
                <div className="team-member-main">
                    <img src={currentTeamMember?.image} alt={`${currentTeamMember?.title}'s`} className="team-member-image-overlap">
                        
                    </img>
                    
                    <p className="team-member-description">
                        {t(currentTeamMember?.descriptionKey as string)}
                    </p>

                    <div className="team-member-social-links-container">
                        <a href={currentTeamMember?.linkedinLink} target="_blank" rel="noreferrer" className="team-member-social-link" >
                            <LinkedinSVG width="30px" height="30px" className="social-icon" />
                        </a>
                        <a href={`mailto:${currentTeamMember?.email}`} target="_blank" rel="noreferrer" className="team-member-social-link" >
                            <EmailSVG width="30px" height="30px" className="social-icon" />
                        </a>
                    </div>
                </div>
                <div className="team-member-blogs">
                    <AuthorBlogPosts authorId={currentTeamMember?.teamMemberId}/>
                </div>
            </div>
        </HelmetProvider>
    );
};

export default TeamMember;
