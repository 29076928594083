import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "helmetOurCasesMetaDescription": "Discover the successful projects and case studies of Wordlords Translation Company. Learn how we deliver top-notch localization and subtitle translation services, with a focus on cultural adaptation and accuracy.",
      "helmetOurCasesKeywords": "translation services, localization, subtitle translation, Wordlords Translation, case studies, project success, cultural adaptation, accurate translation, Ukrainian translation, English translation",
      "helmetOurCasesTitle": "Our Business Cases | Wordlords Translation",
      'Interested in Our Services?': 'Interested in Our Services?',
      "contactUsBlockDescription": "Contact us today to find out how we can help you with our expert subtitle translation and localization services.",
      "Contact Us": "Contact Us",
      ourCasesDescription: "Discover how we have helped our clients achieve success through our expert translation and localization services.",
      "Our Business Cases": "Our Business Cases",
      "Cases in detail:": "Cases in detail:",
      "Other Notable Projects:": "Other Notable Projects:",
      keepInMindEstimatesWarning: "Please note that some values are estimates, and marketing and other expenses are not included in the overall cost.",
      helmetRoiCalculatorTitle: "Calculate Translation ROI for your project",
      helmetRoiCalculatorMetaDescription: "Discover the potential return on investment for your translation projects with our intuitive ROI Calculator. Effortlessly estimate your translation ROI and make informed decisions with ease.",
      helmetRoiCalculatorKeywords: "translation ROI calculator, translation ROI estimation, return on investment in translation, ROI calculation for translators, translation estimation tool, improving translation ROI, translation projects ROI analysis, economic efficiency of translation, optimization of translation costs, calculation of translation profitability",
      "roi-calculator": "ROI Calculator",
      "select": "Select...",
      "English": "English",
      "Chinese": "Chinese",
      "Hindi": "Hindi",
      "Spanish": "Spanish",
      "French": "French",
      "Arabic": "Arabic",
      "Bengali": "Bengali",
      "Russian": "Russian",
      "Portuguese": "Portuguese",
      "Indonesian": "Indonesian",
      "Urdu": "Urdu",
      "German": "German",
      "Japanese": "Japanese",
      "Swahili": "Swahili",
      "Marathi": "Marathi",
      "Telugu": "Telugu",
      "Turkish": "Turkish",
      "Korean": "Korean",
      "Tamil": "Tamil",
      "Vietnamese": "Vietnamese",
      "Italian": "Italian",
      "Hausa": "Hausa",
      "Thai": "Thai",
      "Gujarati": "Gujarati",
      "Kannada": "Kannada",
      "Polish": "Polish",
      "Ukrainian": "Ukrainian",
      "Persian": "Persian",
      "Punjabi": "Punjabi",
      "Malay": "Malay",
      roiCalculatorEmailSectionHeader: "You can send an exel of the table to your email here:",
      yes: "Yes",
      no: "No",
      profitable: "Profitable",
      costEstimated: "Estimated cost",
      estimatedAdditionalRevenue: "Estimated Additional Annual Revenue",
      estimatedPlayersGained: "Estimated Players Gained",
      sendResults: "Send Results",
      results: "Results",
      calculate: "Calculate",
      checkboxOnlyProftableLabel: "Show only profitable",
      targetLanguagesSelectLabel: "Select target languages",
      sourceLanguageSelectLabel: "Source language",
      estimatedWordcountForProject: "Estimated Wordcount of your Project",
      averageAnnualRevenuePerPlayer: "Average Annual Revenue per Player",
      estimatedPlayerBase: "Estimated Player Base",
      roiCalculatorDescription2: "Select a source language, enter player base of your game and fill in the avarage revenue from a player.",
      roiCalculatorDescription1: "This calculator helps you estimate the return on investment (ROI) for translation of your game project to different languages based on your player base, revenue per player, and word count of your project in source language.",
      roiCalculatorHeading: "ROI Calculator",
      authorBlogsTitle: "Author latests blogs:",
      hoqDescription: "Olha Serhieva, as the Head of Quality at Wordlords, brings over a decade of experience in the translation industry, specializing in the creative sectors. Her profound expertise and innovative leadership ensure that our translations not only meet but exceed quality standards, perfectly marrying linguistic accuracy with creative expression. Olha's commitment to excellence and her strategic approach to quality assurance have been pivotal in establishing Wordlords as a trusted partner for clients seeking unparalleled translation services. Under her guidance, we continue to navigate the nuances of language with precision and passion, making every word count in the vast landscape of creative industries.",
      ceoDescription: "Acorn Dobrovolskyy, the esteemed CEO of Wordlords Localisation Solutions, commands the helm with a visionary zeal and a profound commitment to surpassing industry standards. Specializing in creative translation, Wordlords under Acorn's leadership excels in bridging linguistic gaps with innovation and artistry, catering to a myriad of sectors requiring nuanced and culturally resonant translations. With over a six years of rich experience in the language services industry, Acorn's expertise has been pivotal in propelling our company to the forefront of the field. Guided by his foresight, Wordlords continues to unify cultures, enhance global communication, and enable businesses around the world to thrive in multilingual and multicultural landscapes.",
      ctoDescription: "As Chief Technology Officer, Andrian Zafiiovskiy spearheads our engineering endeavors, orchestrating the planning of future projects and adeptly managing our exceptional team of developers. With a keen eye for innovation and a steadfast commitment to excellence, he ensures that Wordlords remains at the cutting edge of technology, driving our mission forward with precision and expertise.",
      ourTeamPeopleHeading: "A team united by the common goal:",
      ourTeamPeopleSubheading: "Making creative industries accessible to everyone",
      ourTeamJoinHeading: "Join Wordlords!",
      ourTeamJoinButtonName: "See our open positions",
      peopleBehindWordlords: "People behind Wordlords",
      whoWeAre: "Who we are?",
      helmetOurTeamKeywords: "Wordlords team, creative professionals, innovation in creativity, creative industries access, team diversity, Wordlords mission, creative talent, industry leaders, creative visionaries, shaping creativity, inclusivity in creativity",
      helmetOurTeamTitle: "Meet Our Team | Shaping the Future of Creativity | Wordlords",
      helmetOurTeamMetaDescription: "Explore the heart of Wordlords: a driven team committed to unlocking the creative industries for all. Discover our unique blend of talent and passion that shapes the future of creativity at Wordlords",
      videoBackgroundAlt: "Your browser does not support the video tag.",
      experienceYearsLabel: "Years of expertise",
      translatorsNumberLabel: "Translators",
      progectsDeliveredLabel: "Progects delivered",
      subtitlesDeliveredLabel: "Subtitles translated",
      wordsDeliveredLabel: "Words translated",
      sending: "Sending",
      successContactMessage: "Thanks for contacting us. We get back to you shortly! P.S. Don't forget to check spam folder not to miss out mails.",
      errorContactMessage: "Something went wrong while sending your message :(",
      message: "Message",
      "message palceholder": "Ask anything you want and we will contact you right away!",
      contactPopupTitleLead: "For clients",
      contactPopupTitlePartner: "For partnerships",
      contactPopupTitlePress: "For press",
      contactPopupTitleOther: "For other questions",
      home: "Home",
      about: "About",
      ourteam: "Our Team",
      ourcases: "Our Cases",
      "our services": "Our services",
      blog: "Blog",
      "games": "Games localization",
      "subtitle": "Subtitle translation",
      "document": "Document translation",
      "heroTitle": "Unleash Your Words",
      heroDescription:
        "Wordlords is a translation agency dedicated to ease the localisation process for creative industries by using cutting edge technologies.",
      "partners title":"Trusted by Global Companies",
      "get quote": "Get quote",
      "get a quote": "Get a quote",
      "book demo": "Book a demo",
      "translations easy": "Professional translation made easy",
      content: "Content",
      language: "Language",
      quote: "Quote",
      "submit order": "Submit order",
      "what translate": "What would you like to translate today?",
      "drag drop": "Drag&drop files here",
      or: "or",
      browse: "Browse files",
      Or: "Or",
      "paste text": "Paste or type your text here",
      "5 steps": "5 simple steps",
      "how it works": "How we work",
      "choose file": "Choose the file and upload",
      "get a quote submit": "Get a quote and submit your project",
      "Order summary": "Order summary",
      "From/To": "From/To",
      "Delivery date": "Delivery date",
      "Order total": "Order total",
      "agile agency": "Most agile agency out there",
      Convenience: "Convenience",
      "Live progress": "Live progress",
      "Expert translators": "Expert translators",
      "Cloud based solution": "Cloud based solution",
      "professional services": "Our professional services",
      "Translation innovation": "Translation through innovation",
      "Read more": "Read more",
      "our clients": "Our clients say it best",
      "easy way": "The easy way to get your projects translated fast",
      "buy online": "Buy online in a few clicks",
      name: "First Name",
      "last name": "Last Name",
      email: "Email",
      date: "Date",
      talk: "Let’s Talk",
      "get translated project": "Get your translated project back",
      "latest blogs": "Our latest blog posts",
      "one option": "Please, select only one option",
      "upload file": "Upload file",
      "get projects": "Get your translated project back",
      getInstantQuote: "Get an instant quote",

      "why choose us": "Why Choose Us",
      "why choose us content":
        "We carefully select only the native target language speakers with a totally fluent understanding of English to perform expert translations in WordLords. Every one of them is an experienced translator with exceptionally high standards of work. Their work is always carefully supervised and proofread by our experienced editors, who ensure the level of quality to match the highest standards of world leading media, who set them. Our primary focus is on English to Ukrainian and Ukrainian to English translations, but more language pairs are also available, tailored for your needs.",

      "games localization short": "Games localization",
      "games localization": "Games localization and translations",
      "games localization content short":
        "From board games to RPG , to phone games, to computer games – we perform highest quality adaptive translation.",
      "games localization content":
        "From board games to RPG , to phone games, to computer games – we perform highest quality adaptive translation either for whole project or for its parts, including new maps, new scenarios, patches, special events.\n We at WordLords are great fans of gaming and are happy to introduce great games to more people speaking different languages. Translation and localization of games is one of our core activities and we welcome every new project, dedicating our full creativity and passion to each one of them.",
      "games localization question":"Unleash the Power of Localization in Gaming",
      "games localization answer1":"Unlock Global Success: Expand your game's horizons by harnessing the prowess of WordLords, experts in game localization.",
      "games localization answer2":"Cross Cultural Mastery: We fuse our passion for gaming with our linguistic finesse, ensuring your game resonates with players worldwide.",
      "games localization answer3":"Seamless Integration: Our team seamlessly integrates localization into your game development process, enabling smooth workflows and timely releases.",
      "games localization answer4":"Conquer New Frontiers: With WordLords by your side, conquer new markets in over 100 languages, captivating gamers across the globe.",

      "subtitle translation short": "Subtitle translations",
      "subtitle translation": "Subtitle and dubbing scripts translations",
      "subtitle translation content_1 short":
        "We at WordLords are experts in timed text translations and timing subtitled events to speech and on-screen events.",
      "subtitle translation content_1":
        "We at WordLords are experts in timed text translations and timing subtitled events to speech and on-screen events. Subtitles are our passion, as we believe that Ukrainians deserve to hear original actors’ voices while being able to fully understand the conveyed message of every scene. We use the world’s best practices and always match our work to the highest level of standards and requirements in this industry.",
      "subtitle translation content_2":
        "We also perform dubbing scripts translation and adaptation for a variety of language pairs. Dubbing scripts are also a highly demanding creative adaptation of the translated text. We make sure to make the job for voice actors easy and enjoyable, while the translation is always error-free, perfectly localized and timed to audio.",
      "subs title": "Please feel free to get a quote for subtitles or dubbing scripts or scenarios translations for:",
      "subs film": "Films",
      "subs serial": "TV shows",
      "subs commercial": "Commercials",
      "subs trailer": "Trailers",
      "subs doc": "Documentary footages",
      "subs clip": "Music videos",

      "docs translation short": "Document translations",
      "docs translation": "Text and document translations",
      "docs translation content":
        "Highest quality and top responsibility for the end result – is our motto with any texts or documents that you or your business might need translated.",
      "docs translation question":"Unleash the Power of Seamless Document Translation",
      "docs web": "Website translations",
      "docs business": "Business presentations translation",
      "docs tech": "Technical specifications translation",
      "docs contract": "Contracts translation",
      "docs marketing": "Marketing materials translation",
      "docs literature": "Literature translation – books, novels, articles",

      "customer testimonials": "Customer testimonials",
      testimonials1:
        "We have trusted WordLords to perform subtitles translations for our video materials and media materials adaptation, including scripts translations and news digests translations for our service, VICE. WordLords are a loyal, true and timely partner even in the most turbulent of times.",
      testimonials2:
        "With WordLords we can always count on best creative translation of games and adapting them for Ukrainian market. Our company, Boardova, has successfully launched multiple board and RPG games and we hope to do more in the coming years in cooperation with our localization partner.",
      testimonials3:
        "Being a private commercial company with foreign partners, we often needed high quality translations. Therefore, my business has been relying on WordLords for timely and precise translations of our website, marketing materials, and commercial documents over the last 2 years. Impeccable work!",
      "translate better": "Translate better and faster",

      previous: "Previous",
      next: "Next",

      from: "Translate From",
      to: "Translate To",
      "select language": "Select Language",
      "all lang selected": "All languages selected",
      "send translation": "Send the translation version to",
      "required field": "This field is required.",
      "not valid email": "Email is not valid.",
      "Please choose date": "Please choose date",

      "notes translators": "Do you have any notes for our translators?",
      "specifics project":
        "This is the place to let our translators know about the specifics of your project. Please try to be as descriptive as possible.",
      "word count": "We counted 19 words in your document(s)",
      "coupon code": "I have a coupon code",
      instructions:
        "Explain what we need to  pay attention to in your translation. Elaborate as much as possible; the more context you provide our translators with, the better your translations are",
      source: "Source document(s) in your quote",
      "request quote": "Request quote",
      copyright: "Copyright 2023 © WordLords.com - All rights reserved",
      onTheWeb: "On the web",
      notFoundTitle: "Ooops...\n Page not found",
      notFoundDescription:
        "The page you are looking for doesn’t exist or an other error occurred, go back to home page.",
      notFoundButton: "Go Home",
      "en": "English",
      "ja": "Japanese",
      "tr": "Turkish",
      "ko": "Korean",
      "fr": "French",
      "de": "German",
      "it": "Italian",
      "pl": "Polish",
      "uk": "Ukrainian",
      "ro": "Romanian",
      "el": "Greek",
      "cs": "Czech",
      "name placeholder": "John",
      "surname placeholder": "Doe",
      "email placeholder": "mail@example.com",
      "year placeholder":"DD/MM/YYYY",
      "received request":"Your request has been received. \nOur manager will get to you soon! \nThanks for choosing Wordlords.",
      "person1":"Lester Mcgrath",
      "person2":"Naomi Morgan",
      "person3":"Ruslana Koropetska",
      "helmetMainTitile": "Language & Technology Solutions for Business | Wordlords",
      "helmetMainDescription": "Wordlords: Your Gateway to Innovative Translation Services. Experience automated delivery tailored for media, game studios, and beyond, breaking language barriers with cutting-edge solutions.",
      "helmetInstantQuoteTitle" : "Get Instant Translation Quotes | Wordlords",
      "helmetInstantQuoteDescription" : "Elevate your translation journey with WordLords' Instant Quote service. Calculate your translation pricing effortlessly and schedule a consultation with our expert team. Seamlessly navigate through the process of estimating costs and connecting with our dedicated professionals for personalized translation solutions.",
      "contact us": "Contact us",
      "contact us subtitle": "Our contacts",
      "contact us title": "Ask anything you want!",
      "Or mail us": "Or mail us",
      "For partners": "For partners",
      "For press": "For press",
      "For clients": "For clients",
      "For other questions": "For other questions",
      "helmetContactUsMetaDescription": "Contact Wordlords for partnerships, client inquiries, press requests, and other questions. Explore our expertise in language translation, localization, and content creation.",
      "helmetContactUsKeywords": "Wordlords, contact us, partnerships, clients, press, questions, language translation, localization, content creation",
      "helmetContactUsTitle": "Contact Us | WordLords - Leading Translation Agency for Media and Gaming",
      "helmetCareersMetaDescription": "Join the Wordlords team and embark on a rewarding career in language translation, localization, and content creation. Explore our current job openings and be part of a dynamic and innovative environment that values expertise and creativity.",
      "helmetCareersKeywords": "Wordlords careers, job openings, employment opportunities, join our team, language translation careers, localization jobs, content creation positions, innovative work culture, dynamic team environment, career growth",
      "helmetCareersTitle": "Careers at WordLords - Join Our Innovative Team in Translation and Localization",
      "careers": "Careers",
      "careers-title": "Become the Lord of the Words",
      "careers-subtitle": "Join Wordlords - a company specialized in creative translation",
    },
  },

  ua: {
    translation: {
      "helmetOurCasesMetaDescription": "Відкрийте для себе успішні проекти та кейси компанії Wordlords Translation. Дізнайтеся, як ми надаємо високоякісні послуги з локалізації та перекладу субтитрів з акцентом на культурну адаптацію та точність.",
      "helmetOurCasesKeywords": "послуги перекладу, локалізація, переклад субтитрів, Wordlords Translation, кейси, успішні проекти, культурна адаптація, точний переклад, український переклад, англійський переклад",
      "helmetOurCasesTitle": "Наші Бізнес Кейси | Wordlords Translation",
      'Interested in Our Services?': 'Цікавлять наші послуги?',
      "contactUsBlockDescription": "Напишіть нам сьогодні і ми проконсультуємо вас по будь яких питаннях.",
      "Contact Us": "Наші контакти",
      ourCasesDescription: "Дізнайтеся, як ми допомогли нашим клієнтам досягти успіху завдяки креативним послугам перекладу та локалізації.",
      "Our Business Cases": "Наші Бізнес Кейси",
      "Cases in detail:": "Більше про деякі наші кейси:",
      "Other Notable Projects:": "Інші проекти, що треба згадати:",
      keepInMindEstimatesWarning: "Зверніть увагу, що деякі значення є приблизними, а маркетингові та інші витрати не включені до загальних витрат на переклад.",
      helmetRoiCalculatorMetaDescription: "Відкрийте для себе потенційну віддачу від інвестицій у ваші перекладацькі проєкти за допомогою нашого інтуїтивно зрозумілого калькулятора ROI. Легко оцініть ROI вашого перекладу та приймайте обґрунтовані рішення з легкістю.",
      helmetRoiCalculatorTitle: "Чи вигідно перекладати? ROI перекладу.",
      helmetRoiCalculatorKeywords: "ROI перекладу, чи вигіднй переклад для мого проекту, калькулятор ROI перекладу, оцінка ROI перекладу, оптимізація витрат на переклад, розрахунок прибутковості перекладу",
      "roi-calculator": "ROI Калькуляор",
      "English": "Англійська",
      "Chinese": "Китайська",
      "Hindi": "Гінді",
      "Spanish": "Іспанська",
      "French": "Французька",
      "Arabic": "Арабська",
      "Bengali": "Бенгальська",
      "Russian": "Російська",
      "Portuguese": "Португальська",
      "Indonesian": "Індонезійська",
      "Urdu": "Урду",
      "German": "Німецька",
      "Japanese": "Японська",
      "Swahili": "Суахілі",
      "Marathi": "Маратхі",
      "Telugu": "Телугу",
      "Turkish": "Турецька",
      "Korean": "Корейська",
      "Tamil": "Тамільська",
      "Vietnamese": "В'єтнамська",
      "Italian": "Італійська",
      "Hausa": "Хауса",
      "Thai": "Тайська",
      "Gujarati": "Гуджараті",
      "Kannada": "Каннада",
      "Polish": "Польська",
      "Ukrainian": "Українська",
      "Persian": "Перська",
      "Punjabi": "Пенджабі",
      "Malay": "Малайська",
      roiCalculatorEmailSectionHeader: "Тут ви можете надіслати обчислення в Exel собі на пошту:",
      yes: "Так",
      no: "Ні",
      profitable: "Прибутково",
      costEstimated: "Приблизні витрати",
      estimatedAdditionalRevenue: "Приблизий додатковий річний дохід",
      estimatedPlayersGained: "Приблизна к-ть нових гравців",
      sendResults: "Надіслати результати",
      results: "Результати",
      calculate: "Порахувати",
      checkboxOnlyProftableLabel: "Показувати лише прибуткові мови",
      targetLanguagesSelectLabel: "Оберіть мови перекладу",
      sourceLanguageSelectLabel: "Вихідна мова",
      estimatedWordcountForProject: "Приблизна кількість слів у вашому проекті",
      averageAnnualRevenuePerPlayer: "Приблизний середній річний дохід з гравця",
      estimatedPlayerBase: "Орієнтовна кількість гравців",
      roiCalculatorDescription2: "Для початку обчислення - виберіть вихідну мову, введіть базу гравців вашої гри та введіть середній дохід від гравцяю",
      roiCalculatorDescription1: "Цей калькулятор допоможе вам оцінити рентабельність інвестицій (ROI) для перекладу вашого ігрового проекту різними мовами на основі вашої бази гравців, середнього річного доходу на гравця та кількості слів вашого проекту вихідною мовою.",
      roiCalculatorHeading: "Калькулятор прибутку з інвестицій",
      authorBlogsTitle: "Останні блоги автора:",
      hoqDescription: "Відданість Ольги досконалості та її стратегічний підхід до забезпечення якості відіграли ключову роль у тому, що Wordlords став надійним партнером для клієнтів, які шукають якісні перекладацькі послуги. Під її керівництвом ми продовжуємо розкривати нюанси мови з точністю та пристрастю, роблячи кожне слово важливим у широкому ландшафті креативних індустрій.",
      ceoDescription: "Жолудь Добровольський, генеральний директор Wordlords LSP, з понад десятиліттям досвіду, веде компанію до нових вершин у сфері креативного перекладу. Завдяки його лідерству, Wordlords LSP відома своїми інноваційними рішеннями у перекладі, що дозволяє ефективно об'єднувати культури та відкривати для бізнесів по всьому світу шляхи до мультикультурного спілкування.",
      ctoDescription: "Як CTO, Андріан Зафійовський очолює наші інженерні зусилля, організовує планування майбутніх проектів і керує нашими винятковими талантами. З відмінним розумінням інновацій та непохитною прихильністю до досконалості, він забезпечує, щоб Wordlords творили нове та неймовірне, просуваючи нашу місію вперед з точністю та компетентністю.",
      ourTeamPeopleHeading: "Команда об'єднана єдиною метою::",
      ourTeamPeopleSubheading: "Зробити креативне доступним для всіх",
      ourTeamJoinHeading: "Приєднуйся до нас!",
      ourTeamJoinButtonName: "Наші вакансії тут",
      peopleBehindWordlords: "Команда Wordlords",
      whoWeAre: "Хто ми?",
      "Andrian": "Андріан",
      "Zafiiovskiy": "Зафійовський",
      "Acorn": "Жолудь",
      "Dobrovolskiy": "Добровольский",
      "Olha": "Ольга",
      "Serhieva": "Сергієва",
      helmetOurTeamKeywords: "команда Wordlords, креативні професіонали, інновації у креативності, доступ до креативних індустрій, різноманітність команди, місія Wordlords, креативний талант, лідери індустрії, креативні візіонери, формування креативності, інклюзивність у креативності",
      helmetOurTeamTitle: "Команда Wordlords | З нами культурні кордони пропадають",
      helmetOurTeamMetaDescription: "Команда Wordlords: це мотивовані люди, що прагнуть зробити креативні проекти доступними для всіх. Відкрийте нас для себе, а ми відкриємо для вас культурні кордони.",
      videoBackgroundAlt: "Ваш браузер не підтримує відео.",
      experienceYearsLabel: "Років досвіду",
      translatorsNumberLabel: "Перекладачів",
      progectsDeliveredLabel: "Успішних проектів",
      subtitlesDeliveredLabel: "Субтитрів перекладено",
      wordsDeliveredLabel: "Слів переладено",
      sending: "Надсилання",
      successContactMessage: "Дякую, що написали нам. Ми відпишемо якнайшвидше! Не забудь перевірити спам пошти, якщо не можеш найти нашу автоматизовану відповідь.",
      errorContactMessage: "Щось пішло не так :(",
      message: "Для питань/пропозицій",
      "message palceholder": "Запитуйте усе, що вас цікавить. Ми відповідаємо дуже швидко!",
      contactPopupTitleLead: "Для клієнтів",
      contactPopupTitlePartner: "Для партнерів",
      contactPopupTitlePress: "Для преси",
      contactPopupTitleOther: "Для інших питань",
      home: "Головна сторінка",
      about: "Про нас",
      ourteam: "Наша команда",
      ourcases: "Наші кейси",
      "our services": "Наші послуги",
      blog: "Блог",
      games: "Локалізація ігор",
      subtitle: "Переклад субтитрів",
      document: "Переклад документів",
      heroTitle: "Дайте волю словам",
      heroDescription:
        "Wordlords - це перекладатська компанія, що фокусується на спрощенні локалізації для керативної індустрії, користуючись найкращими технологіями на ринку.",
        "partners title":"Нам довіряють світові компанії",
        "get quote": "Дізнатися вартість",
      "get a quote": "Дізнатися вартість",
      "book demo": "Забронювати демо",
      "translations easy": "Професійний переклад без турбот",
      content: "Вміст",
      language: "Мови",
      quote: "Вартість",
      "submit order": "Підтвердити замовленя",
      "what translate": "Що б ви хотіли сьогодні перекласти?",
      "drag drop": "Перетягніть файл(-и) сюди",
      or: "або",
      browse: "Завантажити файл",
      Or: "Або",
      "paste text": "Вставте або введіть текст сюди",
      "5 steps": "5 простих кроків",
      "how it works": "Як це працює",
      "choose file": "Виберіть та завантажте файл",
      "get a quote submit": "Дізнайтеся вартість та замовте переклад",
      "Order summary": "Підсумок замовлення",
      "From/To": "Мовна пара",
      "Delivery date": "Дата завершення",
      "Order total": "Всього",
      "agile agency": "Найбільш гнучка компанія на ринку",
      Convenience: "Зручність",
      "Live progress": "Прогрес в реальному часі",
      "Expert translators": "Експертні перекладачі",
      "Cloud based solution": "Хмарниий сервіс",
      "professional services": "Наші професійні послуги",
      "Translation innovation": "Інноваційний переклад",
      "Read more": "Читати далі",
      "our clients": "Про нас говорять наші клієнти",
      "easy way": "Простий спосіб швидко перекласти свої проєкти",
      "buy online": "Замовляйте онлайн у кілька кліків",
      name: "Ім’я",
      "last name": "Прізвище",
      email: "Електронна адреса",
      date: "Дата",
      talk: "Давайте поговоримо",
      "get translated project": "Отримайте свій перекладений файл",
      "latest blogs": "Останні дописи в блозі",
      "one option": "Будь ласка, виберіть лише один варіант",
      "upload file": "Завантажити файл",
      "get projects": "Отримайте свій перекладений файл",
      getInstantQuote: "Дізнайтеся вартість миттєво",

      "why choose us": "Чому Саме Ми",
      "why choose us content":
        "Наші перекладачі завжди є носіями мови, на яку ведеться переклад, та досконало й вільно володіють англійською на експертному рівні. Кожен наш перекладач є досвідченим професіоналом, робота якого відповідає найвищим фаховим вимогам. Роботу перекладачів курують та перевіряють досвідчені редактори, які забезпечують дотримання якості на рівні провідних медіа-компаній, що визначають стандарти галузі.",

      "games localization short": "Локалізація ігор",
      "games localization": "Локалізація ігор",
      "games localization content short":
        "Настільні ігри, рольові ігри, ігри для смартфонів, комп’ютерні ігри – для всіх типів ігор ми виконаємо адаптивний переклад найвищої якості.",
      "games localization content":
        "Настільні ігри, рольові ігри, ігри для смартфонів, комп’ютерні ігри – для всіх типів ігор ми виконаємо адаптивний переклад найвищої якості. Ми працюємо як із проєктами в цілому, так і з частинами проєктів, такими як: нові мапи, нові сценарії, патчі, події, заходи.\n Команда WordLords є справжніми фанатами ігор, ми завжди радіємо можливості надати доступ до чудових ігор новим гравцям – людям, що розмовляють різними мовами. Переклад та локалізація ігор є одним з наших основних видів діяльності, тож ми радо вітаємо  кожен новий проєкт і вкладаємо в кожен з них усю нашу творчість і пристрасть.",
      "games localization question":"WordLords: Дайте волю локалізації ігор",
      "games localization answer1":"Розблокуйте світовий успіх: Розширте горизонти своєї гри, скориставшись майстерністю WordLords, експертів з локалізації ігор.",
      "games localization answer2":"Крос-культурна майстерність: Ми поєднуємо нашу пристрасть до ігор із мовною витонченістю, щоб ваша гра знаходила відгук у гравців по всьому світу.",
      "games localization answer3":"Бездоганна інтеграція: Наша команда бездоганно інтегрує локалізацію в процес розробки гри, забезпечуючи безперебійний робочий процес і своєчасні релізи.",
      "games localization answer4":"Підкорюйте нові кордони: Підкорюйте понад 100 нових мовних ринків разом із WordLords, зачаровуючи геймерів по всьому світу.",
      "subtitle translation short": "Переклад субтитрів",
      "subtitle translation": "Переклад субтитрів та сценаріїв для дублювання",
      "subtitle translation content_1 short":
        "WordLords є експертами з перекладу тексту, синхронізованого з відеорядом, і точного розташування субтитрів у прив’язці до голосу акторів або тексту на екрані.",
      "subtitle translation content_1":
        "WordLords є експертами з перекладу тексту, синхронізованого з відеорядом, і точного розташування субтитрів у прив’язці до голосу акторів або тексту на екрані. Субтитрування особливе тим, що глядач має змогу чути оригінальні голоси та гру акторів, але при цьому повністю розуміти зміст і сенс кожної сцени. Для нас робота із субтитрування є особливою, бо саме такий спосіб перегляду фільмів є основним у Європі та світі, тож ми прагнемо нести його українським глядачам. Наша робота відповідає найвищим вимогам та стандартам цієї індустрії.",
      "subtitle translation content_2":
        "Ми також здійснюємо переклади та адаптацію сценаріїв для дубляжу, адже дублювання потребує креативної адаптації перекладеного тексту. З нами робота акторів-дублерів буде легкою та приємною, а переклад стрічки – завжди точним, ідеально локалізованим та синхронізованим.",
      "subs title": "Звертайтеся за перекладами субтитрів або сценаріїв для дублювання:",
      "subs film": "Художніх фільмів",
      "subs serial": "Серіалів",
      "subs doc": "Документальних зйомок",
      "subs clip": "Кліпів",
      "subs commercial": "Рекламних роликів",
      "subs trailer": "Трейлерів",

      "docs translation short": "Переклад документів",
      "docs translation": "Переклад текстів та документів",
      "docs translation content":
        "Найвища якість та відповідальність за кінцевий результат – ось наш девіз у роботі з текстами й документами, переклади яких потрібні вам і вашому бізнесу.",
      "docs translation question":"Дайте волю безперебійному перекладу документів",
      "docs web": "Переклад вебсайтів",
      "docs business": "Переклад бізнес-презентацій",
      "docs tech": "Переклад технічних специфікацій",
      "docs contract": "Переклад контрактів",
      "docs marketing": "Переклад маркетингових матеріалів",
      "docs literature": "Літературний переклад – книги, оповіді, статті",

      "customer testimonials": "Відгуки клієнтів",
      testimonials1:
        "Ми довірили WordLords переклад субтитрів для наших відео-матеріалів та адаптацію медіа-матеріалів, включно з перекладами сценаріїв, а також переклади новин нашої служби, VICE. Хлопці з WordLords показали, що фірма є надійним, лояльним та своєчасним партнером навіть у найтяжчі часи.",
      testimonials2:
        "WordLords – це завжди найкращі креативні переклади ігор та їхня адаптація до ринку України. Наша компанія, Boardova, випустила на український ринок серію успішних настільних і рольових ігор, і ми сподіваємося на нові проєкти у найближчі роки разом із нашими партнерами з локалізації.",
      testimonials3:
        "Як приватна комерційна фірма, яка має партнерів за кордоном, ми часто потребували якісних перекладів. Тому наша компанія покладається на WordLords уже понад два роки. Перекладений вебсайт, маркетингові матеріали, комерційні документи – усе швидко, якісно та конфіденційно.",
      "translate better": "Перекладайте краще та швидше",

      previous: "Назад",
      next: "Вперед",

      from: "Перекласти з",
      to: "Перекласти на",
      "select": "Вибрати...",
      "select language": "Вибрати мову",
      "all lang selected": "Вибрано всі мови",
      "send translation": "Надіслати перекладену версію",
      "required field": "Це поле обов'язкове.",
      "not valid email": "Електронна пошта не є дійсною.",
      "Please choose date": "Будь ласка оберіть дату",

      "notes translators": "Чи є у вас якісь побажання для наших перекладачів?",
      "specifics project":
        "Це місце, де ви можете повідомити наших перекладачів про особливості вашого проекту. Будь ласка, намагайтесь бути максимально описовими.",
      "word count": "Ми нарахували у вашому документі 19 слів",
      "coupon code": "У мене є промокод",
      instructions:
        "Поясніть, на що нам слід звернути увагу у вашому перекладі. Максимально розкривайте; чим більше контексту ви надаєте нашим перекладачам, тим кращі будуть ваші переклади",
      source: "Вихідні документи у вашому запиті",
      "request quote": "Розрахувати вартість",
      copyright: "2023 © WordLords — Усі права захищено",
      onTheWeb: "У вебі",
      notFoundTitle: "Упс...\n Сторінку не знайдено",
      notFoundDescription:
        "Сторінка, яку ви шукаєте, не існує або сталася інша помилка, поверніться на головну сторінку.",
      notFoundButton: "Повернутися на головну",
      "en": "Англійська",
      "ja": "Японська",
      "tr": "Турецька",
      "ko": "Корейська",
      "fr": "Французька",
      "de": "Німецька",
      "it": "Італійська",
      "pl": "Польська",
      "uk": "Українська",
      "ro": "Румунська",
      "el": "Грецька",
      "cs": "Чеська",
      "name placeholder": "Іван",
      "surname placeholder": "Петренко",
      "email placeholder": "Емейл",
      "year placeholder":"ДД/ММ/РРРР",
      "received request":"Ваш запит отримано. \nНаш менеджер зв'яжеться з вами найближчим часом! \nДякуємо, що обрали WordLords.",
      "person1":"Лестер МакГрат",
      "person2":"Наомі Морган",
      "person3":"Руслана Коропетська",
      "helmetMainTitile": "Мовні та технологічні рішення для бізнесу | Wordlords",
      "helmetMainDescription": "Ласкаво просимо до Wordlords, де мова зустрічається з технологіями. Підвищте якість вашого контенту завдяки нашим автоматизованим перекладацьким послугам, спеціально розробленим для медіа і гейм-індустрій. Подолайте мовні бар'єри та переосмисліть свою глобальну стратегію.",
      "helmetInstantQuoteTitle" : "Отримай вартість перекладу | Wordlords",
      "helmetInstantQuoteDescription" : "Підніміть свій досвід перекладу завдяки сервісу миттєвого котування від WordLords. Легко розрахуйте вартість перекладу та заплануйте консультацію з нашою експертною командою. Безперешкодно оцінюйте витрати та зв'язуйтеся з нашими висококваліфікованими професіоналами для персоналізованих перекладацьких рішень.",
      "contact us": "Контакти",
      "contact us subtitle": "Наші контакти",
      "contact us title": "Завжди раді відповідсти на усі питання!",
      "Or mail us": "Або напишіть нам:",
      "For partners": "Для партнерів",
      "For press": "Для преси",
      "For clients": "Для клієнтів",
      "For other questions": "Для інших питань",
      "helmetContactUsMetaDescription": "Зв'яжіться з Wordlords для партнерства, запитів клієнтів, запитів преси та інших питань. Ознайомтеся з нашим досвідом у галузі перекладу мов, локалізації та створення контенту.",
      "helmetContactUsKeywords": "Wordlords, зв'яжіться з нами, партнерство, клієнти, преса, питання, переклад мов, локалізація, створення контенту",
      "helmetContactUsTitle": "Наші контакти | WordLords - Найкраща агенція перекладу для медіа та ігор",
      "helmetCareersMetaDescription": "Приєднуйтесь до команди Wordlords та розпочніть кар'єру в галузі перекладу, локалізації та створення контенту. Ознайомтесь з нашими актуальними вакансіями та станьте частиною динамічного та інноваційного середовища, яке цінує експертизу та креативність.",
      "helmetCareersKeywords": "Кар'єра в Wordlords, вакансії, можливості працевлаштування, приєднайтесь до нашої команди, кар'єра в перекладах, робота в локалізації, позиції зі створення контенту, інноваційна робоча культура, динамічне середовище команди, кар'єрний ріст",
      "helmetCareersTitle": "Кар'єра в WordLords",
      "careers": "Кар'єра",
      "careers-title": "Стань лордом серед слів",
      "careers-subtitle": "Приє`днуйся до Wordlords, компанії, що спеціалізується на креативному перекладі",
    },
  },
};

i18n
  .on("languageChanged", (lang) => {
    localStorage.setItem("i18nextLng", lang);
  })
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") || "en",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
