const isProduction = process.env.NODE_ENV === 'production';

const baseUri = isProduction ? 'https://api.wordlords.co' : 'http://localhost:3001';

const contactsEndPoint = `${baseUri}/api/contacts`;
const roiCalculatorEndPoint = `${baseUri}/api/roicalculator`;

export {
  contactsEndPoint,
  roiCalculatorEndPoint
};