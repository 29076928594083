import { useContext } from "react";

import CustomDropdownSelect from "../../../../components/CustomDropdownSelect";
import IconLeftButton from "../../../../components/IconLeftButton";
import IconRightButton from "../../../../components/IconRightButton";
import { LabelContext } from "../../labelDataContext";
import { ReactComponent as RemoveFileSVG } from "../../../../images/icons/removeFileIcon.svg";

import "./LanguageStep.css";
import { stepperPageHandler } from "../ContentStep";
import { useTranslation } from "react-i18next";

const LanguageStep = () => {
  const { t } = useTranslation();

  const value = useContext(LabelContext);
  const btnDisabled = () => {
    if (value.labelInfo?.languages.translateFrom !== "" && value.labelInfo!.languages.translateTo.length > 0) {
      return false;
    } else return true;
  };

  return (
    <div
      className="language-input-wrapper"
    >
      <div className="language-input-container">
        <h5>{t('language')}</h5>
        <div className="dropdown-container">
          <CustomDropdownSelect
            label={t('from')}
            selected={value.labelInfo?.languages.translateFrom!}
            languagesArray={value.labelInfo?.languages.languagesArray!}
            setFunction={value.setTranslateFrom!}
          />
          <div className="dropdown-selected-container">
            <CustomDropdownSelect
              label={t('to')}
              selected={value.labelInfo?.languages.translateTo!}
              languagesArray={value.labelInfo?.languages.languagesArray!}
              setFunction={value.setTranslateTo!}
            />
            <div className="selected-container">
              {value.labelInfo?.languages.translateTo.map((item) => (
                <div key={item.id} className="chip-container" onClick={() => value.removeTranslateTo!(item.id)}>
                  <p>{item.value}</p>
                  <RemoveFileSVG />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-button-container">
        <IconLeftButton buttonName={t('previous')} onClick={() => stepperPageHandler!(value.prevPage!)} />
        <IconRightButton
          disabled={btnDisabled()}
          buttonName={t('next')}
          theme="light"
          onClick={() => stepperPageHandler!(value.nextPage!)}
        />
      </div>
    </div>
  );
};

export default LanguageStep;
