import Card from "./Card";
import Slider from "react-slick";
import testimonialsArray from "./TestimonialsArray";

import "./Testimonials.css";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
    ],
  };

  const {t} = useTranslation();

  return (
    <div className="testimonials">
      <div className="container">
        <p className="testimonials-subtitle subtitle-mobile-size">{t('our clients')}</p>
        <h2 className="testimonials-title title-mobile-size">{t('customer testimonials')}</h2>
        <Slider {...settings}>
          {testimonialsArray.map(({ person, media, id }) => (
            <Card key={id} id={id} person={person} media={media} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
