import { ToastContainer } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import InstantQuote from "./InstantQuote";
import LatestBlogPosts from "./LatestBlogPosts";
import OurProServices from "./OurProServices";
import Testimonials from "./Testimonials";
import WhyChooseUs from "./WhyChooseUs";
import { useTranslation } from "react-i18next";
import OgLogo from "../../images/worldordslogohey.webp";

const Homepage = () => {
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  const mainHelmetDescription = t("helmetMainDescription");
  const mainHelmetTitle = t("helmetMainTitile");
  const helmetCanonicalHref = pathname === '/' ? `https://wordlords.co/` : `https://wordlords.co${pathname}/`;
  const blogURL = 'https://wordlords.co/blog/localization-player-count/';

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://wordlords.co/",
    "logo": "https://wordlords.co/logo.svg",
    "contactPoint": [{
      "@type": "ContactPoint",
      "email": "info@wordlords.co",
      "contactType": "Contact Us",
    }],
    "sameAs": [
      "https://www.linkedin.com/company/wordlords"
    ],
    "potentialAction": [
      {
        "@type": "ContactAction",
        "target": "https://wordlords.co/contact-us"
      },
      {
        "@type": "ReadAction",
        "target": blogURL
      }
    ]
  };

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>{mainHelmetTitle}</title>
          <link rel="canonical" href={helmetCanonicalHref} />
          <meta name="description" content={mainHelmetDescription} />

          <meta property="og:title" content={mainHelmetTitle} />
          <meta property="og:description" content={mainHelmetDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={helmetCanonicalHref} />
          <meta property="og:image" content={OgLogo} />
          <meta property="og:site_name" content={mainHelmetTitle} />
          <meta property="og:locale" content="en_US" />

          <meta name="twitter:title" content={mainHelmetTitle} />
          <meta name="twitter:description" content={mainHelmetDescription} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content="@zafiovskiy" />
          <meta name="twitter:image" content={OgLogo} />
          <meta name="twitter:url" content={helmetCanonicalHref} />

          <meta name="robots" content="index, follow" />

          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <ToastContainer autoClose={5000} />
        <Hero />
        <WhyChooseUs />
        <HowItWorks />
        <OurProServices />
        <Testimonials />
        <InstantQuote />
        <LatestBlogPosts />
      </>
    </HelmetProvider>
  );
};

export default Homepage;
