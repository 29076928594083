import personImage1 from "../../../images/vice-logo.jpeg";
import personImage2 from "../../../images/boardova-logo.jpeg";
import personImage3 from "../../../images/ua-comix-logo.png";
import { CardInterface } from "./Card";

const testimonialsArray: CardInterface[] = [
  {
    person: {
      name: "Aleks Mikuch",
      date: "27.01.2022",
      testimonial:
        "Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus, nascetur ridiculus mus",
    },
    media: {
      type: "image",
      content: {
        src: personImage1,
      },
    },
    id: 1,
  },
  {
    person: {
      name: "Aleks Mikuch",
      date: "13.08.2022",
      testimonial:
        "Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus, nascetur ridiculus mus",
    },
    media: {
      type: "image",
      content: {
        src: personImage2,
      },
    },
    id: 2,
  },
  {
    person: {
      name: "Aleks Mikuch",
      date: "03.09.2022",
      testimonial:
        "Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus, nascetur ridiculus mus",
    },
    media: {
      type: "image",
      content: {
        src: personImage3,
      },
    },
    id: 3,
  },
];

export default testimonialsArray;
