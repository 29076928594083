import { toast } from "react-toastify";
import { Email } from "./sendInquiryToEmail";
import { InputData } from "../components/BookADemoPopUp";
import i18n from "../i18n";

declare global {
  interface Window {
    Email: Email;
  }
}

const formatMailBody = (
  data: InputData
) => {
  let result =
    `Name: ${data.fName} ${data.lName} <br/>` +
    `Email: ${data.email} <br/>` +
    `Requested demo date: ${data.requestedDate} <br/>`   

  return result;
};

// uses smtpjs from CDN
export const sendBookADemo = async (inqury: InputData) => {
  const { send } = window.Email;

  console.log("sending book demo")
  await toast.promise(
    send({
      Host: "smtp.elasticemail.com",
      Username: "info@wordlords.co",
      Password: "DED64A65066324C406BD205467527F253EC8",
      To: `info@wordlords.co`,
      From: `info@wordlords.co`,
      Subject: `Book a demo from Wordlords site`,
      Body: formatMailBody(inqury),
    }),
    {
      pending: "Sending",
      success: i18n.t('received request') as string,
      error: "Something went wrong. \nPlease try again",
    }
  );
};
