import { ReactComponent as BtnMoreSVG } from "../../images/icons/rightBtnArrow.svg";

import "./IconRightButton.css";

interface IconRightButtonInterface {
  buttonName: string;
  theme?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

const IconRightButton = ({ buttonName, disabled, theme, onClick, className }: IconRightButtonInterface) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${theme === "light" ? "icon-right-btn" : "icon-right-btn dark"}`}
    >
      <p>{buttonName}</p>
      <BtnMoreSVG />
    </button>
  );
};

export default IconRightButton;
