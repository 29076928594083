import axios from 'axios';
import { roiCalculatorEndPoint } from '../apiEndpoints';
import { IRoiCalculatorServiceEmailRequest } from './roiCalculatorEmailRequest';

export class RoiCalculatorService {
  async sendRoiCalculatorResponse(request: IRoiCalculatorServiceEmailRequest): Promise<void> {
    try {
      const config = {
        headers: {
          'content-type': 'application/json'
        },
      };

    await axios.post(roiCalculatorEndPoint, request, config);
    } catch (e: any) {
      e.message === 'HTTP request failed'
        ? console.error(JSON.stringify(e.response, null, 2))
        : console.error(e);

      throw e;
    }
  }
}
