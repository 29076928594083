import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import NavList from "../NavList";
import LangSelector from "../LangSelector";
import MobileMenu from "../MobileMenu";
import { ReactComponent as BurgerIconSVG } from "../../images/icons/burgerMenuIcon.svg";
import { ReactComponent as LogoIconSVG } from "../../images/icons/logo.svg";

import "./Header.css";
import { toggleModal } from "../BookADemoPopUp";
import { LabelContext } from "../../pages/InstantQuotePage/labelDataContext";
import { useTranslation } from "react-i18next";



const Header = (): JSX.Element => {
  const { i18n } = useTranslation();
  const currentPathBase = i18n.language === 'en' ? '/' : `/${i18n.language}/`; 
  const {isMobileMenuOpen, setMobileMenuOpen} = useContext(LabelContext);
  const { pathname } = useLocation();
  const [background, setBackground] = useState(false);
  const [theme, setTheme] = useState("");

  const checkBackground = () => {
    setBackground(window.scrollY > 80 ? true : false);
  };

  const isDynamicHeaderUrl = (pathname: string): boolean => {
    return pathname === "/" || pathname === "/ua/" || pathname.startsWith("/careers/") 
      || pathname.startsWith("/ua/careers/") || pathname.startsWith("/about-us/our-team/")
      || pathname.startsWith("/ua/about-us/our-team/");
  }

  useEffect(() => {
    document.addEventListener("scroll", checkBackground);

    return () => document.removeEventListener("scroll", checkBackground);
  }, []);

  useEffect(() => {
    if (isDynamicHeaderUrl(pathname)) {
      if (background) {
        setTheme("light");
      } else {
        setTheme("dark");
      }
    } else {
      setTheme("light");
    }
  }, [pathname, background]);

  return (
    <header className={`header ${background ? "sticky" : ""} ${theme}`}>
      <div className="container">
        <div className="header-container">
          <NavLink to={currentPathBase}>
            <div className="logo-container">
              <LogoIconSVG className="header-logo" title="WordLords logo" />
              <h2 className={`logo-text ${theme} ${background ? "light" : ""}`}>WordLords</h2>
            </div>
          </NavLink>
          <BurgerIconSVG
            onClick={() => {
              toggleModal(setMobileMenuOpen!, !isMobileMenuOpen);
            }}
            className={`header-burger-menu-icon ${theme}`}
          />
          {isMobileMenuOpen && <MobileMenu isOpen={isMobileMenuOpen} setIsOpen={setMobileMenuOpen!}  />}
          <div className="header-navigation">
            <NavList theme={theme} />
            <div className="header-lang">
              <LangSelector location="header" theme={theme}  />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
