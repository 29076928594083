import { IVacancy, IVacancySection, VacancyType } from "./IVacancy";

const CareersArray: IVacancy[] = [
    {
        vacancyId: "any-translator",
        tags: ["Translator", "Creative Industries"],
        title: "Creative Industry Translator",
        body: [
            {
                title: "Why Wordlords?",
                bulletPoints: [
                    "No barrier communication. We value time and freedom, so we are always in touch and helpful, whether you are an in-house employee or a freelancer.",
                    "Competitive compensation packages.",
                    "Creative and interesting projects.",
                    "Fast-growing environment."
                ]
            },
            {
                title: "What you’ll do in this role at Wordlords?",
                bulletPoints: [
                    "Translate media, gaming, and other projects from the creative space.",
                    "Work with MemoQ, Phrase, and other newest CAT tools."
                ]
            },
            {
                title: "What we think will help you succeed in this role?",
                bulletPoints: [
                    "We seek seasoned translators who can demonstrate their proficiency with a test task.",
                    "Experience with CAT tools and BMS (Business Management Systems) systems.",
                    "Passionate about creative industries."
                ]
            },
            {
                title: "How to apply?",
                subtext: [
                    "Fill the form below which will take no longer than 5 minutes, and we will contact you if there will be any projects for your skillset."
                ]
            }
        ] as IVacancySection[],
        formLink: "https://tally.so/r/w8ZbVz",
        type: VacancyType.SubmissionByForm,
        datePosted: '2023-02-07',
        jobPostingDescription: 'Join Wordlords as an Any Language Pair Translator and be part of our mission to break communication barriers across the globe. In this role, you will have the opportunity to work on translating diverse media and gaming projects, leveraging the latest CAT tools like MemoQ and Phrase. Ideal candidates are seasoned translators with experience in CAT tools and BMS systems, and a passion for the creative industries. We offer competitive compensation, a fast-growing environment, and the freedom to work as a freelancer or an in-house employee. Apply now to contribute to creative and interesting projects!'
    },
    {
        vacancyId: "sales-representative",
        tags: ["Sales", "Creative Industries", "Client Relations"],
        title: "Sales Representative",
        body: [
            {
                title: "Why Wordlords is the Place for You",
                bulletPoints: [
                    "Dive into the vibrant world of creative industries, where every day brings something new.",
                    "Collaborate directly with our CTO and CEO, becoming a key player in our mission to connect cultures.",
                    "A chance to flex your sales muscles with the coolest clients from gaming, media, movies, and more.",
                    "Competitive salary, with a dash of freedom and a sprinkle of creativity."
                ]
            },
            {
                title: "Your Mission Should You Choose to Accept",
                bulletPoints: [
                    "Identify and engage potential clients within the EU and US creative industries, weaving our translation services into their projects.",
                    "Craft bespoke proposals that speak the language of gaming, media, and movies, showcasing how Wordlords can elevate their content.",
                    "Navigate the thrilling landscape of LSPs and translation industry clients, leveraging any experience you have to seal the deal."
                ]
            },
            {
                title: "Is This Role Tailored for You?",
                bulletPoints: [
                    "You have a knack for sales with a proven track record, especially if you’ve dazzled in the creative sectors.",
                    "Experience with LSPs or the translation industry? Awesome! Not a deal-breaker if not; we value potential.",
                    "You’re a self-starter who loves the freedom to explore and capture new business landscapes.",
                    "Excellent communication skills, a strategic mindset, and a touch of creativity are your tools of the trade."
                ]
            },
            {
                title: "Ready to Join Our Quest?",
                subtext: [
                    "Leap into this adventure by filling out the form below. It’s your first step towards making a global impact with your sales prowess and our linguistic wizardry."
                ]
            }
        ] as IVacancySection[],
        formLink: "https://tally.so/r/3qAGd5", 
        type: VacancyType.SubmissionByForm,
        datePosted: '2024-02-10', 
        jobPostingDescription: 'Wordlords is on the hunt for a Creative Industries Sales Representative, a role crafted for those who thrive in the dynamic worlds of gaming, media, and movies. Working alongside our visionary CTO and CEO, you’ll spearhead our expansion into the EU and US markets, using your charm and sales acumen to bring our cutting-edge translation services to creative industry giants. If you have experience with LSPs or a passion for breaking new ground, we’re looking for you. Dive into a role where creativity meets strategy, and your efforts directly fuel our global journey. Apply now and let’s rewrite the future of communication together!'
    }
];

export default CareersArray;
