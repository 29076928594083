import "./Careers.css";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import VacancyCard from "../../components/VacancyCard/intex";
import { Fade } from "react-awesome-reveal";
import { useEffect, useState } from "react";
import { CareersService } from "../../services/careers/CareersService";
import { IVacancyCard } from "../../services/careers/IVacancyCard";

const Careers = () => {
  const { t, i18n } = useTranslation();
  const careersService = new CareersService();
  const [vacancyCards, setVacancyCards] = useState([] as IVacancyCard[]);
  const language: string = i18n.language;
  const helmetCareersMetaDescription: string = t("helmetCareersMetaDescription");
  const helmetCareersKeywords: string = t("helmetCareersKeywords");
  const helmetCareersTitle: string = t("helmetCareersTitle");
  
  useEffect(() => {
     careersService.getVacancyCards().then((result) => setVacancyCards(result))
  });
  
  return (
    <HelmetProvider>
        <div>
            <div className="careers-container-primary">
                <Fade direction="down" triggerOnce={true} cascade damping={0.3} delay={200} duration={1500}>
                    <h1 className="careers-heading">{t('careers-title')}</h1>
                    <h3 className="careers-heading">{t('careers-subtitle')}</h3>
                </Fade>
            </div>
            <div className="careers-container">
            <Helmet>
                <title>{helmetCareersTitle}</title>
                <meta name="description" content={helmetCareersMetaDescription} />
                <meta name="keywords" content={helmetCareersKeywords} />
                <meta name="robots" content="index, follow"/>

                <meta property="og:image" content=""/>
                <meta property="og:title" content={helmetCareersTitle} />
                <meta property="og:description" content={helmetCareersMetaDescription} />
                {language === 'en' && (
                <meta property="og:locale" content="en_US" />
                )}
                {language === 'ua' && (
                <meta property="og:locale" content="uk_UA" />
                )}
                
                {language === 'en' && (
                <meta property="og:url" content="https://wordlords.co/careers/" />
                )}
                {language === 'ua' && (
                <meta property="og:url" content="https://wordlords.co/ua/careers/" />
                )}

                {language === 'en' && (
                <link rel="canonical" href="https://wordlords.co/careers/" />
                )}
                {language === 'ua' && (
                <link rel="canonical" href="https://wordlords.co/ua/careers/" />
                )}
                
            </Helmet>
                <div className="careers-container-headings">
                    <h2 className="careers-list-heading">
                        Our current job openings
                    </h2>
                    <span>{language === 'ua' && (
                            <span className="language-note">Та ну, ти ж знаєш англійську 😉</span>)}
                    </span>
                </div>
                <div className="vacancy-list">
                    {vacancyCards.map((vacancy: IVacancyCard) => (
                        <VacancyCard key={vacancy.vacancyId} {...vacancy} />
                    ))}
                </div>
            </div>
        </div>
    </HelmetProvider>
  );
};

export default Careers;
