import { scroller } from "react-scroll";

import NavList from "../NavList";
import LangSelector from "../LangSelector";
import { ReactComponent as ArrowUpSVG } from "../../images/icons/arrowUp.svg";
import { ReactComponent as ArrowUpBgSVG } from "../../images/icons/arrowUpBg.svg";
import { ReactComponent as LinkedinSVG } from "../../images/icons/linkedin-logo-icon.svg";


import "./Footer.css";
import { useTranslation } from "react-i18next";


export const scrollToTop = () => {
  scroller.scrollTo("root", {
    duration: 1500,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <ArrowUpBgSVG className="arrow-up-background" />
      <ArrowUpSVG className="arrow-up" onClick={scrollToTop} />
      <div className="container">
        <div className="footer-container">
          <div className="footer-navigation-container footer-flex">
            <h2 className="footer-title">{t('talk')}</h2>
            <NavList location="footer" theme="dark" />
          </div>
          <div className="write-us-container footer-flex">
            <div className="social-media-container footer-flex">
              <p className="social-media-title">{t('onTheWeb')}</p>
              <a href="https://www.linkedin.com/company/wordlords/" target="_blank" rel="noreferrer" className="social-link" >
                <LinkedinSVG width="20px" height="20px" className="social-icon" />
              </a>
            </div>
          </div>
          <div className="copyright-container footer-flex">
            <p className="copyright">{t('copyright')}</p>
            <LangSelector theme="dark" location="footer"  />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
