import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { toast } from 'react-toastify';
import { useValidateContactUs } from "./ContactUsValidate";
import { ReactComponent as ClosePopUpSVG } from "../../images/icons/mobileMenuCloseIcon.svg";
import PrimaryButton from "../PrimaryButton";
import { NoScrollToggle } from "../../hooks/NoScroll";
import CustomInput from "../CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { HubSpotApiService } from "../../services/hubspot/hubspotService";
import { ContactType, IContact } from "../../services/hubspot/contact";

interface ContactUsPopUpData {
    setIsPopUpOpen: Dispatch<SetStateAction<boolean>>;
    contactType: string;
}

export interface InputData {
  fName: string;
  lName: string;
  email: string;
  message: string;
}

export const toggleModal = (changeState: React.Dispatch<React.SetStateAction<boolean>>, state: boolean) => {
  changeState(state);
  NoScrollToggle();
};

const ContactUsPopUp = ({ setIsPopUpOpen, contactType }: ContactUsPopUpData): JSX.Element => {
  const { t } = useTranslation();
  const validate = useValidateContactUs();


  const initialInputData: InputData = {
    fName: "",
    lName: "",
    email: "",
    message: "",
  };

  const [inputData, setInputData] = useState(initialInputData);

  const [errors, setErrors] = useState({
    fName: "",
    lName: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: validate(name, value),
    });
  };

  const Submit = () => {
    let validationErrors: any = {};
    for (const [key, value] of Object.entries(inputData)) {
      const error = validate(key, value);
      if (error) {
        validationErrors[key] = error;
      }
      setErrors(validationErrors);
    }
    if (Object.keys(validationErrors).length) return;
    
    const hubSpotService = new HubSpotApiService();
    const client: IContact = {
        clientType: contactType,
        firstName: inputData.fName,
        lastName: inputData.lName,
        email: inputData.email,
        message: inputData.message
    };

    toast.promise(
      hubSpotService.createContact(client),
      {
        pending: t("sending") as string,
        success: t("successContactMessage") as string,
        error: t("errorContactMessage") as string,
      }
    );
    
    toggleModal(setIsPopUpOpen, false);
    setInputData(initialInputData);
  };

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsPopUpOpen(false);
    NoScrollToggle();
  });

  const getTitle = (contactType: string) => {
    switch(contactType){
      case ContactType.lead:
        return t("contactPopupTitleLead");
      case ContactType.partnership:
        return t("contactPopupTitlePartner");
      case ContactType.press:
        return t("contactPopupTitlePress");
      case ContactType.other:
        return t("contactPopupTitleOther");
      default:
        return "";
    }
  }

  return (
    <div className="pop-up-blur">
      <div className="book-a-demo-wrapper" ref={ref}>
        <ClosePopUpSVG
          onClick={() => {
            toggleModal(setIsPopUpOpen, false);
          }}
          className="pop-up-close-icon"
        />
        <h2 className="pop-up-title">{getTitle(contactType)}</h2>
        <form className="pop-up-form">
          <CustomInput
            errors={errors.fName}
            label={t('name')}
            inputName="fName"
            value={inputData.fName}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('name placeholder')}
            type="text"
          />
          <CustomInput
            errors={errors.lName}
            label={t('last name')}
            inputName="lName"
            value={inputData.lName}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('surname placeholder')}
            type="text"
          />
          <CustomInput
            errors={errors.email}
            label={t('email')}
            inputName="email"
            value={inputData.email}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('email placeholder')}
            type="email"
          />
          <CustomInput
            errors={errors.message}
            label={t('message')}
            inputName="message"
            value={inputData.message}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('message palceholder')}
            type="textarea"
          />
        </form>
        <PrimaryButton onClick={() => Submit()} buttonName={t('Send')} />
      </div>
    </div>
  );
};

export default ContactUsPopUp;
