import CareersArray from "./CareersArray";
import { IVacancy } from "./IVacancy";
import { IVacancyCard } from "./IVacancyCard";

export class CareersService {
  async getVacancyCards(): Promise<IVacancyCard[]> {
    try {
      return CareersArray as IVacancyCard[]
    } catch (e: any) {
      e.message === 'HTTP request failed'
        ? console.error(JSON.stringify(e.response, null, 2))
        : console.error(e);

      throw e;
    }
  }

  async getVacancy(vacancyId: string): Promise<IVacancy> {
    try {
      return CareersArray.filter(vacancy => vacancy.vacancyId === vacancyId)[0];
    } catch (e: any) {
      e.message === 'HTTP request failed'
        ? console.error(JSON.stringify(e.response, null, 2))
        : console.error(e);

      throw e;
    }
  }
}
