import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    scroller.scrollTo("header", {
      duration: 1500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }, [pathname]);
  return null;
}
