import React, { useContext, useState } from "react";
import i18n from "../../i18n";
import { LabelContext } from "../../pages/InstantQuotePage/labelDataContext";
import { checkTheme } from "../NavList";

import "./LangSelector.css";
import { useNavigate } from "react-router-dom";

interface Lang {
  id: number;
  language: string;
}

interface ILangSelector {
  location?: string;
  theme: string;
}

const data: Lang[] = [
  { id: 0, language: "EN" },
  { id: 1, language: "UA" },
];

const LangSelector = ({ location, theme }: ILangSelector) => {
  const value = useContext(LabelContext);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleItemClick = (lang: string) => {
    const { pathname } = window.location;
    value.changeWebsiteLang!(lang);
    i18n.changeLanguage(lang.toLowerCase());
    value.changeWebsiteLang!(lang.toLowerCase())

    let newPathname;

    if (lang.toLowerCase() === "ua") {
      newPathname = pathname.startsWith("/ua/") ? pathname : `/ua${pathname}`;
    } else {
      newPathname = pathname.startsWith("/ua/") ? pathname.replace("/ua", "") : pathname;
    }
    navigate(newPathname);

    setOpen(false);
  };

  const withoutSelected = data.filter((item) => item.language !== value.websiteLang);

  return (
    <>
      <div className="dropdown">
        <div className="dropdown-header noselect ">
          {data.find((item) => item.language === value.websiteLang)?.language}
          <svg
            className={`icon ${isOpen && "open"}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6673 5.6665L8.00065 10.3332L3.33398 5.6665"
              stroke="#71778A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={`dropdown-body ${location === "footer" ? "lang-footer" : ""}`}>
          {withoutSelected.map((item) => (
            <div
              key={item.language as React.Key}
              className={`dropdown-item noselect ${checkTheme(location!, theme)}`}
              onClick={() => {
                handleItemClick(item.language);
              }}
            >
              {item.language}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LangSelector;
