import { ITeamMember } from "../../pages/OurTeam/OurTeamArray";
import "./TeamMemberCard.css";
import { ReactComponent as LinkedinSVG } from "../../images/icons/linkedin-logo-icon.svg";
import { ReactComponent as EmailSVG } from "../../images/icons/emailScuare.svg";
import { useTranslation } from "react-i18next";

interface ITeamMemberCard {
  teamMember: ITeamMember,
}

const TeamMemberCard = ({ teamMember }: ITeamMemberCard) => {
  const { i18n } = useTranslation();

  const getLanguageSegment = (): string => {
    return i18n.language === 'en' ? '' : '/ua' 
  }

  return (
    <div className="team-member-container">
        <a href={`${getLanguageSegment()}/about-us/our-team/${teamMember.teamMemberId}`}><img src={teamMember.image} alt={`${teamMember.title}'s`} className="team-member-image" /></a>
        <h2 className="team-member-name">{`${teamMember.firstName} ${teamMember.lastName}`}</h2>
        <h5 className="team-member-title">{teamMember.title}</h5>
        <div className="team-member-social-links-container">
          <a href={teamMember.linkedinLink} target="_blank" rel="noreferrer" className="team-member-social-link" >
              <LinkedinSVG width="30px" height="30px" className="social-icon" />
          </a>
          <a href={`mailto:${teamMember.email}`} target="_blank" rel="noreferrer" className="team-member-social-link" >
              <EmailSVG width="30px" height="30px" className="social-icon" />
          </a>
        </div>
    </div>
  );
};

export default TeamMemberCard;
