import { useCallback, useState } from "react";
import Slider from "react-slick";

import BlogPostsArray from "../../../components/BlogCard/BlogPostsArray";
import BlogCard from "../../../components/BlogCard";

import "./LatestBlogPosts.css";
import { useTranslation } from "react-i18next";

const LatestBlogPosts = () => {
  const { t } = useTranslation();
  const [, setDragging] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
    ],
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  return (
    <div className="latest-blog-posts">
      <div className="container">
        <p className="latest-blog-posts-subtitle">{t('translate better')}</p>
        <h2 className="latest-blog-posts-title title-mobile-size">{t('latest blogs')}</h2>
        <div className="blog-cards-container">
          <Slider beforeChange={handleBeforeChange} afterChange={handleAfterChange} {...settings}>
            {BlogPostsArray.sort((a, b) => 
              new Date(b.blogMetaData.date).getTime() - new Date(a.blogMetaData.date).getTime()
            ).map((item) => (
              <BlogCard key={item.blogId} item={item} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default LatestBlogPosts;
