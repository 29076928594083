import { Dispatch, SetStateAction } from "react";
import { ReactComponent as CloseLangMenuSVG } from "../../images/icons/mobileLangCloseArrow.svg";
import "./MobileOurServices.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { INamePathPair } from "../NavList/NavigationLists";

interface IMobileMenu {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  navList: INamePathPair[];
}
const MobileDropDown = ({ setIsOpen, setMenuOpen, navList }: IMobileMenu): JSX.Element => {
  const { t } = useTranslation();
  const handleClick = () => {
    setMenuOpen(false);
  }
  return (
    <div className="mobile-lang-wrapper">
      <div className="close-lang-container">
        <CloseLangMenuSVG onClick={() => setIsOpen(false)} />
      </div>
      <div className="mobile-lang-container">
        {navList.map((item, i) => (
          <NavLink key={i} to={item.path} className="lang-items" onClick={handleClick}>
            <p className="lang-item">{t(item.name)}</p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default MobileDropDown;
