export interface IContact {
    email?: string;
    firstName?: string;
    lastName?: string;
    clientType?: string;
    country?: string;
    phoneNumber?: string;
    website?: string;
    message?: string;
}

export enum ContactType {
    partnership = "408041155",
    other = "other",
    press = "408027067",
    lead = "lead"
}

export enum ContactProperties {
    email = "email",
    lastName = "lastname",
    firstName = "firstname",
    type = "lifecyclestage",
    country = "country",
}