import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useClickAway } from "react-use";
import DatePicker from "react-datepicker";

import { useValidateBookADemo } from "./BookADemoValidate";
import { ReactComponent as ClosePopUpSVG } from "../../images/icons/mobileMenuCloseIcon.svg";
import PrimaryButton from "../PrimaryButton";
import { NoScrollToggle } from "../../hooks/NoScroll";
import CustomInput from "../CustomInput";

import "./BookADemoPopUp.css";
import "react-datepicker/dist/react-datepicker.css";
import { sendBookADemo } from "../../hooks/sendBookADemo";
import { useTranslation } from "react-i18next";

interface BookADemoInterface {
  setIsPopUpOpen: Dispatch<SetStateAction<boolean>>;
}

export interface InputData {
  fName: string;
  lName: string;
  email: string;
  requestedDate?: Date | null;
}

export const toggleModal = (changeState: React.Dispatch<React.SetStateAction<boolean>>, state: boolean) => {
  changeState(state);
  NoScrollToggle();
};

const BookADemo = ({ setIsPopUpOpen }: BookADemoInterface): JSX.Element => {
  const { t } = useTranslation();
  const validate = useValidateBookADemo();


  const initialInputData: InputData = {
    fName: "",
    lName: "",
    email: "",
    requestedDate: null,
  };

  const [inputData, setInputData] = useState(initialInputData);

  const [errors, setErrors] = useState({
    fName: "",
    lName: "",
    email: "",
    requestedDate: "",
  });

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: validate(name, value),
    });
  };

  const handleDateChange = (date: Date) => {
    setInputData({
      ...inputData,
      requestedDate: date,
    });
    setErrors({
      ...errors,
      requestedDate: validate("requestedDate", date.toString()),
    });
  };

  const Submit = () => {
    let validationErrors: any = {};
    for (const [key, value] of Object.entries(inputData)) {
      const error = validate(key, value);
      if (error) {
        validationErrors[key] = error;
      }
      setErrors(validationErrors);
    }
    if (Object.keys(validationErrors).length) return;
    sendBookADemo(inputData);
    toggleModal(setIsPopUpOpen, false);
    setInputData(initialInputData);
  };

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsPopUpOpen(false);
    NoScrollToggle();
  });

  return (
    <div className="pop-up-blur">
      <div className="book-a-demo-wrapper" ref={ref}>
        <ClosePopUpSVG
          onClick={() => {
            toggleModal(setIsPopUpOpen, false);
          }}
          className="pop-up-close-icon"
        />
        <h2 className="pop-up-title">{t('book demo')}</h2>
        <form className="pop-up-form">
          <CustomInput
            errors={errors.fName}
            label={t('name')}
            inputName="fName"
            value={inputData.fName}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('name placeholder')}
            type="text"
          />
          <CustomInput
            errors={errors.lName}
            label={t('last name')}
            inputName="lName"
            value={inputData.lName}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('surname placeholder')}
            type="text"
          />
          <CustomInput
            errors={errors.email}
            label={t('email')}
            inputName="email"
            value={inputData.email}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t('email placeholder')}
            type="email"
          />
          <div className="custom-input">
            <label className={`date-label ${errors.requestedDate && "label-error"}`} htmlFor="">
            {t('date')}
            </label>
            <DatePicker
              className={`date-input ${errors.requestedDate && "input-error"}`}
              placeholderText={t('year placeholder') as string}
              selected={inputData.requestedDate}
              dateFormat="dd/MM/yyyy"
              popperPlacement="top"
              onChange={(date: Date) => handleDateChange(date)}
            />
            {errors.requestedDate && <div className="error-message">{errors.requestedDate}</div>}
          </div>
        </form>
        <PrimaryButton onClick={() => Submit()} buttonName={t('book demo')} />
      </div>
    </div>
  );
};

export default BookADemo;
