import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BlogCard from "../../components/BlogCard";
import BlogPostsArray from "../../components/BlogCard/BlogPostsArray";

import "./Blog.css";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const [, setDragging] = useState(false);
   
  const pathname = window.location.pathname;

  const { id } = useParams();
  const currentBlog = BlogPostsArray.find((item) => item.blogId === id);
  const withoutCurrentBlog = BlogPostsArray.filter((item) => item.blogId !== currentBlog?.blogId);
  const { i18n } = useTranslation();

  const getLanguageSegment = (): string => {
    return i18n.language === 'en' ? '' : '/ua';
  }

  interface Settings {
    dots: boolean;
    infinite: boolean;
    speed: number;
    responsive: {
      breakpoint: number;
      settings: any;
    }[];
  }

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
          dotsClass: "custom-dots",
        },
      },
    ],
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const helmetCanonicalhref = pathname === '/' ? `https://wordlords.co/` : `https://wordlords.co${pathname}`;
  const helmetBlogImage = `https://wordlords.co${currentBlog?.blogImages.blogMainImage}/`;

  return (
    <HelmetProvider>
      <div className="blog-wrapper">
        <Helmet>
        
          <title>{currentBlog?.blogMetaData.title}</title>
          <link rel="canonical" href={helmetCanonicalhref} />
          <meta name="description" content={currentBlog?.blogMetaData.description}/>
          
          <meta name="date" content={currentBlog?.blogMetaData.date} />
          <meta name="DC.date.issued" content={currentBlog?.blogMetaData.date} />
          <meta property="og:article:published_time" content={currentBlog?.blogMetaData.date} />

          <meta property="og:title" content={currentBlog?.blogMetaData.title} />
          <meta property="og:description" content={currentBlog?.blogMetaData.description} />
          <meta property="og:type" content="article:blog" />
          <meta property="og:url" content={helmetCanonicalhref} />
          <meta property="og:image" content={helmetBlogImage} />
          <meta property="og:site_name" content="Wordlords" />
          <meta property="og:locale" content="en_US" />

          <meta name="twitter:title" content={currentBlog?.blogMetaData.title} />
          <meta name="twitter:description" content={currentBlog?.blogMetaData.description} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content="@zafiovskiy" />
          <meta name="twitter:image" content={helmetBlogImage} />
          <meta name="twitter:url" content={helmetCanonicalhref} />

          <meta name="robots" content="index, follow"/>

        </Helmet>
        <div className="container">
          <div className="blog-container">
            <div className="blog-title-container">
              <h4 className="blog-subtitle">{currentBlog?.blogText.subtitle}</h4>
              <h1 className="blog-title title">{currentBlog?.blogText.title}</h1>
            </div>
            <div className="blog-content-container">
              <div className="blog-current-container">
                <a href={`${getLanguageSegment()}/about-us/our-team/${currentBlog?.blogAuthor?.authorId}`} className="blog-author-container">
                  <img src={currentBlog?.blogImages.blogAuthorImage} height={80} width={80} alt="author" className="author-image" />
                  <p className="author-name">{currentBlog?.blogAuthor?.authorName}</p>
                  <p className="blog-date">{currentBlog?.blogAuthor?.blogDate}</p>
                </a>
                <img src={currentBlog?.blogImages.blogMainImage} alt="blog" className="blog-image" />
                <article className="blog-text">
                  {currentBlog?.blogText.content.map((item, index) => (
                    <div className="blog-inner-text-container" key={index}>
                      <div className="blog-inner-subtitle">{item.subtitle}</div>
                      <br />
                      {item.text.map((paragraph, pIndex) => (
                        <p key={pIndex} className="blog-inner-paragraph" dangerouslySetInnerHTML={{ __html: (paragraph.replace(/\[([^:]+):([^\]]+)\]/g, '<a class="link-animated" href="$2" target="_blank" >$1</a>')) }}></p>
                      ))}
                      {
                        item.image && <img src={item.image} alt={`${item.image}`} className="blog-inner-image"/>
                      }
                    </div>
                  ))}
                </article>
              </div>
              <div className="more-blogs-container">
                <p className="more-articles">More articles</p>
                <div className="blogs-scroll-container">
                  <Slider beforeChange={handleBeforeChange} afterChange={handleAfterChange} {...settings}>
                    {withoutCurrentBlog.sort((a, b) => 
                      new Date(b.blogMetaData.date).getTime() - new Date(a.blogMetaData.date).getTime()
                    ).map((item) => (
                      <BlogCard key={item.blogId} item={item} />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Blog;
