import WW2InColorImage from '../../images/WW2InColor.webp';
import TheCrownImage from '../../images/TheCrown.webp';
import TheMindExplainedImage from '../../images/TheMindExplained.webp';
import FungyImage from '../../images/Fungy.webp';
import WesternFrontImage from '../../images/WesternFront.webp';
import BatmanGamingImage from '../../images/BatmanGaming.webp';
import JorneyToTheSavagePlanetImage from '../../images/JorneyToTheSavagePlanet.webp';
import GameStopSagaImage from '../../images/GameStopSaga.webp';
import AridnykImage from '../../images/Aridnyk.webp';

import Case from './Case';
import { CaseType } from './CaseType';

const cases: Case[] = [
    {
        id: 'netflix-subtitle-translation-wwii-in-color',
        title: 'WWII in Color',
        description: 'Netflix Subtitle Translation',
        fullDescription: `
            <p>We provided accurate and culturally relevant subtitle translations for the Netflix series <strong>WWII in Color</strong>, ensuring a seamless viewing experience for Ukrainian audiences.</p>
            <p>This project involved a meticulous process of translating historical terminologies and ensuring that the essence and tone of the documentary were preserved. Our team worked closely with history experts to deliver subtitles that not only translated the dialogue but also respected the historical context.</p>
            <p>The result was a set of subtitles that enabled viewers to fully engage with the documentary, enhancing their understanding and appreciation of World War II events as depicted in the series.</p>
        `,
        imageUrl: WW2InColorImage,
        stats: {
            numberOfLangPairs: 5,
            wordsTranslated: 50000,
        },
        type: CaseType.Notable
    },
    {
        id: 'netflix-subtitle-translation-the-crown',
        title: 'The Crown',
        description: 'Netflix Subtitle Translation',
        fullDescription: `
            <p>Our team translated subtitles for the acclaimed Netflix series <strong>The Crown</strong>, capturing the historical and cultural nuances to provide an immersive viewing experience for Ukrainian audiences.</p>
            <p>The Crown's complex narrative and rich dialogue required careful handling to ensure that subtleties and nuances were preserved. We paid particular attention to historical references and royal protocols, consulting with cultural experts to ensure accuracy.</p>
            <p>This translation project not only helped Ukrainian viewers enjoy the series in their native language but also deepened their understanding of British history and the intricacies of the royal family.</p>
        `,
        imageUrl: TheCrownImage,
        stats: {
            numberOfLangPairs: 3,
            wordsTranslated: 35000,
        },
        type: CaseType.Notable
    },
    {
        id: 'netflix-subtitle-translation-the-mind-explained',
        title: 'The Mind, Explained',
        description: 'Netflix Subtitle Translation',
        fullDescription: `
            <p>We translated subtitles for <strong>The Mind, Explained</strong>, making complex scientific concepts accessible and engaging for Ukrainian viewers.</p>
            <p>This series required translating scientific terminology and concepts in a way that was both accurate and understandable for the general public. Our translators worked closely with scientific consultants to ensure that the subtitles were not only precise but also easy to follow.</p>
            <p>The project aimed to make the fascinating world of neuroscience and psychology available to a broader audience, helping viewers gain insights into the workings of the human mind.</p>
        `,
        imageUrl: TheMindExplainedImage,
        stats: {
            numberOfLangPairs: 4,
            wordsTranslated: 40000,
        },
        type: CaseType.Notable
    },
    {
        id: 'netflix-subtitle-translation-fantastic-fungi',
        title: 'Fantastic Fungi',
        description: 'Netflix Subtitle Translation',
        fullDescription: `
            <p>Our translation for <strong>Fantastic Fungi</strong> ensured that the documentary’s captivating exploration of fungi was fully understood and appreciated by Ukrainian audiences.</p>
            <p>This project involved translating scientific content and ensuring that the poetic and philosophical aspects of the documentary were conveyed effectively. We aimed to maintain the documentary's enchanting narrative while making the scientific information accessible to all viewers.</p>
            <p>The translation helped bring the wonders of the fungal kingdom to life for Ukrainian viewers, enriching their knowledge and appreciation of these amazing organisms.</p>
        `,
        imageUrl: FungyImage,
        stats: {
            numberOfLangPairs: 2,
            wordsTranslated: 20000,
        },
        type: CaseType.Notable
    },
    {
        id: 'netflix-subtitle-translation-all-quiet-on-the-western-front',
        title: 'All Quiet on the Western Front',
        description: 'Netflix Subtitle Translation',
        fullDescription: `
            <p>We provided subtitle translations for <strong>All Quiet on the Western Front</strong>, maintaining the film’s powerful and poignant messages for Ukrainian viewers.</p>
            <p>This translation project required a deep understanding of war terminologies and the emotional gravity of the film. Our translators ensured that the intense emotions and historical accuracy were preserved in the Ukrainian subtitles.</p>
            <p>The end result was a set of subtitles that conveyed the profound impact of the film, allowing Ukrainian audiences to fully experience its moving narrative.</p>
        `,
        imageUrl: WesternFrontImage,
        stats: {
            numberOfLangPairs: 3,
            wordsTranslated: 30000,
        },
        type: CaseType.Notable
    },
    {
        id: 'netflix-subtitle-translation-eat-the-rich-the-gamestop-saga',
        title: 'Eat the Rich: The GameStop Saga',
        description: 'Netflix Subtitle Translation',
        fullDescription: `
            <p>Our team translated subtitles for <strong>Eat the Rich: The GameStop Saga</strong>, bringing the riveting financial drama to Ukrainian audiences with clarity and context.</p>
            <p>This project involved translating complex financial terminology and ensuring that the subtitles were both accurate and engaging. We aimed to make the financial aspects of the story accessible to viewers without diluting the drama and excitement of the narrative.</p>
            <p>The translation enabled Ukrainian viewers to fully grasp the intricacies of the GameStop saga, making the documentary both informative and entertaining.</p>
        `,
        imageUrl: GameStopSagaImage,
        stats: {
            numberOfLangPairs: 1,
            wordsTranslated: 10000,
        },
        type: CaseType.Notable
    },
    {
        id: 'game-localization-batman-arkham-city',
        title: 'Batman: Arkham City',
        description: 'Game Localization',
        fullDescription: `
            <p>Our team localized <strong>Batman: Arkham City</strong> from English to Ukrainian, focusing on preserving the original tone and context of the game dialogues and instructions.</p>
            <p>This project involved translating complex dialogues and ensuring that the game's dark and gritty atmosphere was maintained. Our translators worked closely with game developers to ensure that the localization did not affect gameplay or the immersive experience.</p>
            <p>The localized game allowed Ukrainian players to fully immerse themselves in the world of Batman, enhancing their gaming experience.</p>
        `,
        imageUrl: BatmanGamingImage,
        stats: {
            numberOfLangPairs: 6,
            wordsTranslated: 60000,
        },
        type: CaseType.Notable
    },
    {
        id: 'game-localization-journey-to-the-savage-planet',
        title: 'Journey to the Savage Planet',
        description: 'Game Localization',
        fullDescription: `
            <p>We localized <strong>Journey to the Savage Planet</strong>, ensuring the game’s humor and narrative were effectively translated for Ukrainian players.</p>
            <p>This project required translating humorous content and ensuring that the game's lighthearted tone was preserved. Our translators focused on cultural adaptation to ensure that jokes and references were understandable and funny to Ukrainian players.</p>
            <p>The localization made the game more enjoyable and accessible to Ukrainian gamers, helping them fully engage with its quirky and adventurous world.</p>
        `,
        imageUrl: JorneyToTheSavagePlanetImage,
        stats: {
            numberOfLangPairs: 2,
            wordsTranslated: 20000,
        },
        type: CaseType.Other
    },
    {
        id: 'tabletop-game-localization-aridnyk',
        title: 'Aridnyk',
        description: 'Tabletop Game Localization',
        fullDescription: `
        <style>
        .highlight {
            font-weight: bold;
            color: #2a9d8f; /* Inspired by the green hues in the image */
        }
        .emphasis {
            font-style: italic;
            color: #e76f51; /* Inspired by the red hues in the image */
        }
        .description-paragraph {
            margin-bottom: 1em;
            color: #264653; /* Inspired by the dark green/blue hues in the image */
        }
    </style>
    <p class="description-paragraph">We undertook the challenging task of localizing the tabletop game <span class="highlight">Aridnyk</span>, a game steeped in the rich indigenous culture of the Hutsuls from the Carpathian region of Ukraine.</p>
    <p class="description-paragraph">This project was particularly challenging due to the strict deadlines and the unique localization and LQA (Language Quality Assurance) requirements of a tabletop game. It was essential to ensure that the translated text fit perfectly within the game’s design constraints.</p>
    <p class="description-paragraph">Cultural adaptation was another significant challenge. <span class="highlight">Aridnyk</span> explores the traditions and folklore of the Hutsul people, who are known for their vibrant costumes, intricate woodwork, and deep connection to nature. It was crucial to preserve the cultural essence and vibe of the game. Our team worked diligently to maintain the integrity and authenticity of the original content.</p>
    <p class="description-paragraph"><span class="emphasis">The Hutsuls</span> are an ethnic group from the Carpathian Mountains, renowned for their distinct customs and folklore. Their traditions are characterized by colorful festivals, music, and dance, reflecting their harmonious relationship with the mountainous environment. Translating these cultural nuances required a deep understanding of their way of life and beliefs.</p>
    <p class="description-paragraph">The successful localization of <span class="highlight">Aridnyk</span> allowed Ukrainian players to fully immerse themselves in this culturally rich and engaging game, enjoying both its educational and entertainment value. Players can now explore the mystical world of the Hutsuls, learning about their history, legends, and the mystical creatures of their folklore.</p>
    <p class="description-paragraph">This project not only brought the game to a new audience but also highlighted the importance of cultural preservation through gaming. The meticulous localization process ensured that every element, from the game's narrative to its visual design, was adapted with cultural sensitivity and precision.</p>
    <p class="description-paragraph">For more information, you can visit the official product page <a href="https://boardova.com/solovyina/our-products/ARIDNYK.THEBOARDGAME" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">here</a>.</p>
    `,


        imageUrl: AridnykImage,
        stats: {
            numberOfLangPairs: 3,
            wordsTranslated: 25000,
        },
        type: CaseType.Other
    }
];

export { cases };
