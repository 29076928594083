
export interface INamePathPair {
    name: string;
    path: string;
}

export const servicesNavList: INamePathPair[] = [
    { name: "games", path: "/our-services/games-localization/" },
    { name: "subtitle", path: "/our-services/subtitle-translation/" },
    { name: "document", path: "/our-services/document-translation/" },
    { name: "roi-calculator", path: "/roi-calculator/"}
];

export const mainNavlist: INamePathPair[] = [
    { name: "home", path: "/" },
    { name: "about", path: "/about-us" },
    { name: "our services", path: "/our-services" },
    { name: "contact us", path: "/contact-us/"},
    { name: "careers", path: "/careers/"}
];

export const aboutUsNavList: INamePathPair[] = [
    { name: "ourteam", path: "/about-us/our-team/" },
    { name: "ourcases", path: "/about-us/our-cases/" }
];