import { Dispatch, SetStateAction, useContext } from "react";
import i18n from "../../i18n";

import { ReactComponent as CloseLangMenuSVG } from "../../images/icons/mobileLangCloseArrow.svg";
import { ReactComponent as SelectedIcon } from "../../images/icons/mobileLangSelectedIcon1.svg";
import { LabelContext } from "../../pages/InstantQuotePage/labelDataContext";
import { DataLang } from "../MobileMenu";

import "./MobileLangSelector.css";
import { useNavigate } from "react-router-dom";

interface LangMenu {
  setLangOpen: Dispatch<SetStateAction<boolean>>;
  selectedItem: string;
  data: DataLang[];
}

const MobileLangSelector = ({ setLangOpen, selectedItem, data }: LangMenu): JSX.Element => {
  const value = useContext(LabelContext);
  const navigate = useNavigate();

  const handleSelectLang = (lang: string) => {
    const { pathname } = window.location;
    value.changeWebsiteLang!(lang);
    i18n.changeLanguage(lang.toLowerCase());
    value.changeWebsiteLang!(lang.toLowerCase())

    let newPathname;

    if (lang.toLowerCase() === "ua") {
      newPathname = pathname.startsWith("/ua/") ? pathname : `/ua${pathname}`;
    } else {
      newPathname = pathname.startsWith("/ua/") ? pathname.replace("/ua", "") : pathname;
    }
    navigate(newPathname);
    setLangOpen(false);
  };
  
  return (
    <div className="mobile-lang-wrapper">
      <div className="close-lang-container">
        <CloseLangMenuSVG onClick={() => setLangOpen(false)} />
      </div>
      <div className="mobile-lang-container">
        {data.map((item, i) => (
          <div
            key={i}
            className="lang-items"
            onClick={() => {
              handleSelectLang(item.languageCode);
            }}
          >
            <p className="lang-item">{item.language}</p>
            {item.languageCode === value.websiteLang ? <SelectedIcon className="selected-lang-icon" /> : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileLangSelector;
