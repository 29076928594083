import { Dispatch, SetStateAction, useContext, useState } from "react";

import { ReactComponent as LogoIconSVG } from "../../images/icons/logo.svg";
import NavList from "../NavList";
import MobileLangSelector from "../MobileLangSelector";
import { ReactComponent as CloseMenuSVG } from "../../images/icons/mobileMenuCloseIcon.svg";
import { ReactComponent as LanguageArrow } from "../../images/icons/languageDownArrow.svg";

import "./MobileMenu.css";
import { toggleModal } from "../BookADemoPopUp";
import { LabelContext } from "../../pages/InstantQuotePage/labelDataContext";
import MobileDropDown from "../MobileDropDown";
import { aboutUsNavList, servicesNavList } from "../NavList/NavigationLists";

interface MobileMenuInterface {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface DataLang {
  id: number;
  language: string;
  languageCode: string;
}

const data: DataLang[] = [
  { id: 0, language: "English", languageCode: "EN" },
  { id: 1, language: "Ukrainian", languageCode: "UA" },
];

const MobileMenu = ({ isOpen, setIsOpen }: MobileMenuInterface): JSX.Element => {
  const value = useContext(LabelContext);

  const [isLangOpen, SetLangOpen] = useState(false);
  const {isOurServicesOpen, SetOurServicesOpen, isAboutUsOpen, SetAboutUsOpen} = useContext(LabelContext);

  return (
    <div className="mobile-menu-wrapper">
      <div className="close-menu-container">
        <CloseMenuSVG
        className="close-menu-svg"
          onClick={() => {
            toggleModal(setIsOpen, !isOpen);
          }}
        />
      </div>
      <div className="mobile-menu-container">
        <div className="mobile-menu-logo-container">
          <LogoIconSVG className="header-logo" title="WordLords logo" />
          <h2 className="logo-text">WordLords</h2>
        </div>
        <NavList setIsOpen={setIsOpen} setServicesMobile={SetOurServicesOpen} setAboutUsMobile={SetAboutUsOpen} theme="dark" />
        <div onClick={() => SetLangOpen(!isLangOpen)} className="mobile-language-selector noselect">
          {data.find((item) => item.languageCode === value.websiteLang)?.languageCode}
          <LanguageArrow className="lang-arrow" />
        </div>
        {isOurServicesOpen && <MobileDropDown setMenuOpen={setIsOpen} isOpen={isOurServicesOpen} setIsOpen={SetOurServicesOpen!} navList={servicesNavList} />}
        {isAboutUsOpen && <MobileDropDown setMenuOpen={setIsOpen} isOpen={isAboutUsOpen} setIsOpen={SetAboutUsOpen!} navList={aboutUsNavList} />}
        {isLangOpen && <MobileLangSelector setLangOpen={SetLangOpen} selectedItem={value.websiteLang!} data={data} />}
      </div>
    </div>
  );
};

export default MobileMenu;
