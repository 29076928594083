import { useEffect, useState } from "react";
import Slider from "react-slick";

import "../../pages/HomePage/LatestBlogPosts/LatestBlogPosts.css";
import "./BlogsSlider.css";
import { useTranslation } from "react-i18next";
import BlogPostsArray from "../BlogCard/BlogPostsArray";
import BlogCard from "../BlogCard";
import writingGif from "../../videos/writing-gif.mp4";

interface ILatestBlogPostsUI {
    authorId?: string;
}

const AuthorBlogPosts = ( { authorId } : ILatestBlogPostsUI ) => {
  const { t } = useTranslation();
  const [authorBlogs, setAuthorBlogs] = useState(BlogPostsArray);
  const settings = authorId === 'andrian-zafiiovskiy' ? {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
    ],
  } : {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          dotsClass: "custom-dots",
        },
      },
    ],
  };
  
  useEffect(() => {
    const authorBlogsTemp = BlogPostsArray.filter(blog => blog.blogAuthor?.authorId === authorId);
    setAuthorBlogs(authorBlogsTemp);
  }, [authorId]);

  return (
    <div className="author-latest-blog-posts">
        <div className="container">
            <h2 className="latest-blog-posts-title title-mobile-size">{t("authorBlogsTitle")}</h2>
            {authorBlogs.length > 0 ? (
            <div className="blog-cards-container">
                <Slider {...settings}>
                    {authorBlogs.sort((a, b) => 
                        new Date(b.blogMetaData.date).getTime() - new Date(a.blogMetaData.date).getTime()
                    ).map((item) => (
                        <BlogCard key={item.blogId} item={item} />
                    ))}
                </Slider>
            </div>
            ) : (
            <div className="loading-gif-container">
                <video className="writing-gif-vid" autoPlay loop muted playsInline>
                    <source src={writingGif} type="video/mp4" />
                    {t('videoBackgroundAlt')}
                </video>
            </div>
            )}
        </div>
    </div>
  );
};

export default AuthorBlogPosts;
