import CountUp from 'react-countup';
import "./NumberCounter.css";

interface INumberCounter {
    end: number;
    label: string;
    prefix: string;
} 

const NumberCounter = ({ end, label, prefix }: INumberCounter): JSX.Element => {
    
    return (
        <div className="number-counter">
            <CountUp className='number-counter-digits' prefix={prefix} end={end} duration={3} enableScrollSpy={true} scrollSpyDelay={1.5} scrollSpyOnce={true}/>
            <p className='number-counter-label'>{label}</p>        
        </div>
    );
};

export default NumberCounter;