import { useContext } from "react";
import { useTranslation } from "react-i18next";

import "react-toastify/dist/ReactToastify.css";

import CustomCheckbox from "../../../../components/CustomCheckbox";
import IconLeftButton from "../../../../components/IconLeftButton";
import PrimaryButton from "../../../../components/PrimaryButton";
import { sendInquiryToEmail } from "../../../../hooks/sendInquiryToEmail";
import { LabelContext } from "../../labelDataContext";
import { CheckIcon, stepperPageHandler } from "../ContentStep";

import "./SubmitStep.css";
import { useNavigate } from "react-router-dom";

const SubmitStep = () => {
  const value = useContext(LabelContext);
  const { t, i18n } = useTranslation();
  const currentRedirectValue = i18n.language === 'en' ? '/' : `/${i18n.language}`;
  const navigate = useNavigate();

  const files = value.labelInfo?.translate.file;
  const text = value.labelInfo?.translate.text;
  const note = value.labelInfo?.translate.note;

  const handleSubmit = () => {
    sendInquiryToEmail(value.labelInfo!);
    value.resetLabelInfo!();
    navigate(currentRedirectValue);
  };

  const TextOrFilesRender = () => {
    if (value.labelInfo?.translate.file.length! > 0) {
      return (
        <div className="source-files">
          <ul className="uploaded-list">
            {files!.map((file, idx) => (
              <div className="uploaded-item">
                <div className="file-details">
                  {CheckIcon(file)}
                  <li key={idx}>{file.name}</li>
                </div>
              </div>
            ))}
          </ul>
          <div className="fade"></div>
        </div>
      );
    } else {
      return (
        <div className="content-text-field">
          <textarea disabled className="text-input" value={text}>
            <div className="fade" />
          </textarea>
          <div className="fade" />
        </div>
      );
    }
  };

  return (
    <div className="submit-step-wrapper">
      <div className="submit-step-container">
        <div className="notes-container submit-step-flex">
          <h6>{t('notes translators')}</h6>
          <div className="content-text-field">
            <textarea
              className="text-input"
              placeholder={t('specifics project')!}
              value={note}
              onChange={value.setTranslateInfo!("note")}
            />
            <div className="fade"></div>
          </div>
        </div>
        <div className="counted-container submit-step-flex">
          <h6>{t('word count')}</h6>
          <div className="checkmark-container">
            <CustomCheckbox
              checked={value.labelInfo?.translate.couponCode!}
              label={t('coupon code')}
              tipText={t('instructions')}
              onChange={() => value.toggleCheckbox!("couponCode", value.labelInfo?.translate.couponCode!)}
            />
          </div>
        </div>
        <div className="source-container submit-step-flex">
          <h6>{t('source')}</h6>
          {TextOrFilesRender()}
        </div>
      </div>
      <div className="navigation-button-container">
        <IconLeftButton buttonName={t('previous')} onClick={() => stepperPageHandler!(value.prevPage!)} />
        <div className="btn-accept-container">
          <PrimaryButton buttonName={t('request quote')} onClick={() => handleSubmit()} />
        </div>
      </div>
    </div>
  );
};

export default SubmitStep;
