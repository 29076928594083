import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../components/PrimaryButton";
import NotFoundImage from "../../images/notFoundImage.png";

import "./NotFound.css";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentPathBase = i18n.language === 'en' ? '' : `/${i18n.language}`; 

  return (
    <div className="not-found-wrapper">
      <div className="container">
        <div className="not-found-container">
          <div className="not-found-text-container">
            <h1 className="not-found-title ">
             {t('notFoundTitle')}
            </h1>
            <p>{t('notFoundDescription')}</p>
            <PrimaryButton buttonName={t('notFoundButton')} onClick={() => navigate(`${currentPathBase}/`)} />
          </div>
          <img src={NotFoundImage} alt="Not found" className="not-found-image" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
