import { scroller } from "react-scroll";

import { ReactComponent as ArrowDownBgSVG } from "../../../images/icons/arrowDownBg.svg";
import { ReactComponent as ArrowDownSVG } from "../../../images/icons/arrowUp.svg";

import "./WhyChooseUs.css";
import NumberCounter from "../../../components/NumberCounter";
import { useTranslation } from "react-i18next";

export const scrollToWhyChooseUs = () => {
  scroller.scrollTo("why-choose-us-wraper", {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};

const WhyChooseUs = () => {
  const { t } = useTranslation();

  return (
    <div className="why-choose-us-wraper" id="about-us">
      <ArrowDownBgSVG className="arrow-down-background" />
      <ArrowDownSVG onClick={scrollToWhyChooseUs} className="arrow-down" />
      <div className="stats-container">
        <NumberCounter end={6} prefix="" label={t("experienceYearsLabel")}/>
        <NumberCounter end={120} prefix="≈" label={t("translatorsNumberLabel")}/>
        <NumberCounter end={1120} prefix="≈" label={t("progectsDeliveredLabel")}/>
        <NumberCounter end={700000} prefix="≈" label={t("subtitlesDeliveredLabel")}/>
        <NumberCounter end={2000000} prefix="≈" label={t("wordsDeliveredLabel")}/>
      </div>
        
    </div>
  );
};

export default WhyChooseUs;
