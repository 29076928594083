import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../../components/PrimaryButton";

import "./InstantQuote.css";

const InstantQuote = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentPathBase = i18n.language === 'en' ? '' : `/${i18n.language}`; 
  const routeChange = (path: string) => {
    navigate(path);
  };
  return (
    <div className="instant-quote-wrapper">
      <div className="container">
        <div className="instant-quote-container">
          <h2 className="instant-quote-title title-mobile-size">{t('getInstantQuote')}</h2>
          <h4 className="instant-quote-text">{t("easy way")}<br /> {t("buy online")} </h4>
          <PrimaryButton onClick={() => routeChange(`${currentPathBase}/instant-quote/`)} buttonName={t("get quote")} />
        </div>
      </div>
    </div>
  );
};

export default InstantQuote;
