import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Fade } from "react-awesome-reveal";
import { cases } from '../OurCases/CasesArray';
import { Link } from "react-router-dom";
import ContactUsBlock from "../../components/ContactUsBlock";
import { CaseType } from "./CaseType";

const OurCases = () => {
    const { t, i18n } = useTranslation();
    const language: string = i18n.language;
    const helmetOurCasesMetaDescription: string = t("helmetOurCasesMetaDescription");
    const helmetOurCasesKeywords: string = t("helmetOurCasesKeywords");
    const helmetOurCasesTitle: string = t("helmetOurCasesTitle");

    const notableCases = cases.filter(caseItem => caseItem.type === CaseType.Notable);
    const otherCases = cases.filter(caseItem => caseItem.type === CaseType.Other);

    // Structured data for SEO
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://wordlords.co/about-us/our-cases/"
        },
        "name": helmetOurCasesTitle,
        "description": helmetOurCasesMetaDescription,
        "url": "https://wordlords.co/about-us/our-cases/",
        "creator": {
            "@type": "Organization",
            "name": "Wordlords",
            "url": "https://wordlords.co",
            "logo": {
                "@type": "ImageObject",
                "url": "https://wordlords.co/logo.svg"
            }
        },
        "about": notableCases.concat(otherCases).map(caseItem => ({
            "@type": "CreativeWork",
            "name": caseItem.title,
            "url": `https://wordlords.co/about-us/our-cases/${caseItem.id}`,
            "image": caseItem.imageUrl,
            "description": caseItem.description
        }))
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{helmetOurCasesTitle}</title>
                <meta name="description" content={helmetOurCasesMetaDescription} />
                <meta name="keywords" content={helmetOurCasesKeywords} />
                <meta name="robots" content="index, follow" />
                <meta property="og:image" content="https://wordlords.co/logo.svg" />
                <meta property="og:title" content={helmetOurCasesTitle} />
                <meta property="og:description" content={helmetOurCasesMetaDescription} />
                {language === 'en' && (
                    <>
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:url" content="https://wordlords.co/about-us/our-cases/" />
                        <link rel="canonical" href="https://wordlords.co/about-us/our-cases/" />
                    </>
                )}
                {language === 'ua' && (
                    <>
                        <meta property="og:locale" content="uk_UA" />
                        <meta property="og:url" content="https://wordlords.co/ua/about-us/our-cases/" />
                        <link rel="canonical" href="https://wordlords.co/ua/about-us/our-cases/" />
                    </>
                )}
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <div className="bg-gray-100 min-h-screen">
                <div className="bg-black text-white py-16">
                    <div className="px-8 mx-auto text-center">
                        <Fade direction="down" triggerOnce={true} cascade damping={0.3} delay={200} duration={1500}>
                            <h1 className="text-5xl font-bold mb-4">{t("Our Business Cases")}</h1>
                            <p className="text-lg">{t("ourCasesDescription")}</p>
                        </Fade>
                    </div>
                </div>
                <div className="md:mx-44 py-8 px-8">
                    <h2 className="text-3xl font-bold mb-8">{t("Cases in detail:")}</h2>
                    <div className="flex flex-wrap justify-center -m-4">
                        {otherCases.map((caseItem) => (
                            <div key={caseItem.id} className="p-4 w-full sm:w-1/2 lg:w-1/3">
                                <Link to={`${language === 'en' ? '' : '/ua'}/about-us/our-cases/${caseItem.id}`}>
                                    <div className="relative bg-cover bg-center rounded-lg shadow-lg transition-transform transform hover:scale-95" style={{ backgroundImage: `url(${caseItem.imageUrl})`, height: '400px' }}>
                                        <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                                        <div className="absolute inset-0 flex flex-col justify-end p-4">
                                            <h2 className="text-xl font-semibold text-white">{caseItem.title}</h2>
                                            <p className="text-white">{caseItem.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                    {notableCases.length > 0 && (
                        <div className="mt-16">
                            <h2 className="text-3xl font-bold mb-8">{t("Other Notable Projects:")}</h2>
                            <div className="flex flex-wrap justify-center -m-2">
                                {notableCases.map((caseItem) => (
                                    <div key={caseItem.id} className="p-2 w-1/2 lg:w-1/6">
                                        <div className="relative bg-cover bg-center rounded-lg shadow-lg" style={{ backgroundImage: `url(${caseItem.imageUrl})`, height: '200px' }}>
                                            <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                                            <div className="absolute inset-0 flex flex-col justify-end p-2">
                                                <h2 className="text-sm font-semibold text-white">{caseItem.title}</h2>
                                                <p className="text-xs text-white">{caseItem.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <ContactUsBlock />
            </div>
        </HelmetProvider>
    );
};

export default OurCases;
