import { useLocation } from "react-router-dom";

import OurServicesPage from "./OurServicesPage";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./OurServices.css";
import { useTranslation } from "react-i18next";

import WebsiteTranslationSVG from "../../../images/icons/websiteTranslationIcon.svg";
import DocumentTranslationSVG from "../../../images/icons/documentTranslationIcon.svg";
import SubtitleTranslationSVG from "../../../images/icons/subtitleTranslationIcon.svg";
import WebsiteTranslationImage from "../../../images/websiteTranslation.png";
import SubtitleTranslationImage from "../../../images/subtitleTranslation.png";
import DocumentTranslationImage from "../../../images/websiteTranslation.png";

export interface OurServicesInterface {
  pageName?: any;
  mainImage?: any;
  subtitle: any;
  title: any;
  mainText: any;
  bgImage?: string;
  question: any;
  answers: { answer: any }[];
}

const OurServices = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const currentPathBase = i18n.language === 'en' ? '' : `/${i18n.language}`; 

  const GamesTranslation: OurServicesInterface = {
    pageName: t("games localization"),
    mainImage: WebsiteTranslationImage,
    subtitle: t("translate better"),
    title: t("games localization"),
    mainText: [t("games localization content")],
    bgImage: WebsiteTranslationSVG,
    question: t("games localization question"),
    answers: [
      {
        answer:
        t("games localization answer1")
      },
      {
        answer:
        t("games localization answer2")
      },
      {
        answer:
        t("games localization answer3")
      },
      {
        answer:
        t("games localization answer4")
      },
    ],
  };

  const DocumentTranslation: OurServicesInterface = {
    pageName: t("docs translation"),
    mainImage: DocumentTranslationImage,
    subtitle: t("translate better"),
    title: t("docs translation"),
    mainText: [t("docs translation content")],
    bgImage: DocumentTranslationSVG,
    question: t('docs translation question'),
    answers: [
      {
        answer: t("docs web"),
      },
      {
        answer: t("docs business"),
      },
      {
        answer: t("docs tech"),
      },
      {
        answer: t("docs contract"),
      },
      {
        answer: t("docs marketing"),
      },
      {
        answer: t("docs literature"),
      },
    ],
  };

  const SubtitleTranslation: OurServicesInterface = {
    pageName: t("subtitle translation"),
    mainImage: SubtitleTranslationImage,
    subtitle: t("translate better"),
    title: t("subtitle translation"),
    mainText: [t("subtitle translation content_1"), t("subtitle translation content_2")],
    bgImage: SubtitleTranslationSVG,
    question: t("subs title"),
    answers: [
      {
        answer: t("subs film"),
      },
      {
        answer: t("subs serial"),
      },
      {
        answer: t("subs commercial"),
      },
      {
        answer: t("subs trailer"),
      },
      {
        answer: t("subs doc"),
      },
      {
        answer: t("subs clip"),
      },
    ],
  };

  let ourServicesData = GamesTranslation;

  switch (pathname) {
    case `${currentPathBase}/our-services/games-localization/`:
      ourServicesData = GamesTranslation;
      break;
    case `${currentPathBase}/our-services/document-translation/`:
      ourServicesData = DocumentTranslation;
      break;
    case `${currentPathBase}/our-services/subtitle-translation/`:
      ourServicesData = SubtitleTranslation;
      break;
  }

  const helmetTitle = `${ourServicesData.pageName} | Wordlords`;
  const helmetDescription = ourServicesData.mainText[0].substring(0, 160);
  const helmetCanonicalhref = pathname === '/' ? `https://wordlords.co/` : `https://wordlords.co${pathname}`;
  const helmetImage = `https://wordlords.co${ourServicesData.mainImage}/`;
  return (
    <HelmetProvider>
      <div className="our-services-wrapper">
        <Helmet>
          <title>{helmetTitle}</title>
          <link rel="canonical" href={helmetCanonicalhref} />
          <meta name="description" content={helmetDescription}/>        
          
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:description" content={helmetDescription}/>   
          <meta property="og:url" content={helmetCanonicalhref} />
          <meta property="og:image" content={helmetImage} />
          <meta property="og:site_name" content="Wordlords" />
          <meta property="og:locale" content="en_US" />

          <meta name="twitter:title" content={helmetTitle} />
          <meta name="twitter:description" content={helmetDescription}/>   
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content="@zafiovskiy" />
          <meta name="twitter:image" content={helmetImage} />
          <meta name="twitter:url" content={helmetCanonicalhref} />

          <meta name="robots" content="index, follow"/>
        </Helmet>
        <div className="container">
          <OurServicesPage ourServicesData={ourServicesData} />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default OurServices;
