import HowItWorksImage from "../../../images/howItWorks.png";
import HowItWorksUaImage from "../../../images/howItWorksUa.png";
import { Fade } from "react-awesome-reveal";
import { ReactComponent as ArrowDown } from "../../../images/icons/arrow-down.svg";

import "./HowItWorks.css";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const animationDamping: number = 0.3;

  return (
    <div className="how-it-works-wrapper">
      <div className="container">
        <div className="how-it-works">
          <img
            src={currentLang === "ua" ? HowItWorksUaImage : HowItWorksImage}
            alt="How it works"
            className="how-it-works-image"
          />
          <div className="how-it-works-text-container">
            <p className="how-it-works-subtitle">{t("Five Simple Steps")}</p>
            <h2 className="how-it-works-title title-mobile-size">{t("How It Works")}</h2>
            <img src={HowItWorksImage} alt="How it works" className="how-it-works-image-mobile" />
            <ul className="how-it-words-list">
              <Fade direction="down" cascade damping={animationDamping} delay={200} duration={1000}>
                <li className="how-it-works-list-item">
                  {currentLang === 'en' ? 
                    <p><a href="/contact-us/" className="link-animated">Contact us</a> to explain your needs or request an <a href="/instant-quote/" className="link-animated">instant quote</a> for your business.</p> :
                    <p><a href="/contact-us/" className="link-animated">Зв’яжіться з нами</a>, щоб пояснити ваші потреби або скористайтеся нашою <a href="/instant-quote/" className="link-animated">миттєвою квотою.</a></p>
                  }
                </li>
                <ArrowDown height={30} width={30}/>
                <li className="how-it-works-list-item">
                  {currentLang === 'en' ? 
                    <p>Expect a response within 24 hours to schedule a meeting, if necessary.</p> :
                    <p>Чекайте відповіді протягом 24 годин для планування зустрічі, якщо необхідно.</p> 
                  }
                </li>
                <ArrowDown height={30} width={30}/>
                <li className="how-it-works-list-item">
                  {currentLang === 'en' ? 
                    <p>We'll ask key questions to develop a customized plan tailored to your needs.</p> :
                    <p>Ми обговоримо ключові питання та розробимо індивідуальний план для ваших потреб.</p> 
                  }
                </li>
                <ArrowDown height={30} width={30}/>
                <li className="how-it-works-list-item">
                  {currentLang === 'en' ? 
                    <p>Upon your approval of the plan and billing, we move forward.</p> :
                    <p>Після вашого схвалення плану та умов оплати ми розпочинаємо роботу.</p> 
                  }
                </li>
                <ArrowDown height={30} width={30}/>
                <li className="how-it-works-list-item">
                  {currentLang === 'en' ? 
                    <p>We begin our work, and you can relax and enjoy the results!</p> :
                    <p>Ми приступаємо до роботи, а ви насолоджуєтеся результатами!</p> 
                  }
                </li>
              </Fade>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
