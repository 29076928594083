import { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import InstantQuotePage from "./pages/InstantQuotePage";
import Blog from "./pages/Blog";
import Homepage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import OurServices from "./pages/OurServices/OurServices";
import { useLocation } from 'react-router-dom';
import { LabelContext } from "./pages/InstantQuotePage/labelDataContext";
import ContactUs from "./pages/Contacts";
import Careers from "./pages/Careers";
import VacancyPage from "./components/Vacancy";
import OurTeam from "./pages/OurTeam";
import TeamMember from "./pages/TeamMember";
import RoiCalculator from "./pages/ROICalculator";
import OurCases from "./pages/OurCases";
import CaseDetail from "./pages/OurCases/CasePage";

const getLanguageFromPath = (pathname: string) => {
  const pathSegments = pathname.split('/').filter(segment => segment === "ua");
  return pathSegments.length > 0 ? pathSegments[0] : 'en';
};

interface RoutesInterface {
  name: string;
  path: string;
  element?: JSX.Element;
}

export const routes: RoutesInterface[] = [
  { name: "Home", path: "/", element: <Homepage /> },
  { name: "About", path: "/about-us" },
  { name: "About", path: "/about-us/our-team/", element: <OurTeam/> },
  { name: "OurCases", path: "/about-us/our-cases/", element: <OurCases/> },
  { name: "Games localization", path: "/our-services/games-localization", element: <OurServices /> },
  { name: "Subtitle translation", path: "/our-services/subtitle-translation", element: <OurServices /> },
  { name: "Document translation", path: "/our-services/document-translation", element: <OurServices /> },
  { name: "404", path: "*", element: <NotFound /> },
  { name: "InstantQuote", path: "/instant-quote", element: <InstantQuotePage /> },
  { name: "Contacts", path: "/contact-us/", element: <ContactUs /> },
  { name: "Careers", path: "/careers/", element: <Careers /> },
  { name: "ROICalculator", path: "/roi-calculator/", element: <RoiCalculator/>},

  { name: "Home", path: "/ua", element: <Homepage /> },
  { name: "About", path: "/ua/about-us" },
  { name: "About", path: "/ua/about-us/our-team/", element: <OurTeam/> },
  { name: "OurCases", path: "/ua/about-us/our-cases/", element: <OurCases/> },
  { name: "Games localization", path: "/ua/our-services/games-localization", element: <OurServices /> },
  { name: "Subtitle translation", path: "/ua/our-services/subtitle-translation", element: <OurServices /> },
  { name: "Document translation", path: "/ua/our-services/document-translation", element: <OurServices /> },
  { name: "404", path: "*", element: <NotFound /> },
  { name: "InstantQuote", path: "/ua/instant-quote", element: <InstantQuotePage /> },
  { name: "Contacts", path: "/ua/contact-us/", element: <ContactUs /> },
  { name: "Careers", path: "/ua/careers/", element: <Careers /> },
  { name: "ROICalculator", path: "/ua/roi-calculator/", element: <RoiCalculator/>},

];

const RoutesComponent = (): JSX.Element => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const currentLanguage: string = i18n.language;
  const languageFromPath: string = getLanguageFromPath(pathname);
  const context = useContext(LabelContext);

  useEffect(() => {
    if(context && context.changeWebsiteLang && currentLanguage !== languageFromPath){
      i18n.changeLanguage(languageFromPath);
      context.changeWebsiteLang(languageFromPath);
    }
  }, [context, currentLanguage, languageFromPath, i18n]); 
  
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route path="/careers/:vacancyId" element={<VacancyPage />} />
      <Route path="/ua/careers/:vacancyId" element={<VacancyPage />} />
      <Route path="/about-us/our-team/:teamMemberId" element={<TeamMember />} />
      <Route path="/ua/about-us/our-team/:teamMemberId" element={<TeamMember />} />
      <Route path="/about-us/our-cases/:id" element={<CaseDetail />} />
      <Route path="/ua/about-us/our-cases/:id" element={<CaseDetail />} />
      <Route path={'/blog'}>
        <Route path={'/blog/:id'} element={<Blog />} />
      </Route>
      <Route path={'/ua/blog'}>
        <Route path={'/ua/blog/:id'} element={<Blog />} />
      </Route>
    </Routes>
  );
};

export default RoutesComponent;
