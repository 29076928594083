import { ReactComponent as BtnMoreSVG } from "../../images/icons/leftBtnArrow.svg";

import "./IconLeftButton.css";

interface IconLeftButtonnInterface {
  buttonName: string;
  disabled?: boolean;
  onClick?: () => void;
}

const IconLeftButton = ({ buttonName, disabled, onClick }: IconLeftButtonnInterface) => {
  return (
    <button disabled={disabled} onClick={onClick} className="icon-left-btn">
      <BtnMoreSVG className="icon-left" />
      <p>{buttonName}</p>
    </button>
  );
};

export default IconLeftButton;
