import { ReactComponent as CopySVG } from "../../images/icons/copy-to-clipboad-img.svg";
import "./EmailContainer.css";

interface CopyableEmailProps {
    emailAddress: string;
}

const EmailContainer = ({ emailAddress }: CopyableEmailProps) => {

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(emailAddress);
  };

  return (
    <div className='email-container'>
      <a href={`mailto:${emailAddress}`} className="link-underline copy-email">
        {emailAddress}
      </a>
      <CopySVG className="copy-svg" width="20px" height="20px" onClick={handleCopyEmail}/>
    </div>
  );
};

export default EmailContainer;