import axios from 'axios';
import { IContact } from './contact';
import { contactsEndPoint } from '../apiEndpoints';

export class HubSpotApiService {
  async createContact(client: IContact): Promise<void> {
    try {
      const config = {
        headers: {
          'content-type': 'application/json'
        },
      };

    await axios.post(contactsEndPoint, client, config);
    } catch (e: any) {
      e.message === 'HTTP request failed'
        ? console.error(JSON.stringify(e.response, null, 2))
        : console.error(e);

      throw e;
    }
  }
}
