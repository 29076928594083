import React, { useRef, useState } from "react";
import { useClickAway } from "react-use";
import { Language } from "../../pages/InstantQuotePage/labelDataContext";
import { ReactComponent as ArrowDownSVG } from "../../images/icons/languageDownArrow.svg";
import "./CustomDropdownSelect.css";
import { useTranslation } from "react-i18next";

interface CustomDropdownSelectInterface {
  label: string;
  selected: string | Language[];
  languagesArray: Language[];
  setFunction?: (item: any) => void;
}

const CustomDropdownSelect = ({ label, selected, languagesArray, setFunction }: CustomDropdownSelectInterface) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const filteredLanguages = () => {
    if (Array.isArray(selected)) {
      return languagesArray.filter((item) => !selected.includes(item));
    } else {
      return languagesArray.filter((item) => item.value !== selected);
    }
  };

  const handleClick = (item: Language) => {
    setFunction!(item);
    setOpen(false);
  };

  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));

  return (
    <div className="translate-dropdown" ref={ref}>
      <p className="translate-dropdown-label">{label}</p>
      <div className="translate-dropdown-selected" onClick={() => setOpen(!isOpen)}>
        {selected.length > 0 ? (
          Array.isArray(selected) ? (
            <p className="noselect">{selected.map((item) => item.value).join(", ")}</p>
          ) : (
            <p className="noselect">{selected}</p>
          )
        ) : (
          <p className="place-holder">{t('select language')}</p>
        )}
        <ArrowDownSVG />
      </div>
      {isOpen && (
        <div className="lang-dropdown-body open">
          {filteredLanguages().length < 1 ? (
            <p>{t('all lang selected')}</p>
          ) : (
            filteredLanguages().map((item) => (
              <div
                key={item.value as React.Key}
                className="dropdown-lang-item noselect"
                onClick={() => handleClick(item)}
              >
                {item.value}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdownSelect;
