import { createRef, useCallback, useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { scroller } from "react-scroll";

import { ReactComponent as RemoveFileSVG } from "../../../../images/icons/removeFileIcon.svg";
import { ReactComponent as WordSVG } from "../../../../images/icons/fileWordIcon.svg";
import { ReactComponent as PdfSVG } from "../../../../images/icons/filePdfIcon.svg";
import { ReactComponent as OtherSVG } from "../../../../images/icons/fileOtherIcon.svg";
import IconRightButton from "../../../../components/IconRightButton";
import PrimaryButton from "../../../../components/PrimaryButton";

import { LabelContext } from "../../labelDataContext";

import "./ContentStep.css";
import { useTranslation } from "react-i18next";

const acceptedFormats: string[] = [
  "application/msword",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/rtf",
  "text/*",
  "application/pdf",
];

export function CheckIcon(file: File) {
  if (
    file.type === "application/msword" ||
    file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    file.type === "application/vnd.ms-word.document.macroEnabled.12"
  ) {
    return <WordSVG />;
  } else if (file.type === "application/pdf") {
    return <PdfSVG />;
  } else return <OtherSVG />;
}

export const stepperPageHandler = (page: () => void) => {
  scroller.scrollTo("instant-quote-page-container", {
    duration: 1500,
    delay: 0,
    smooth: "easeInOutQuart",
  });
  page();
};

const ContentStep = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const dropzoneRef = createRef<any>();
  const value = useContext(LabelContext);
  const translate = value.labelInfo?.translate;

  const files = value.labelInfo?.translate.file;
  const btnDisabled = (translate?.text.length! || files!.length > 0) && error === false ? false : true;

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  useEffect(() => {
    if (translate?.text.length! > 0 && files!.length >= 1) {
      setError(true);
    } else {
      setError(false);
    }
  }, [translate?.text.length, files]);

  const checkUploadClass = () => {
    return error ? "upload-container error" : "upload-container";
  };

  const handleOnDrop = useCallback(
    (acceptedFiles) => {
      value.setFiles!(acceptedFiles, translate!);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files, translate]
  );

  return (
    <div className="content-step-container">
      <h4 className="content-title">{t('what translate')}</h4>
      <div className="upload-or-text-container">
        <Dropzone accept={acceptedFormats} ref={dropzoneRef} noClick noKeyboard onDrop={handleOnDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps({ className: checkUploadClass() })}>
                <div className="upload-container-text">
                  {files!.length ? (
                    <div className="uploaded-files ">
                      <input {...getInputProps()} />
                      <ul className="uploaded-list">
                        {files!.map((file, idx) => (
                          <div key={idx} className="uploaded-item">
                            <div className="file-details">
                              {CheckIcon(file)}
                              <li>{file.name}</li>
                            </div>
                            <RemoveFileSVG className="remove-icon" onClick={() => value.removeFiles!(idx)} />
                          </div>
                        ))}
                      </ul>
                      <IconRightButton theme="light" onClick={openDialog} buttonName={t('upload file')} />
                      <div className="fade"></div>
                    </div>
                  ) : (
                    <>
                      <input {...getInputProps()} />
                      <h3>{t('drag drop')}</h3>
                      <p>{t('or')}</p>
                      <PrimaryButton onClick={openDialog} buttonName={t('browse')} />
                      <PrimaryButton customClass="mobile" onClick={openDialog} buttonName={t('upload file')} />
                    </>
                  )}
                </div>
              </div>
            );
          }}
        </Dropzone>
        <p className="or-text">{t('Or')}</p>
        <div className={error ? "content-text-field error" : "content-text-field"}>
          <textarea
            className="text-input"
            placeholder={t('paste text')!}
            value={translate?.text}
            onChange={value.setTranslateInfo!("text")}
          ></textarea>
          <div className="fade"></div>
        </div>
      </div>
      {error && <h5 className="upload-error">{t('one option')}</h5>}
      <div className="navigation-button-container">
        <IconRightButton
          className="content-step-next"
          disabled={btnDisabled}
          buttonName={t('next')}
          theme="light"
          onClick={() => stepperPageHandler!(value.nextPage!)}
        />
      </div>
    </div>
  );
};

export default ContentStep;
