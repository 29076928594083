import "./OurTeam.css";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Fade } from "react-awesome-reveal";
import { OurTeamArray } from "./OurTeamArray";
import TeamMemberCard from "../../components/TeamMemberCard";
import VideoBackground from "../../components/VideoBackground";
import VideoBackgroundMP4 from "../../videos/outTeamVid.mp4";

const OurTeam = () => {
  const { t, i18n } = useTranslation();
  const language: string = i18n.language;
  const helmetOurTeamMetaDescription: string = t("helmetOurTeamMetaDescription");
  const helmetOurTeamKeywords: string = t("helmetOurTeamKeywords");
  const helmetOurTeamTitle: string = t("helmetOurTeamTitle");

  return (
    <HelmetProvider>
        <Helmet>
                <title>{helmetOurTeamTitle}</title>
                <meta name="description" content={helmetOurTeamMetaDescription} />
                <meta name="keywords" content={helmetOurTeamKeywords} />
                <meta name="robots" content="index, follow"/>

                <meta property="og:image" content=""/>
                <meta property="og:title" content={helmetOurTeamTitle} />
                <meta property="og:description" content={helmetOurTeamMetaDescription} />
                {language === 'en' && (
                <meta property="og:locale" content="en_US" />
                )}
                {language === 'ua' && (
                <meta property="og:locale" content="uk_UA" />
                )}
                
                {language === 'en' && (
                <meta property="og:url" content="https://wordlords.co/about-us/our-team/" />
                )}
                {language === 'ua' && (
                <meta property="og:url" content="https://wordlords.co/ua/about-us/our-team/" />
                )}

                {language === 'en' && (
                <link rel="canonical" href="https://wordlords.co/about-us/our-team/" />
                )}
                {language === 'ua' && (
                <link rel="canonical" href="https://wordlords.co/ua/about-us/our-team/" />
                )}
                
            </Helmet>
        <div className="our-team-container">
            <div className="our-team-header-container">
                <VideoBackground src={VideoBackgroundMP4} />
                <Fade direction="down" triggerOnce={true} cascade damping={0.3} delay={200} duration={1500}>
                    <h1>{t('whoWeAre')}</h1>
                    <h4>{t('peopleBehindWordlords')}</h4>
                </Fade>
            </div>
            <div className="our-team-people-container">
                <div className="our-team-people-heading">
                    <Fade direction="down" triggerOnce={true} cascade damping={0.3} delay={1400} duration={1500}>
                        <h2>{t('ourTeamPeopleHeading')}</h2>
                        <h3>{t('ourTeamPeopleSubheading')}</h3>
                    </Fade>
                </div>
                <div className="our-team-members-container">
                    {OurTeamArray.map(member => (
                        <TeamMemberCard key={member.teamMemberId} teamMember={member}/>
                    ))}
                </div>
            </div>
            <div className="our-team-join-container">
                <h2 className="our-team-join-heading">{t('ourTeamJoinHeading')}</h2>  
                <a href={`${language === 'en' ? '' : '/ua'}/careers/`} className="link-button" >
                    <h5>{t('ourTeamJoinButtonName')}</h5>
                </a>  
            </div>
        </div>
    </HelmetProvider>
  );
};

export default OurTeam;
