import { LabelInfo } from "../pages/InstantQuotePage/labelDataContext";
import { toast } from "react-toastify";
import i18n from "../i18n";


export type Attachment =
  | {
      name: string;
      path: string;
    }
  | {
      name: string;
      data: string; // base64 format
      encoding?: string;
    };

export interface EmailData {
  Host: string;
  Username: string;
  Password: string;
  To: string | string[];
  From: string;
  Subject: string;
  Body: string;
  Attachments?: Attachment[];
}

export interface Email {
  send: (email: EmailData) => Promise<unknown>;
}

declare global {
  interface Window {
    Email: Email;
  }
}

const formatMailBody = (
  data: LabelInfo,
  options?: {
    attachmentsError: boolean;
  }
) => {
  let result =
    `Name: ${data.userContacts.fName} ${data.userContacts.lName} <br/>` +
    `Email: ${data.userContacts.email} <br/>` +
    `Text to translate: ${data.translate.text} <br/>` +
    `Translate from: ${data.languages.translateFrom} <br/>` +
    `Translate to: ${data.languages.translateTo.map((obj) => obj.value).join(", ")} <br/>` +
    `Note: ${data.translate.note || "No notes"} <br/>`;

  if (options?.attachmentsError) {
    result += `<br/><div style="color: #d52b1e">Attachments were added, but not sent</div>`;
  }

  return result;
};

const convertAttachments = async (files: File[]) => {
  if (files.length === 0) {
    return [];
  }

  const readFile = (file: File): Promise<Attachment> => {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const content = fileReader.result;
        resolve({
          name: file.name,
          data: String(content),
        });
      };
      fileReader.readAsDataURL(file);
    });
  };

  const result: Attachment[] = [];
  for (const file of files) {
    const attachment = await readFile(file);
    result.push(attachment);
  }

  return result;
};


// uses smtpjs from CDN
export const sendInquiryToEmail = async (inqury: LabelInfo) => {
  const { send } = window.Email;

  const attachments = await convertAttachments(inqury.translate.file);

  const response = await toast.promise(
    send({
      Host: "smtp.elasticemail.com",
      Username: "info@wordlords.co",
      Password: "DED64A65066324C406BD205467527F253EC8",
      To: `info@wordlords.co`,
      From: `info@wordlords.co`,
      Subject: `Inquiry from Wordlords site`,
      Body: formatMailBody(inqury),
      Attachments: attachments,
    }),
    {
      pending: "Sending",
      success: i18n.t('received request') as string,
      error: "Something went wrong. \nPlease try again",
    }
  );

  if (response !== "OK") {
    await send({
      Host: "smtp.elasticemail.com",
      Username: "info@wordlords.co",
      Password: "DED64A65066324C406BD205467527F253EC8",
      To: `andrian@wordlords.co`,
      From: `info@wordlords.co`,
      Subject: `Inquiry from Wordlords site`,
      Body: formatMailBody(inqury, { attachmentsError: true }),
    });
  }
};
