import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as QuotesIconSVG } from "../../../../images/icons/quotesIcon.svg";

import "./Card.css";

interface Image {
  src: string;
}

interface Person {
  name: string;
  date: string;
  testimonial?: string;
}

export interface CardInterface {
  person: Person;
  media: {
    type: "image";
    content: Image;
  };
  id: number;
}

const Card = ({ person, media, id }: CardInterface): ReactElement => {
  const {t} = useTranslation();
  return (
    <div className="card-container">
      <QuotesIconSVG />
      <p className="testimonial-text">{t(`testimonials${id}`)}</p>
      <div className="person-container">
        <img width={70} height={70} className="person-image" src={media.content.src} alt={t(`person${id}`) as string} />
        <div className="person-details-container">
          <p className="testimonial-date">{person.date}</p>
          <p className="person-name">{t(`person${id}`)}</p>
        </div>
      </div>
    </div>
  );
}
export default Card;
