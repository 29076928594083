import React, { useEffect } from 'react';

const TawkToWidget: React.FC = () => {
  useEffect(() => {
    // Define the Tawk.to script to be added
    const addTawkToScript = (): void => {
      (function() {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/65b92ba30ff6374032c68ec8/1hldl4u3q';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode?.insertBefore(s1, s0);
      })();
    };

    // Invoke the function to add the Tawk.to script
    addTawkToScript();
  }, []); // The empty array ensures this effect runs only once after the initial render

  return null; // This component does not render anything itself
};

export default TawkToWidget;
