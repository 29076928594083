import ReactTooltip from "react-tooltip";

import { ReactComponent as TooltipIcon } from "../../images/icons/tooltipIcon.svg";

import "./CustomCheckbox.css";

interface ICustomCheckbox {
  checked: boolean;
  label: string;
  tipText: string;
  onChange?: () => void;
}

const CustomCheckbox = ({ checked, label, tipText, onChange }: ICustomCheckbox) => {
  return (
    <div className="checkbox-container">
      <input id={label} className="checkbox-input" type="checkbox" checked={checked} onChange={onChange} />
      <label htmlFor={label}> {label}</label>
      <TooltipIcon data-for="tip" data-tip={tipText} />
      <ReactTooltip
        className="tip"
        id="tip"
        multiline={true}
        effect="solid"
        delayHide={400}
        delayShow={400}
        backgroundColor="#71778A"
        textColor="#E9EBF2"
      />
    </div>
  );
};

export default CustomCheckbox;
