import { BlogCardInterface } from ".";
import blog1Image from "../../images/game-translation-blog-image.png";
import blog2Image from "../../images/media-automated-translation-tips-blog-image.png";
import blog3Image from "../../images/how-to-translate-wordpress-image-blog.png";
import blog4Image from "../../images/ukrainian-services-blog-image.png";
import lbsSuiteBlogImage from "../../images/lbs-suite-demo.png";
import beLazyBlogImage from "../../images/be-lazy-blog-image.png";
import beLazyBlogImageDiagram from "../../images/be-lazy-blog-image-diagram.png";
import acornImage from "../../images/acornPhoto.jpeg";
import roiBlogImage from "../../images/roi-localisation-blog-image.webp"


import blogAuthorIconAndrianZafiiovskiy from "../../images/icons/blogImageAndrianZaf.svg";

const BlogPostsArray: BlogCardInterface[] = [
  {
    blogId: "game-translation",
    blogImages: {
      blogMainImage: blog1Image,
      blogAuthorImage: blogAuthorIconAndrianZafiiovskiy,
    },
    blogAuthor: {
      authorName: "Andrian Zafiiovskiy",
      blogDate: "Nov 23, 2023",
      authorId: "andrian-zafiiovskiy"
    },

    blogText: {
      subtitle: "Level Up Your Game Translation: Meeting the Unique Needs",
      title: "The Challenge of Cultural Nuances in translation",
      content: [
        {
          subtitle: "Section 1. Why AI sometimes cannot handle it?",
          text: [
            "In the intricate realm of game translation, one of the most significant challenges lies in capturing the rich tapestry of cultural nuances. Unlike straightforward text translation, games often contain layers of cultural references, humor, and context that require a nuanced approach."
            ,"Even for the most advanced AI, understanding and reproducing these subtleties poses a considerable challenge."
            ,"To illustrate this, let's delve into the fascinating world of '[Aridnyk:https://boardova.com/en/our-products],' a tabletop game that immerses players in the mythology of Gutsulshchina. This unique game presented a formidable challenge in translating not just words but an entire cultural experience. The game's narrative is deeply rooted in the Gutsulshchina mythology, complete with its dialect and cultural idiosyncrasies."
            ,"In the translation of 'Aridnyk' from Ukrainian to English, we faced the task of not merely substituting words but ensuring that the cultural essence remained intact. The dialect used in the game posed a distinctive hurdle, as direct translation would not suffice."
            ,"Here's where the limitations of AI become evident. AI, while incredibly powerful, struggles to grasp the intricate nuances and historical context embedded in cultural expressions."
            ,"To overcome this challenge, we took an innovative approach by aligning 'Aridnyk' with a relatively similar cultural group for English localization. The decision was made to draw parallels with Scottish culture and dialect, creating a translation vector that would resonate authentically with British citizens. By doing so, we aimed to maintain the game's immersive quality and ensure that players in the English-speaking world experienced 'Aridnyk' in a way that felt native and culturally resonant."
            ,"This example underscores the importance of a human touch in game translation, where linguistic experts not only understand the languages but also appreciate the cultural intricacies, ensuring that the essence of the game transcends borders seamlessly."
          ] 
        },
        {
          subtitle: "Section 2. In-Game Consistency is Key",
          text: [
            "Imagine playing a game where the terminology is inconsistent or the characters' voices don't align with their personalities. Game translation requires meticulous attention to detail to maintain consistency in terminology, character voices, and overall narrative.",
            "At Wordlords, our translation process is tailored to ensure a seamless gaming experience, keeping players fully immersed in the virtual worlds you've crafted.",
            "Consistency is not just a matter of linguistic precision; it extends to the overall gaming experience. Our team places a strong emphasis on maintaining a cohesive terminology throughout the game, ensuring that players can fully engage without disruptions or confusion.",
            "[Localization Quality Assurance:https://www.gridly.com/blog/what-is-localization-quality-assurance-lqa/] (LQA) plays a pivotal role in achieving this goal. Rigorous testing is conducted to identify and rectify any discrepancies in translation, ensuring that the final product meets the highest standards of linguistic and contextual integrity.",
            "Moreover, we understand that consistency goes beyond language. Consideration must be given to the context of display, the time of display, and other technical nuances. For instance, a translated phrase might be grammatically correct, but if it doesn't fit within the character limit for a dialogue box or if it appears at an inappropriate time, the gaming experience can be compromised.",
            "Our commitment to excellence involves not only linguistic expertise but also a keen understanding of the technical aspects of game localization. By harmonizing linguistic precision with technical considerations, we guarantee that your game maintains its integrity across all dimensions, providing players with a consistently immersive and enjoyable experience."
          ]
        },
        {
          subtitle: "Section 3. Technical Challenges of Game Localization",
          text: [
            "Unlike traditional text, games often involve intricate coding and programming. Translating a game involves not only linguistic expertise but also a deep understanding of the technical aspects.",
            "Our team at Wordlords consists of skilled professionals who are not only language experts but also well-versed in the technical intricacies of game localization.",
            "Navigating the coding maze requires precision and a holistic approach. We understand the importance of preserving the functionality of the game while ensuring that the translated content seamlessly integrates into the gaming environment."
          ]
        },
        {
          subtitle: "Section 4. Player Engagement through Localization",
          text: [
            "Game localization isn't just about translating the game; it's about connecting with players on a personal level. Tailoring the gaming experience to resonate with different cultures enhances player engagement.",
            "At Wordlords, we go beyond translation to create an immersive experience that transcends language barriers, ensuring that your game resonates with audiences worldwide.",
            "By understanding the cultural nuances, preferences, and expectations of diverse player communities, we craft a localized gaming experience that feels native, fostering a deeper connection between the game and its global audience."
          ]
        },
        {
          subtitle: "Conclusion",
          text: [
            "In the dynamic realm of game translation, Wordlords stands as a beacon of expertise, addressing the unique needs that this specialized field demands.",
            "By combining linguistic proficiency, cultural understanding, and technical prowess, we ensure that your games not only speak the language but also capture the hearts of players across the globe.",
            "Level up your game translation with Wordlords and let your games speak to a truly global audience."
          ]
        }
      ],
    },
    blogMetaData: {
      title: "Wordlords: Your Gateway to a Truly Global Gaming Audience",
      description: "Elevate your game translation with Wordlords. Masters of cultural nuances, seamless in-game consistency, and technical prowess, we deliver immersive gaming experiences for a global audience. Wordlords: Where games speak the language of the world."
      ,date: "2023-11-10"
    }
  },
  {
    blogId: "media-translation-tips",
    blogImages: {
      blogMainImage: blog2Image,
      blogAuthorImage: blogAuthorIconAndrianZafiiovskiy,
    },
    blogAuthor: {
      authorName: "Andrian Zafiiovskiy",
      blogDate: "Sep 04, 2022",
      authorId: "andrian-zafiiovskiy"
    },

    blogText: {
      subtitle: "Translation automation for media platforms",
      title: "Elevate Your Media Translation: Expert Automation Tips",
      content: [
        {
          subtitle: "Embrace Cutting-edge Translation Tools",
          text: [
            "Invest in advanced translation tools to enhance your media translation workflow. Platforms like [SDL Trados:https://en.wikipedia.org/wiki/Trados_Studio], Memsource (now [Phrase:https://phrase.com/]), and [Smartcat:https://www.smartcat.com/] offer robust features, including machine translation integration, terminology management, and collaboration capabilities. These tools boost productivity and ensure consistency across your media content."
          ]
        },
        {
          subtitle: "Leverage AI-Powered Solutions",
          text: [
            "Explore AI-powered translation services such as [Google Cloud Translation API:https://cloud.google.com/translate/docs/reference/rest], Microsoft Translator, or Amazon Translate. These tools leverage machine learning algorithms to improve translation quality over time, making them valuable assets for media content with diverse language nuances."
          ]
        },
        {
          subtitle: "Develop Customized Translation Models",
          text: [
            "Tailor your translation models to the specific needs of your media content. By training machine translation models on your organization's terminology and industry-specific language, you can achieve more accurate and contextually relevant translations. This customization minimizes the risk of misinterpretation and ensures a seamless viewer experience."
          ]
        },
        {
          subtitle: "Implement Continuous Localization",
          text: [
            "Automate the process of updating translated content in real-time by integrating translation tools with your content management system (CMS). This ensures that your audience receives timely and culturally sensitive translations, keeping them engaged with your media across different languages."
          ]
        },
        {
          subtitle: "Quality Assurance Through Automation",
          text: [
            "Maintain translation quality with automated quality assurance (QA) processes. Implement tools that perform linguistic checks, verify terminology consistency, and flag potential errors. This reduces the manual workload and guarantees a polished and professional final product."
          ]
        },
        {
          subtitle: "Integrate Translation Workflows with Media Production",
          text: [
            "Seamlessly integrate translation workflows with your media production pipeline. Automation tools can be linked to video editing software, enabling synchronized subtitling and voiceover processes. This integration ensures that your translated media is ready for distribution as soon as the original content is finalized."
          ]
        },
        {
          subtitle: "Stay Compliant with Accessibility Standards",
          text: [
            "Automation can play a pivotal role in ensuring your translated media complies with accessibility standards. Implement tools that automate the creation of subtitles, closed captions, and audio descriptions, making your content inclusive and accessible to a broader audience."
          ]
        },
        {
          subtitle: "Collaborate in Real-time",
          text: [
            "Enhance collaboration among your translation team by leveraging real-time collaboration features offered by modern translation platforms. This ensures that all team members are on the same page, leading to more coherent and accurate translations."
          ]
        },
        {
          subtitle: "Conclusion: Revolutionize Your Media Translation Workflow",
          text: [
            "In the ever-evolving landscape of media translation, automation is the key to efficiency, accuracy, and global reach. By adopting cutting-edge tools, embracing AI solutions, and integrating translation seamlessly into your media production pipeline, you can elevate your translation game and captivate audiences worldwide. Explore the possibilities of automation, stay ahead in the translation game, and let your media content transcend language barriers effortlessly. Revolutionize your media translation workflow, and watch your global audience grow."
          ]
        }
      ]
      
    },

    blogMetaData: {
      title: "Harnessing Automation for Flawless Multilingual Media Content",
      description: "Unleash the power of automation to elevate your media translation. Discover expert tips on leveraging AI, developing customized translation models, and integrating translation seamlessly into your media production workflow. Revolutionize your translation game and captivate audiences worldwide with Wordlords' automation expertise."
      ,date: "2023-12-01"
    }
  },
  {
    blogId: "how-to-translate-wordpress",
    blogImages: {
      blogMainImage: blog3Image,
      blogAuthorImage: blogAuthorIconAndrianZafiiovskiy,
    },
    blogAuthor: {
      authorName: "Andrian Zafiiovskiy",
      blogDate: "Sep 04, 2022",
      authorId: "andrian-zafiiovskiy"
    },

    blogText: {
      subtitle: "How to translate a WordPress website",
      title: "Optimizing Your WordPress Website for Global Audiences: A Strategic Guide to Translation",
      content: [
        {
          subtitle: "Section 1: Selecting the Optimal Translation Plugin",
          text: [
            "Launching your WordPress translation initiative requires judicious tool selection. Among the plethora of options, plugins like [WPML:https://wpml.org/] and [Polylang:https://polylang.pro/] stand out for their user-friendly interfaces and seamless integration. Consider factors such as ease of implementation, compatibility with your theme, and support for your target languages.",
            "Here are links to all most popular Wordpress translation plugins: <ul style=\"list-style-type: disc;margin-left: 10%;\"><li>[WPML:https://wpml.org/]</li><li>[TranslatePress:https://translatepress.com/]</li><li>[Polylang:https://polylang.pro/]</li><li>[Weglot:https://weglot.com/]</li><li>[GTranslate:https://gtranslate.io/]</li></ul>"            
          ]
        },
        {
          subtitle: "Section 2: Crafting Multilingual Content - Elevating the Art of Translation",
          text: [
            "Effective translation transcends mere linguistic conversion; it's about conveying the subtleties of your content across languages. Pay meticulous attention to cultural nuances, idiomatic expressions, and tonal intricacies to ensure that your message resonates authentically with diverse audiences. Uphold a consistent style and tone across languages for a cohesive and engaging user experience."
          ]
        },
        {
          subtitle: "Section 3: Embracing Localization - Customizing for Cultural Precision",
          text: [
            "Localization is the linchpin for cultural resonance. Customize date formats, currency symbols, and culturally specific elements to align seamlessly with the preferences of your target audience. This step ensures that your WordPress website not only speaks the language but also exudes a native feel for users from distinct regions."
          ]
        },
        {
          subtitle: "Section 4: Technical Considerations - SEO and Performance Optimization",
          text: [
            "Translation's impact extends beyond language; it influences website performance and SEO. Optimize your translated pages for search engines by refining SEO elements such as URLs, meta tags, and image alt texts in each language. Additionally, address the performance implications of translation plugins, optimizing your site for speed and operational efficiency."
          ]
        },
        {
          subtitle: "Section 5: Quality Assurance - Pinnacle of User Experience",
          text: [
            "Prior to the grand reveal of your multilingual WordPress masterpiece, conduct meticulous Quality Assurance (QA) assessments. Scrutinize every facet of your website, from navigation to form functionalities, ensuring a seamless user experience. Address any layout discrepancies, rectify broken links, and resolve language-specific bugs to guarantee a flawless browsing experience for visitors across the globe."
          ]
        },
        {
          subtitle: "Conclusion: Unleashing Global Opportunities Through Strategic Translation",
          text: [
            "As the digital landscape evolves, the translation of your WordPress website becomes a gateway to a global audience. By selecting the right translation tools, crafting nuanced content, embracing meticulous localization, considering technical intricacies, and ensuring stringent quality assurance, your website transforms into a beacon, beckoning users from diverse cultures to explore, engage, and connect with your brand on a truly global scale.",
            "Unlock the full potential of your WordPress site, master the art of translation, and create an online presence that resonates with audiences worldwide. In a world that celebrates diversity, your multilingual WordPress site becomes an ambassador, inviting users to explore, engage, and connect across linguistic and cultural boundaries."
          ]
        }
      ]
    },
    blogMetaData: {
      title: "Best Wordpress Translation Plugins: Mastering WordPress Translation",
      description: "Expand your WordPress website's reach with strategic translation. Discover expert guidance on selecting translation plugins, crafting multilingual content, embracing localization, optimizing for SEO and performance, and ensuring rigorous quality assurance. Unleash global opportunities with Wordlords' expertise in WordPress translation."
      ,date: "2023-12-01"
    }
  },
  {
    blogId: "ukrainian-translation-services",
    blogImages: {
      blogMainImage: blog4Image,
      blogAuthorImage: blogAuthorIconAndrianZafiiovskiy,
    },
    blogAuthor: {
      authorName: "Andrian Zafiiovskiy",
      blogDate: "Nov 04, 2023",
      authorId: "andrian-zafiiovskiy"
    },

    blogText: {
      subtitle: "Why Choose Our Ukrainian Translation Services?",
      title: "Unlocking the Power of Ukrainian Translation Services",
      content: [
        {
          subtitle: "",
          text: [
            "In our interconnected world, effective communication is a key driver of success. Whether you're a business expanding into Ukrainian-speaking markets or an individual seeking precise and culturally relevant translations, our Ukrainian translation services at Wordlords are your gateway to linguistic excellence.",
          ]
        },
        {
          subtitle: "Understanding the Power of Accurate Communication",
          text: [
            "[Ukrainian:https://en.wikipedia.org/wiki/Ukrainian_language] is not just a language; it's a rich tapestry of culture and history. Our team of expert linguists not only understands the intricacies of the Ukrainian language but is also deeply immersed in the nuances of Ukrainian culture. This profound understanding allows us to deliver translations that go beyond words, capturing the essence of your message.",
          ]
        },
        {
          subtitle: "Why Choose Wordlords for Ukrainian Translation?",
          text: [
            "<ul style=\"list-style-type: disc;\"><li><strong style=\"font-weight: bold;\">Expert Linguists:</strong> Our team consists of skilled linguists with a specialization in Ukrainian language and culture. Each translator is carefully chosen for their expertise and commitment to delivering high-quality translations.</li><li><strong style=\"font-weight: bold;\">Cultural Sensitivity:</strong> We recognize the importance of cultural nuances in language. Our translations are not just accurate; they are culturally sensitive, ensuring that your message resonates authentically with the Ukrainian audience.</li><li><strong style=\"font-weight: bold;\">Versatility of Services:</strong> Whether you need document translation, website localization, or multilingual support, Wordlords has you covered. Our comprehensive range of services is designed to meet the diverse language needs of our clients.</li><li><strong style=\"font-weight: bold;\">Automation of Services:</strong> Embracing the latest tech practices, Wordlords incorporates automation into our translation services. We utilize [Git:https://git-scm.com/] data translation or API automation, making it ideal for websites and products like games that require continuous translation of code base objects.</li></ul>",
          ]
        },
        {
          subtitle: "How to contact us?",
          text: [
            "Here is out [contacts page:https://wordlords.co/contact-us/] where you can get in touch with us!"
          ]
        }
      ]
    },
    blogMetaData: {
      title: "Best Ukrainian Translation Services - Wordlords",
      description: "Discover unparalleled Ukrainian translation services tailored to meet your communication needs. Our expert translators ensure precision, cultural relevance, and timely delivery. Elevate your content with our language expertise. Explore the power of accurate and culturally sensitive translations.",
      date: "2023-12-04"
    }
  },
  {
    blogId: "why-lbs-suite",
    blogImages: {
      blogMainImage: lbsSuiteBlogImage,
      blogAuthorImage: blogAuthorIconAndrianZafiiovskiy,
    },
    blogAuthor: {
      authorName: "Andrian Zafiiovskiy",
      blogDate: "Jan 21, 2024",
      authorId: "andrian-zafiiovskiy"
    },

    blogText: {
      subtitle: "Insights from Wordlords' CTO",
      title: "Is LBS Suite Right for My Language Service Provider? An In-Depth Review",
      content: [
        {
          subtitle: "Inroduction:",
          text: [
            "Recently, as the CTO of Wordlords, I teamed up with our CEO to find the best BMS/TMS system for our LSP. Our search led us to [LBS Suite:https://www.lbssuite.com/]. In this post, I'll share our initial impressions from the demo and our research to help you decide if LBS Suite is the right choice for you. Let's dive in!",
          ]
        },
        {
          subtitle: "Overview of LBS Suite:",
          text: [
            "At Wordlords, we understand the importance of efficient project management. LBS Suite offers a range of functionalities aimed at enhancing the workflow of translation projects. It stands out for its comprehensive approach to managing client and vendor relationships, financial processes, and customizable operational needs.",
          ]
        },
        {
          subtitle: "Key features:",
          text: [
            `<ul style="list-style-type: disc;">
              <li>
                <strong style="font-weight: bold;">Client & Translator Portals:</strong> I belive this is the strongest feature of LBS Suite. It gives you an ability to provide separate Web based aplication like experience for your translators and for vendors, which is something that may be difficult for other tools, but this BMS did this in a user-friendly and easy-to-use way.
              </li>
              <li>
                <strong style="font-weight: bold;">Project Management:</strong> LBS Suite's project management capabilities has been positive, with features like automated project creation and deadline tracking.
              </li>
              <li>
                <strong style="font-weight: bold;">Client and Vendor Management:</strong> The tool’s ability to manage and improve client and vendor communication has been beneficial for our operations.
              </li>
              <li>
                <strong style="font-weight: bold;">Financial Management:</strong> I’ve found its financial tools, including invoicing and budgeting, to be robust and user-friendly.
              </li>
              <li>
                <strong style="font-weight: bold;">Customizability and Integration:</strong> Great and robust configuration and integration with other tools on the market. Support for Memoq, BeLazy, Frase and other tools.
              </li>
              <li>
                <strong style="font-weight: bold;">User Interface and Experience:</strong> The UI may be not modern, but it's understandable and easy to use unlike in most other BMS/TMS tools on the market.
              </li>
            </ul>`,
          ]
        },
        {
          subtitle: "Cons vs Pros:",
          text: [
            `<div style="max-width: 600px; margin: auto;">
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px; text-align: center; margin-top: 10px; margin-bottom: 30px">
                <div style="padding: 20px; border-radius: 8px; background-color: #d4edda; border: 1px solid #c3e6cb; color: #155724;">
                    <h2 style="font-size: 1.5em; margin-bottom: 10px;">Pros</h2>
                    <ul style="list-style: none; padding: 0;">
                        <li style="margin-bottom: 10px; text-align: center; border: 1px solid #c3e6cb; padding: 10px; border-radius: 5px; background-color: white;">Web based great Client & Translator portals</li>
                        <li style="margin-bottom: 10px; text-align: center; border: 1px solid #c3e6cb; padding: 10px; border-radius: 5px; background-color: white;">Clear and understandable UI</li>
                        <li style="margin-bottom: 10px; text-align: center; border: 1px solid #c3e6cb; padding: 10px; border-radius: 5px; background-color: white;">Good workflows</li>
                        <li style="margin-bottom: 10px; text-align: center; border: 1px solid #c3e6cb; padding: 10px; border-radius: 5px; background-color: white;">Great client support with ability for free custom feature development</li>
                    </ul>
                </div>
                <div style="padding: 20px; border-radius: 8px; background-color: #f8d7da; border: 1px solid #f5c6cb; color: #721c24;">
                    <h2 style="font-size: 1.5em; margin-bottom: 10px;">Cons</h2>
                    <ul style="list-style: none; padding: 0;">
                        <li style="margin-bottom: 10px; text-align: center; border: 1px solid #f5c6cb; padding: 10px; border-radius: 5px; background-color: white;">No MacOS native support (LBS Suit is a Windows based app)</li>
                        <li style="margin-bottom: 10px; text-align: center; border: 1px solid #f5c6cb; padding: 10px; border-radius: 5px; background-color: white;">No web based app for main functionality</li>
                        <!-- Add more cons as needed -->
                    </ul>
                </div>
            </div>
        </div>
            `,
          ]
        },
        {
          subtitle: "Pricing and Plans:",
          text: [
            `LBS Suite's pricing is in line with industry standards, similar to platforms like [XTRF:https://xtrf.eu/packages/], and reflects its status as a premium solution. It's important to note that LBS Suite isn't a budget option, but its unique features could be the perfect match for your business's specific needs. Since pricing is tailored to each client's case, to get a clear understanding of the costs involved for your particular business, I recommend [booking a demo:https://www.lbssuite.com/demo/] or directly contacting LBS Solutions. This personalized approach allows you to explore how LBS Suite's specific functionalities can add value to your operations and receive a custom quote that fits your LSP's requirements.`,
          ]
        },
        {
          subtitle: "Conclusion and Personal Opinion:",
          text: [
            `In summary, LBS Suite is a relatively new but promising BMS tool in the translation market, having honed its features during its exclusive years of operation in France. Our assessment at Wordlords is that it stands out as an excellent option. Its robust capabilities and innovative features make it a strong contender, particularly for those who see value beyond the cost and minor drawbacks. If you're looking for a tool that brings a fresh perspective to translation management with a track record of success, LBS Suite could be the ideal choice for your business.`,
          ]
        },
        {
          subtitle: "Read our other blog:",
          text: [
            `[Why should you choose Wordlords if you want to enter the Ukrainian market?:https://wordlords.co/blog/ukrainian-translation-services/]`,
          ]
        },
      ]
    },
    blogMetaData: {
      title: "Is LBS Suite Right for My Language Service Provider",
      description: "Discover the potential of LBS Suite for Language Service Providers in this insightful review by Andrian Zafiiovskiy, CTO of Wordlords. Get a firsthand look at the features, pros, and cons of LBS Suite and determine if it's the right BMS/TMS solution for your business.",
      date: "2024-01-21"
    }
  },
  {
    blogId: "be-lazy",
    blogImages: {
      blogMainImage: beLazyBlogImage,
      blogAuthorImage: blogAuthorIconAndrianZafiiovskiy,
    },
    blogAuthor: {
      authorName: "Andrian Zafiiovskiy",
      blogDate: "Jan 23, 2024",
      authorId: "andrian-zafiiovskiy"
    },

    blogText: {
      subtitle: "Is BeLazy a CAT Tool, a BMS, a TMS, or an All-in-One Solution?",
      title: "What is BeLazy? An Exploration of a Unique Industry Tool from Wordlords",
      content: [
        {
          subtitle: "What is this blog about:",
          text: [
            "As CTO of Wordlords, exploring innovative tools is key to our success. That's why I was intrigued by [BeLazy:https://belazy.cat/], a unique tool promising to integrate various BMS, TMS, and CAT tools. This post shares my thoughts on BeLazy which I got from a detailed demo and an in-depth research."
          ]
        },
        {
          subtitle: "BeLazy: An Overview",
          text: [
            "BeLazy stands out in the LSP tool landscape. It's more than just a CAT tool, BMS, or TMS – it's a comprehensive solution, aiming to integrate these systems into a single efficient platform for managing all LSP projects. It's a relatively simple tool that is not an all-in-one package but rather a place where you can perform most important actions and analyze data from various TMS, BMS, and CAT tools.",
            "For example you can manage projects from [XTRF:https://xtrf.eu/] and [Plunet:https://www.plunet.com/en/] that are done in [Memoq:https://www.memoq.com/] and [Phrase:https://phrase.com/] at the same time while integrating some sort of reporting for Google Sheets or even custom API reports"
          ],
          image: beLazyBlogImageDiagram
        },
        {
          subtitle: "Key Functionalities:",
          text: [
            `<ul style="list-style-type: disc;">
              <li>
                <strong style="font-weight: bold;">Integration Capabilities:</strong> BeLazy's main feature is its ability to integrate with a variety of BMS, TMS, and CAT tools, streamlining workflows across platforms. Here is the [exact list:https://belazy.cat/integrations/] of all possible intergrations, but I will hightlight thouse that were most interesting and unique for me. First, you can integrate with [Make:https://www.make.com/en] which is API like constructor simmilar to [Zapier:https://zapier.com/] and possibilities with this feature are numerous. Second, BeLazy has a great client API to intergate your own solutions. And third honorable mention is LBS Solutions, about whom we taked in [this blog:https://wordlords.co/blog/why-lbs-suite/].
              </li>
              <li>
                <strong style="font-weight: bold;">Project Management Efficiency:</strong> It offers a centralized dashboard for managing projects, enhancing visibility and control over various tasks.
              </li>
              <li>
                <strong style="font-weight: bold;">User Experience and Interface:</strong> The interface is intuitive and user-friendly, designed for ease of use while offering powerful features.
              </li>
              <li>
                <strong style="font-weight: bold;">Customization and Flexibility:</strong> BeLazy provides customization options to cater to the specific needs of different LSPs, ensuring a versatile and adaptable experience.
              </li>
            </ul>`
          ]
        },
        {
          subtitle: "Pros and Cons:",
          text: [
            `<div style="max-width: 600px; margin: auto;">
              <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px; text-align: center; margin-top: 10px; margin-bottom: 30px">
                  <div style="padding: 20px; border-radius: 8px; background-color: #d4edda; border: 1px solid #c3e6cb; color: #155724;">
                      <h2 style="font-size: 1.5em; margin-bottom: 10px;">Pros</h2>
                      <ul style="list-style: none; padding: 0;">
                          <li style="margin-bottom: 10px; text-align: center;">Ability to have a clear oversight for all your systems</li>
                          <li style="margin-bottom: 10px; text-align: center;">Enhanced project management</li>
                          <li style="margin-bottom: 10px; text-align: center;">Customizable to fit specific LSP needs</li>
                          <li style="margin-bottom: 10px; text-align: center;">User-friendly and well desighed interface</li>
                      </ul>
                  </div>
                  <div style="padding: 20px; border-radius: 8px; background-color: #f8d7da; border: 1px solid #f5c6cb; color: #721c24;">
                      <h2 style="font-size: 1.5em; margin-bottom: 10px;">Cons</h2>
                      <ul style="list-style: none; padding: 0;">
                          <li style="margin-bottom: 10px; text-align: center;">No direct integration with management tools like Trello or Asana</li>
                      </ul>
                  </div>
              </div>
            </div>`
          ]
        },
        {
          subtitle: "Conclusion and Personal Opinion:",
          text: [
            "In conclusion, BeLazy presents a versatile and innovative solution for LSPs. From my experience, it offers significant benefits in terms of integration and project management. It's ideal for LSPs looking for a comprehensive tool to streamline their various system functionalities.",
            "Also want to mention that BeLazy delivered one of the best demos that we had in [Wordlords:https://wordlords.co/]. Sales and Tech team members where present and all questions where answered. So if you want to hear more about BeLazy I strongly recommend booking a demo with them [here:https://belazy.cat/contact-us/]."
          ]
        },
        {
          subtitle: "Read our other blog:",
          text: [
            `[Explore our insights on entering the Ukrainian market:https://wordlords.co/blog/ukrainian-translation-services/]`
          ]
        },
      ]
      
    },
    blogMetaData: {
      title: "What is BeLazy? An Exploration of a Unique Industry Tool from Wordlords",
      description: "Join Andrian Zafiiovskiy, CTO of Wordlords, as he delves into BeLazy, an innovative tool integrating BMS, TMS, and CAT solutions for LSPs. Gain insights from his demo experience and research on how BeLazy can streamline project management across various platforms.",
      date: "2024-01-23"
    }
  },
  {
    blogId: "localization-player-count",
    blogImages: {
      blogMainImage: roiBlogImage, // Placeholder path, replace with actual image path
      blogAuthorImage: acornImage, // Placeholder path, replace with actual author image path
    },
    blogAuthor: {
      authorName: "Acorn Dobrovolskyy", // Replace with the actual author's name
      blogDate: "Mar 04, 2024", // Format: "MMM DD, YYYY", e.g., "Mar 28, 2024"
      authorId: "acorn-dobrovolskyy" // A slug version of the author's name
    },
    blogText: {
      subtitle: "Localization ROI Calculator",
      title: "What languages should I localize my game into?",
      content: [
        {
          subtitle: "",
          text: [
            "In the dynamic world of online gaming, where virtual landscapes know no bounds, the concept of player count takes on a new significance. Gaming projects are bigger and more complex than ever before. As gaming communities continue to expand globally, developers are increasingly recognizing the importance of localization in not only attracting diverse player bases but also elevating the overall gaming experience. There is a lot of information out there about the importance of localisation for games, here is a report from [Google:https://games.withgoogle.com/reports/2022-pc-console-insights-report/]. According to Google, at least 62% of global player base put localisation as “very” or “extremely” important. This blog post delves into the crucial role of localization in boosting player count and breaking geographical borders."
          ]
        },
        {
          subtitle: "",
          text: [
            "Ok, localisation is important, not everyone speaks English, of course. But, there are a lot of people who are proficient in English all over the world! Does it mean that by localizing my game I would only add the potential of those who don’t speak English? That’s the key element here, because the answer is NO. First of all, even if the potential gamer knows English, he’s much more likely to purchase a game if it’s localized to his language. And, according to [blogpost:https://newsletter.gamediscover.co/p/how-localization-affects-game-discovery] by Simon Carless on GameDiscoverCo, people likely won’t know about your game if it is not localized. The lower [EF EPI:https://www.ef.com/assetscdn/WIBIwq6RdJvcD9bc8RMd/cefcom-epi-site/reports/2023/ef-epi-2023-english.pdf] (English Proficiency Index) of player base, the less likely is that players of that language will hear about your game. "
          ]
        },
        {
          subtitle: "",
          text: [
            "But, here lies the main issue of all publishers all over the world - ROI (return on investment) of localization. Localization of a game is an expensive, sometimes extremely complex, and always a time consuming process. Because of that, publishers are very cautios when it comes to choosing languages to localize to.",
          ]
        },
        {
          subtitle: "",
          text: [
            "So, we decided to create a [handy tool:https://wordlords.co/roi-calculator/] which could help publishers of all sizes, from small indie companies to giants to estimate their ROI of localization per game.",
            "We take into account game market size (accoring to statista), player base count by language, EPI (English Proficiency Index) and data you provide. We ensure that this data is not transferred to 3rd parties."
          ]
        }
      ],
    },
    blogMetaData: {
      title: "Localization Player Count: Maximizing Your Game's Global Reach",
      description: "Explore the pivotal role of game localization in expanding your global player base and the direct impact on ROI. Learn how localizing your game into strategic languages can significantly enhance player engagement and market penetration.",
      date: "2024-04-04"
    },
  }
];

export default BlogPostsArray;
