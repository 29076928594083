import imageAndrianZafiiovskiy from "../../images/icons/blogImageAndrianZaf.svg";
import imageAcornDobrovolskiy from "../../images/acornPhoto.jpeg";
import imageOlhaTsyatska from "../../images/olhaPhoto.webp";

export interface ITeamMember {
    teamMemberId: string;
    image: string;
    title: string;
    firstName: string;
    lastName: string;
    linkedinLink: string;
    email: string;
    descriptionKey: string;
}

export const OurTeamArray: ITeamMember[]  = [
    {
        teamMemberId: "andrian-zafiiovskiy",
        image: imageAndrianZafiiovskiy,
        title: "CTO",
        firstName: "Andrian",
        lastName: "Zafiiovskiy",
        linkedinLink: "https://www.linkedin.com/in/andrian-zafiiovskiy-b43318209/",
        email: "andrian@wordlords.co",
        descriptionKey: "ctoDescription"
    },
    {
        teamMemberId: "acorn-dobrovolskyy",
        image: imageAcornDobrovolskiy,
        title: "CEO",
        firstName: "Acorn",
        lastName: "Dobrovolskyy",
        linkedinLink: "https://www.linkedin.com/in/mr-acornd/",
        email: "acorn@wordlords.co",
        descriptionKey: "ceoDescription"
    },
    {
        teamMemberId: "olha-serhieva",
        image: imageOlhaTsyatska,
        title: "Head Of Quality",
        firstName: "Olha",
        lastName: "Serhieva",
        linkedinLink: "",
        email: "olha@wordlords.co",
        descriptionKey: "hoqDescription"
    }
]