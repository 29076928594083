import "./CustomInput.css";

interface ICustomInput {
  errors?: any;
  label: string;
  inputName: string;
  value?: any;
  onChange?: (e: any) => void;
  placeHolder: string;
  type: string;
}

const CustomInput = ({ errors, label, inputName, value, onChange, placeHolder, type }: ICustomInput) => {
  const isTextArea: boolean = type === "textarea";

  return (
    <div className="custom-input">
      <label className={errors && "label-error"}>{label}</label>
      {isTextArea ? (
        <textarea
          name={inputName}
          className={errors && "input-error"}
          value={value}
          onChange={onChange}
          placeholder={placeHolder}
        />
      ) : (
        <input
          name={inputName}
          className={errors && "input-error"}
          value={value}
          onChange={onChange}
          placeholder={placeHolder}
          type={type}
        />
      )}
      {errors && <div className="error-message">{errors}</div>}
    </div>
  );
};

export default CustomInput;
