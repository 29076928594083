import { useTranslation } from "react-i18next";
import "./VideoBackground.css"; 
import React, { useRef, useEffect } from 'react';

export interface VideoBackgroundProps {
    src: string;
    className?: string;
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({ src, className }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.8; // Slows down the video to half its original speed
    }
  }, []);

  return (
    <div className={`video-background ${className || ''}`}>
      <div className="video-overlay"></div>
      <video autoPlay loop muted playsInline className="video-tag" ref={videoRef}>
        <source src={src} type="video/mp4" />
        {t("videoBackgroundAlt")}
      </video>
    </div>
  );
};


export default VideoBackground;