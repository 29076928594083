import { IVacancyCard } from "./IVacancyCard";

export interface IVacancy extends IVacancyCard {
    body: IVacancySection[];
    formLink?: string;
    type: VacancyType;
    jobPostingDescription?: string;
    datePosted?: string;
}

export interface IVacancySection {
    title: string;
    subtext?: string[];
    bulletPoints?: string[];
    endtext?: string[];
}

export enum VacancyType {
    SubmissionByEmail,
    SubmissionByForm,
    SubmissionByButton
}