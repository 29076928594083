import { useTranslation } from "react-i18next";
import "./VacancyCard.css"; 

interface IVacancyCard {
    vacancyId: string;
    title: string;
    tags: string[];
}

const VacancyCard = ({ vacancyId, title, tags }: IVacancyCard) => {
  const { i18n } = useTranslation();
  const vacancyUrl = `${i18n.language === 'en' ? '' : '/ua'}/careers/${vacancyId}/`;

  return (
    <div id={vacancyId.toString()} className="vacancy-container">
        <h5>{title}</h5>
        <div className="tags-container">
          {tags.map((tag, index) => (
            <div key={index} className="tag">{tag}</div>
          ))}
        </div>
        <a href={vacancyUrl} className="link style-1"> <span className="job-details-button-text">Job details</span>
          <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <g fill="none" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
              <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
              <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
              </g>
          </svg>
        </a>
    </div>
  );
};

export default VacancyCard;
