import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { LabelProvider } from "./pages/InstantQuotePage/labelDataContext";
import RoutesComponent from "./Routes";

import "./stylesheet/style.css";
import TawkToWidget from "./components/TawkChatWidget";

const App = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <LabelProvider>
      <Header  />
      <RoutesComponent />
      <Footer />
      <TawkToWidget />
    </LabelProvider>
  );
};

export default App;
