import { ReactComponent as CheckmarkSVG } from "../../../../images/icons/greenCheckmark.svg";
import { OurServicesInterface } from "..";

import "./OurServicesPage.css";

interface OurServicesPageInterface {
  ourServicesData: OurServicesInterface;
}

const OurServicesPage = ({ ourServicesData }: OurServicesPageInterface) => {
  return (
    <div className="our-services-container">
      <div className="our-services-hero">
        <img src={ourServicesData.mainImage} alt="Website translation" className="our-services-image" />
        <div className="our-services-text-container">
          <p className="our-services-subtitle">{ourServicesData.subtitle}</p>
          <h1 className="our-services-title ">{ourServicesData.title}</h1>
          {ourServicesData.mainText.map((item: string, index: number) => (
            <p key={index + item}>{item}</p>
          ))}
        </div>
        <img src={ourServicesData.bgImage} className="our-services-bg-icon" alt="Our Services" />
      </div>
      <div className="our-services-question-container">
        <h2>{ourServicesData.question}</h2>
        <div className="our-services-checkmark-container">
          {ourServicesData.answers.map((answers, index) => (
            <div key={answers.answer + index} className="our-services-answer-container">
              <CheckmarkSVG />
              <p>{answers.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServicesPage;
