import { useContext } from "react";
import { Stepper, Step } from "react-form-stepper";
  import { ToastContainer } from 'react-toastify';
  import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ReactComponent as CheckmarkSVG } from "../../images/icons/stepCheckmark.svg";
import ContentStep from "./components/ContentStep";
import LanguageStep from "./components/LanguageStep";
import QuoteStep from "./components/QuoteStep";
import SubmitStep from "./components/SubmitStep";
import { LabelContext } from "./labelDataContext";

import "./InstantQuotePage.css";
import { useTranslation } from "react-i18next";

const InstantQuotePage = () => {
  const { t } = useTranslation();
  const pathname = window.location.pathname;

  const value = useContext(LabelContext);
  const stepStyles = {
    activeBgColor: "#6491CF",
    activeTextColor: "#FFFFFF",
    completedBgColor: "#91CF64",
    completedTextColor: "#FFFFFF",
    inactiveBgColor: "#F9FAFD",
    inactiveTextColor: "#A7ADC3",
    size: "40px",
    circleFontSize: "24px",
    labelFontSize: "16px",
    borderRadius: "50%",
    fontWeight: 500,
  };
  const connectorStyles = {
    disabledColor: "#E9EBF2",
    activeColor: "#ed1d24",
    completedColor: "#a10308",
    size: 4,
    stepSize: "2em",
    style: "solid",
  };
  const getCheckmark = (step: number) => {
    if (value.page! > step) {
      return <CheckmarkSVG />;
    }
    return null;
  };

  
  const helmetTitle = t("helmetInstantQuoteTitle");
  const helmetDescription = t("helmetInstantQuoteDescription");
  const helmetCanonicalhref = pathname === '/' ? `https://wordlords.co/` : `https://wordlords.co${pathname}`;
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>{helmetTitle}</title>
          <link rel="canonical" href={helmetCanonicalhref} />
          <meta name="description" content={helmetDescription} />
          
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:description" content={helmetDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={helmetCanonicalhref} />
          <meta property="og:image" content="https://wordlords.co/logo.svg"/>
          <meta property="og:site_name" content="Wordlords Quote" />
          <meta property="og:locale" content="en_US" />

          <meta name="twitter:title" content={helmetTitle} />
          <meta name="twitter:description" content={helmetDescription} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content="@zafiovskiy" />
          <meta name="twitter:image" content="https://wordlords.co/logo.svg" />
          <meta name="twitter:url" content={helmetCanonicalhref} />

          <meta name="robots" content="index, follow"/>
        </Helmet>
        <ToastContainer autoClose={5000}/>
        <div className="instant-quote-page-wrapper">
          <div className="container">
            <div className="instant-quote-page-container">
              <h2 className="instant-quote-page-title">{t('get a quote')}</h2>
              <p className="instant-quote-page-subtitle">{t('translations easy')}</p>
              <Stepper
                className="steper-container"
                styleConfig={stepStyles}
                connectorStyleConfig={connectorStyles}
                activeStep={value.page}
              >
                <Step label={t('content')!} children={getCheckmark(0)} />
                <Step label={t('language')!} children={getCheckmark(1)} />
                <Step label={t('quote')!} children={getCheckmark(2)} />
                <Step label={t('submit order')!} children={getCheckmark(3)} />
              </Stepper>

              {value.page === 0 && <ContentStep />}
              {value.page === 1 && <LanguageStep />}
              {value.page === 2 && <QuoteStep />}
              {value.page === 3 && <SubmitStep />}
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default InstantQuotePage;
